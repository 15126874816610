import React, {useContext, useState, useEffect, useRef, useMemo} from 'react';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';
import {
  BLACK,
  WHITE,
  FOCUS_BLUE,
  ASH,
  CHARCOAL,
  LIGHT_SHADED_GREY
} from '../../../../shared/style/colors';
import DownArrow from '../../../../shared/library/icons/drop_icon.svg';
import {CurrentUserContext} from '../../../../shared/enhancers/current-user-enhancer';
import {BASE_TEXT, WEIGHT} from '../../../../shared/style/typography';
import PrivateStackshareIcon from '../../../../shared/library/icons/private-stackshare-small-icon.svg';
import DefaultSearchIcon from '../../../../shared/library/icons/search-icon-black.svg';
import SearchDropdown from './search-dropdown';
import {debounce} from '../../../../shared/utils/debounce';
import NavbarAvatar from './avatar';
import NavbarLogin from './login';
import {
  DESKTOP,
  LAPTOP,
  LAPTOP_WIDTH,
  MOBILE_WIDTH,
  PHONE,
  TABLET_WIDTH
} from '../../../../shared/style/breakpoints';
import SSLogo from '../../../../shared/library/icons/ss-logo.svg';
import SSIcon from '../../../../shared/library/icons/ss_icon.svg';
import {PAGE_WIDTH} from '../../../../shared/style/dimensions';
import {PrivateModeContext} from '../../../../shared/enhancers/private-mode-enchancer';
import {siteSearch} from '../../../../data/shared/queries';
import {ApolloContext} from '../../../../shared/enhancers/graphql-enhancer';
import {userPlans} from '../../../../shared/utils/user-plans';
import {ENTERPRISE_COMPANY_PLANS, USER_PLANS} from '../../../../shared/utils/payment-plans';
import StackFileIcon from '../../../../shared/library/icons/stack-file.svg';
import {
  enterpriseOrg,
  personalInstall,
  stackFileOrg,
  stackFilePersonal
} from '../../../../shared/utils/dropdown-options';

const noop = () => null;

const Container = glamorous.div({
  backgroundColor: WHITE,
  justifyContent: 'center',
  position: 'relative',
  margin: 'auto',
  width: '100%',
  [LAPTOP]: {
    padding: '0 20px'
  },
  [PHONE]: {
    padding: '0 9px',
    overflowY: 'scroll',
    maxHeight: '75vh'
  }
});

const SubContainer = glamorous.div({
  maxWidth: PAGE_WIDTH,
  minHeight: 68,
  display: 'flex',
  margin: '0 auto',
  alignItems: 'center',
  [PHONE]: {
    display: 'block',
    minHeight: 200,
    height: 'auto'
  }
});

const SSIconContainer = glamorous.a({
  display: 'block',
  marginRight: 35,
  [LAPTOP]: {
    marginRight: 15
  }
});

const SSLogoStyled = glamorous(SSLogo)({
  height: 31,
  width: 203
});

const NavbarSearchLoginContainer = glamorous.div({
  display: 'flex',
  justifyContent: 'space-between',
  [DESKTOP]: {
    width: 900
  },
  [`@media only screen and (max-width: ${LAPTOP_WIDTH}px) and (min-width: ${TABLET_WIDTH +
    1}px)`]: {
    width: 745,
    justifyContent: 'space-around'
  },
  [`@media only screen and (max-width: ${TABLET_WIDTH}px) and (min-width: ${MOBILE_WIDTH +
    1}px)`]: {
    width: 690,
    justifyContent: 'space-around'
  },
  [PHONE]: {
    display: 'block'
  }
});

const Menu = glamorous.div({
  listStyle: 'none',
  display: 'flex',
  height: 68,
  margin: 0,
  padding: 0,
  gap: 35,
  [`@media only screen and (max-width: ${LAPTOP_WIDTH}px) and (min-width: ${MOBILE_WIDTH +
    1}px)`]: {
    gap: 15
  },
  [PHONE]: {
    display: 'block',
    height: 'auto'
  }
});

const NavTitle = glamorous.a(
  {
    ...BASE_TEXT,
    textDecoration: 'none',
    color: BLACK,
    fontWeight: WEIGHT.BOLD,
    fontSize: 14,
    cursor: 'pointer',
    display: 'inline-block',
    padding: '0 5px 0 10px',
    whiteSpace: 'nowrap',
    [PHONE]: {
      padding: 0
    }
  },
  ({customStyle}) => ({
    marginTop: !customStyle && 3,
    [PHONE]: {
      display: 'flex',
      justifyContent: 'space-between'
    }
  })
);

const ToggleContainer = glamorous.div({
  backgroundColor: WHITE,
  cursor: 'default',
  top: '100%',
  whiteSpace: 'nowrap',
  minWidth: 225,
  padding: '8px 12px 15px',
  position: 'absolute',
  [`@media only screen and (min-width: ${MOBILE_WIDTH + 1}px)`]: {
    border: '1px solid #EBEBEB',
    borderTop: 0,
    boxShadow: '0px 2px 4px rgba(190, 190, 190, 0.5)',
    borderRadius: 4,
    zIndex: 10000,
    marginLeft: 7
  },
  [PHONE]: {
    position: 'relative',
    padding: '23px 0 15px'
  }
});

const NavOption = glamorous.a(({customStyle}) => ({
  ...BASE_TEXT,
  width: '100%',
  height: 38,
  display: 'flex',
  alignItems: 'center',
  boxSizing: 'border-box',
  fontStyle: 'normal',
  cursor: 'pointer',
  fontSize: 14,
  fontWeight: WEIGHT.BOLD,
  lineHeight: '19px',
  padding: '9px 18px 10px',
  textAlign: 'left',
  [LAPTOP]: {
    textDecoration: 'none',
    color: CHARCOAL
  },
  [PHONE]: {
    textDecoration: 'none',
    color: CHARCOAL,
    fontSize: 16,
    marginBottom: 16
  },
  '&:hover': {
    background: '#F3F8FB'
  },
  ...customStyle
}));

const PrivateSSContainer = glamorous.div(
  {
    alignSelf: 'center',
    position: 'relative',
    [DESKTOP]: {
      display: 'flex',
      maxWidth: 250
    },
    [`@media only screen and (max-width: ${LAPTOP_WIDTH}px) and (min-width: ${MOBILE_WIDTH +
      1}px)`]: {
      maxWidth: 250
    },
    '&:hover': {
      '> div': {
        '> div': {
          '> a': {
            color: FOCUS_BLUE
          },
          ' .dropdown-arrow': {
            ' path': {
              fill: FOCUS_BLUE
            }
          }
        }
      }
    }
  },
  ({customStyle, extraStyle}) => ({
    '> div': {
      '> div': {
        '> a': {
          color: customStyle && FOCUS_BLUE
        },
        ' .dropdown-arrow': {
          ' path': {
            fill: customStyle && FOCUS_BLUE
          }
        }
      }
    },
    [LAPTOP]: {
      WebkitTapHighlightColor: (customStyle || extraStyle) && 'transparent'
    },
    [PHONE]: {
      margin: customStyle || extraStyle ? '10px 0' : '25px 0'
    }
  })
);

const PrivateSSTextContainer = glamorous.div(
  {
    display: 'block',
    marginLeft: 10,
    cursor: 'pointer'
  },
  ({customStyle}) => ({
    marginTop: customStyle && 13,
    [PHONE]: {
      margin: 0,
      width: '100%'
    }
  })
);

const PrivateSSTextArrowContainer = glamorous.div(
  {
    display: 'flex',
    [PHONE]: {
      width: 'auto',
      minWidth: 280
    }
  },
  ({customStyle}) => ({
    [PHONE]: {
      display: customStyle ? 'block' : 'flex'
    }
  })
);

const PSSSectionContainer = glamorous.div(
  {
    display: 'flex',
    [DESKTOP]: {
      '&:hover': {
        '> div': {
          '> div': {
            '> a': {
              color: FOCUS_BLUE
            },
            '> svg': {
              ' path': {
                fill: FOCUS_BLUE
              }
            }
          }
        }
      }
    },
    [PHONE]: {
      padding: '0 11px'
    }
  },
  ({customStyle}) => ({
    [DESKTOP]: {
      '> a': {
        '> div': {
          '> a': {
            color: customStyle && FOCUS_BLUE
          },
          '> svg': {
            ' path': {
              fill: customStyle && FOCUS_BLUE
            }
          }
        }
      }
    },
    [PHONE]: {
      background: customStyle && '#F3F8FB'
    }
  })
);

const PrivateStackshareIconStyled = glamorous(PrivateStackshareIcon)(
  {
    width: 25,
    height: 28,
    marginRight: 10,
    alignSelf: 'flex-start',
    [PHONE]: {
      width: 32,
      height: 32,
      alignSelf: 'center'
    }
  },
  ({customStyle}) => ({
    cursor: customStyle ? 'pointer' : 'default',
    marginTop: customStyle && 7
  })
);

const TextContainer = glamorous.div({
  display: 'flex',
  alignItems: 'center'
});

const PrivateSSIconTextContainer = glamorous
  .div(
    {
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      '&:hover': {
        '> div': {
          '> a': {
            color: FOCUS_BLUE
          },
          '> p': {
            color: CHARCOAL
          }
        }
      },
      [PHONE]: {
        justifyContent: 'space-between'
      }
    },
    ({customStyle}) => ({
      [PHONE]: {
        width: customStyle && '100%',
        padding: customStyle && '10px 5px 10px 0'
      }
    })
  )
  .withComponent('a');

const EnterpriseArrowContainer = glamorous.div(({customStyle}) => ({
  ':hover': {
    '> a': {
      color: FOCUS_BLUE
    }
  },
  [PHONE]: {
    width: customStyle && '100%'
  }
}));

const EnterpriseDownArrowStyled = glamorous(DownArrow)({
  marginBottom: -3,
  [PHONE]: {
    alignSelf: 'center',
    marginBottom: 0
  }
});

const CompanyOrUsernameTag = glamorous.p({
  ...BASE_TEXT,
  [LAPTOP]: {
    marginTop: 0
  },
  [PHONE]: {
    margin: 0
  }
});

const StackFileIconStyled = glamorous(StackFileIcon)({
  width: 25,
  height: 28
});

const AdminLinkContainer = glamorous.div(
  {
    alignSelf: 'center',
    [PHONE]: {
      margin: '25px 0',
      padding: '0 15px 0 11px'
    }
  },
  ({customStyle}) => ({
    ...customStyle
  })
);

const AdminLink = glamorous.a({
  ...BASE_TEXT,
  textDecoration: 'none',
  color: BLACK,
  fontWeight: WEIGHT.BOLD,
  fontSize: 14,
  cursor: 'pointer',
  padding: '0 5px 0 10px',
  ':focus': {
    color: BLACK
  },
  [PHONE]: {
    padding: 0
  }
});

const SearchLoginContainer = glamorous.div({
  display: 'flex',
  [PHONE]: {
    display: 'block'
  }
});

const SearchContainer = glamorous.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between'
});

const SearchInputWrapper = glamorous.div({
  height: 40,
  width: 195,
  backgroundColor: WHITE,
  borderRadius: 20,
  display: 'flex',
  alignItems: 'center',
  border: `1px solid ${LIGHT_SHADED_GREY}`
});

const SearchIcon = glamorous(DefaultSearchIcon)({
  width: 13,
  height: 13,
  margin: '0 9px 0 17px'
});

const InnerSearchInput = glamorous.input({
  background: 'transparent',
  margin: 0,
  border: 'none',
  flexGrow: 1,
  outline: 'none',
  borderRadius: 20,
  width: '100%',
  height: 40
});

const LoginContainer = glamorous.div({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 15,
  [PHONE]: {
    margin: 0
  }
});

const Separator = glamorous.div({
  [PHONE]: {
    borderBottom: `1px solid ${ASH}`,
    textAlign: 'justify'
  }
});

const NavbarMenu = ({
  mobile,
  setCloseMenuOptions,
  closeMenuOptions,
  userId,
  device,
  orgStructureMode,
  stackFile = false
}) => {
  const [togglePrivateStackshare, setTogglePrivateStackshare] = useState(false);
  const [open, setOpen] = useState(false);
  const [stackFileOwnerName, setStackFileOwnerName] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [toggleStackFileDropdown, setToggleStackFileDropdown] = useState(false);
  const [enterpriseDropdownOptions, setEnterpriseDropdownOptions] = useState([]);
  const [stackFileOptions, setStackFileOptions] = useState([]);
  const currentUser = useContext(CurrentUserContext);
  const privateMode = useContext(PrivateModeContext);
  const [hits, setHits] = useState({});
  const [toggleSearch, setToggleSearch] = useState(false);
  const [searchText, setSearchText] = useState('');
  const privateCompany =
    currentUser &&
    currentUser.companies &&
    currentUser.companies.filter(company => company.privateMode)[0];
  const hasPersonalGithubInstall = currentUser && currentUser.hasPersonalGithubInstall;
  const isAdmin = privateMode && privateMode.myRole === 'admin';
  const isEditStagesAllowed =
    privateMode?.adoptionStages?.length > 0
      ? privateMode.adoptionStages.some(stage => stage?.permissions?.edit)
      : isAdmin;
  const username = currentUser && currentUser.username;
  const closeMenuCondition = mobile ? () => setCloseMenuOptions(!closeMenuOptions) : () => {};
  const client = useContext(ApolloContext);
  const currentUserPlans = userPlans(currentUser);
  const isEnterpriseUser =
    currentUser && currentUserPlans.some(plan => ENTERPRISE_COMPANY_PLANS.includes(plan));
  const hasPersonalGithubInstallation =
    currentUser && currentUserPlans.some(plan => USER_PLANS.includes(plan));
  const stackFileAppInstall = currentUser?.stackFileApp;
  const enterpriseDropdownCondition = userId && (privateCompany || hasPersonalGithubInstall);
  const onlyStackFileInstallation = stackFileAppInstall && !isEnterpriseUser;
  const stackFilePersonalInstallation = stackFileAppInstall?.owner?.type === 'User';
  const stackFileOrgInstallation = stackFileAppInstall?.owner?.type === 'Company';
  const isStackFileAppInstallation = stackFile;

  const searchRef = useRef();
  const privateSSRef = useRef();
  const avatarRef = useRef();

  const enterpriseOrgOptions = enterpriseOrg(
    privateCompany,
    privateMode,
    orgStructureMode,
    isEditStagesAllowed
  );
  const personalInstallOptions = personalInstall(username);
  const stackFilePersonalOptions = useMemo(() => stackFilePersonal(stackFileOwnerName), [
    stackFileOwnerName
  ]);
  const stackFileOrgOptions = useMemo(() => stackFileOrg(stackFileOwnerName), [stackFileOwnerName]);

  useEffect(() => {
    if (currentUser?.stackFileApp) {
      setStackFileOwnerName(currentUser.stackFileApp.owner?.slug);
    }
  }, [currentUser]);

  useEffect(() => {
    const optionsCheck = () => {
      let options;
      let stackFileOptions;

      if (stackFilePersonalInstallation && onlyStackFileInstallation) {
        stackFileOptions = stackFilePersonalOptions;
      } else if (stackFileOrgInstallation && onlyStackFileInstallation) {
        stackFileOptions = stackFileOrgOptions;
      } else {
        stackFileOptions = [];
      }

      if (isEnterpriseUser) {
        options = enterpriseOrgOptions;
      } else if (hasPersonalGithubInstallation) {
        options = personalInstallOptions;
      } else options = [];

      return {
        options,
        stackFileOptions
      };
    };

    setEnterpriseDropdownOptions(optionsCheck().options);
    setStackFileOptions(optionsCheck().stackFileOptions);

    if (isEnterpriseUser || hasPersonalGithubInstallation) setShowDropdown(true);
    else setShowDropdown(false);
  }, [currentUser, stackFileOwnerName]);

  // useEffect(() => {
  //   if (isEnterpriseUser || hasPersonalGithubInstallation) {
  //     setShowDropdown(true);
  //   } else {
  //     setShowDropdown(false);
  //   }
  // }, [currentUser]);

  useEffect(() => {
    document.addEventListener('click', handler);
    return () => {
      document.removeEventListener('click', handler);
    };
  }, []);

  const handler = e => {
    if (!privateSSRef.current.contains(e.target)) {
      setTogglePrivateStackshare(false);
    }
    if (!(avatarRef && avatarRef.current && avatarRef.current.contains(e.target))) {
      setOpen(false);
    }

    if (!mobile && !searchRef.current.contains(e.target)) {
      setToggleSearch(false);
    }
  };

  useEffect(() => {
    if (searchText) {
      getSearchedData(searchText);
    } else getSearchedData('');
  }, [searchText]);

  const onSearch = value => {
    setSearchText(value);
  };

  const getSearchedData = debounce(keyword => {
    client
      .query({
        query: siteSearch,
        variables: {keyword}
      })
      .then(result => {
        if (keyword.length === 0) {
          setHits({});
          return;
        }
        setHits(result && result.data && result.data.siteSearch);
        setToggleSearch(true);
      });
  }, 300);

  const handleSubmit = event => {
    let q = event.target.value;
    if (q) {
      window.location = '/search/q=' + encodeURIComponent(q);
    }
    return false;
  };

  const keyDownHandler = event => {
    if (event.key === 'Enter' && searchText) {
      event.preventDefault();
      handleSubmit(event);
    }
  };

  return (
    <Container>
      <SubContainer>
        {!mobile && (
          <SSIconContainer href={'/'}>
            {device !== 'tablet' ? <SSLogoStyled /> : <SSIcon />}
          </SSIconContainer>
        )}
        <NavbarSearchLoginContainer>
          <Menu>
            <TextContainer>
              <NavTitle href={'/tools/trending'} data-testid="toolsClick">
                Tools
              </NavTitle>
            </TextContainer>

            <TextContainer>
              <NavTitle href={'/stacks'} data-testid="stacksClick">
                Stacks
              </NavTitle>
            </TextContainer>

            <TextContainer>
              <NavTitle href={'/stackups'} data-testid="stackupsClick">
                Comparisons
              </NavTitle>
            </TextContainer>

            {showDropdown && (
              <>
                <Separator />
                <PrivateSSContainer
                  onClick={
                    enterpriseDropdownCondition
                      ? () => setTogglePrivateStackshare(!togglePrivateStackshare)
                      : () => {}
                  }
                  customStyle={togglePrivateStackshare}
                  extraStyle={enterpriseDropdownCondition}
                  innerRef={privateSSRef}
                  data-testid="enterpriseDropdown"
                >
                  <PrivateSSTextContainer customStyle={enterpriseDropdownCondition}>
                    <PrivateSSTextArrowContainer customStyle={enterpriseDropdownCondition}>
                      <PSSSectionContainer customStyle={togglePrivateStackshare}>
                        <PrivateStackshareIconStyled customStyle={enterpriseDropdownCondition} />
                        <PrivateSSIconTextContainer
                          customStyle={enterpriseDropdownCondition}
                          href={!enterpriseDropdownCondition && '/enterprise'}
                          onClick={
                            !enterpriseDropdownCondition ? () => closeMenuCondition() : () => {}
                          }
                          data-testid="enterpriseClick"
                        >
                          <EnterpriseArrowContainer customStyle={enterpriseDropdownCondition}>
                            <NavTitle
                              style={{padding: 0}}
                              customStyle={enterpriseDropdownCondition}
                            >
                              Enterprise
                              {enterpriseDropdownCondition && (
                                <EnterpriseDownArrowStyled className="dropdown-arrow" />
                              )}
                            </NavTitle>
                            {isEnterpriseUser ? (
                              <CompanyOrUsernameTag>{privateCompany?.name}</CompanyOrUsernameTag>
                            ) : hasPersonalGithubInstall ? (
                              <CompanyOrUsernameTag>{username}</CompanyOrUsernameTag>
                            ) : (
                              ''
                            )}
                          </EnterpriseArrowContainer>
                        </PrivateSSIconTextContainer>
                      </PSSSectionContainer>
                    </PrivateSSTextArrowContainer>
                  </PrivateSSTextContainer>

                  {enterpriseDropdownCondition && togglePrivateStackshare && (
                    <>
                      <ToggleContainer style={{left: 0}}>
                        {enterpriseDropdownOptions?.length > 0 &&
                          enterpriseDropdownOptions.map(
                            (item, index) =>
                              item.permission && (
                                <NavOption
                                  href={item.link}
                                  target={item.targetLink && '_blank'}
                                  rel="noreferrer noopener"
                                  key={index}
                                  onClick={() => closeMenuCondition()}
                                  data-testid={`${item.feature}Click`}
                                >
                                  {item.feature}
                                </NavOption>
                              )
                          )}
                      </ToggleContainer>
                    </>
                  )}
                </PrivateSSContainer>
              </>
            )}
            <Separator />
            {currentUser &&
              isStackFileAppInstallation &&
              stackFileDropDown(
                stackFileOptions,
                onlyStackFileInstallation,
                toggleStackFileDropdown,
                setToggleStackFileDropdown,
                stackFileOwnerName,
                username
              )}
            <Separator />
            {currentUser && currentUser.canIModerate && (
              <>
                <AdminLinkContainer>
                  <AdminLink href="/admin" onClick={() => closeMenuCondition()}>
                    Admin
                  </AdminLink>
                </AdminLinkContainer>
                <Separator />
              </>
            )}
          </Menu>

          <SearchLoginContainer>
            {!mobile && (
              <SearchContainer innerRef={searchRef}>
                <SearchInputWrapper>
                  <SearchIcon />
                  <form>
                    <InnerSearchInput
                      placeholder="Search"
                      onChange={e => onSearch(e.target.value)}
                      value={searchText}
                      onKeyDown={event => keyDownHandler(event)}
                    />
                  </form>
                </SearchInputWrapper>
                {hits && toggleSearch && (
                  <SearchDropdown hits={hits} searchText={searchText} currentUser={currentUser} />
                )}
              </SearchContainer>
            )}

            <LoginContainer>
              {!userId ? (
                <NavbarLogin />
              ) : (
                <NavbarAvatar
                  currentUser={currentUser}
                  mobile={mobile}
                  avatarRef={avatarRef}
                  open={open}
                  setOpen={setOpen}
                  setCloseMenuOptions={setCloseMenuOptions}
                  closeMenuOptions={closeMenuOptions}
                />
              )}
            </LoginContainer>
          </SearchLoginContainer>
        </NavbarSearchLoginContainer>
      </SubContainer>
    </Container>
  );
};

const stackFileDropDown = (
  stackFileOptions,
  onlyStackFileInstallation,
  toggleStackFileDropdown,
  setToggleStackFileDropdown,
  stackFileOwnerName,
  username
) => {
  if (stackFileOptions.length > 0 && onlyStackFileInstallation) {
    return (
      <>
        <PrivateSSContainer
          onClick={() => setToggleStackFileDropdown(!toggleStackFileDropdown)}
          customStyle={toggleStackFileDropdown}
          extraStyle={onlyStackFileInstallation}
          data-testid="stackFileDropdown"
        >
          <PrivateSSTextContainer customStyle={onlyStackFileInstallation}>
            <PrivateSSTextArrowContainer
              customStyle={onlyStackFileInstallation}
              style={{display: 'flex', alignItems: 'center'}}
            >
              <StackFileIconStyled style={{marginBottom: 15, marginRight: 10}} />
              <PSSSectionContainer customStyle={toggleStackFileDropdown}>
                <EnterpriseArrowContainer customStyle={onlyStackFileInstallation}>
                  <NavTitle style={{padding: 0}} customStyle={onlyStackFileInstallation}>
                    Stack File
                    {onlyStackFileInstallation && (
                      <EnterpriseDownArrowStyled className="dropdown-arrow" />
                    )}
                  </NavTitle>
                  <CompanyOrUsernameTag>{stackFileOwnerName || username}</CompanyOrUsernameTag>
                </EnterpriseArrowContainer>
              </PSSSectionContainer>
            </PrivateSSTextArrowContainer>
          </PrivateSSTextContainer>

          {toggleStackFileDropdown && (
            <ToggleContainer style={{left: 0}}>
              {stackFileOptions.map(
                (item, index) =>
                  item.permission && (
                    <NavOption
                      href={item.link}
                      target={item.targetLink && '_blank'}
                      rel="noreferrer noopener"
                      key={index}
                      onClick={() => setToggleStackFileDropdown(false)}
                      data-testid={`${item.feature}Click`}
                    >
                      {item.feature}
                    </NavOption>
                  )
              )}
            </ToggleContainer>
          )}
        </PrivateSSContainer>
      </>
    );
  }
};

NavbarMenu.defaultProps = {
  mobile: false,
  setCloseMenuOptions: noop,
  closeMenuOptions: false
};

NavbarMenu.propTypes = {
  mobile: PropTypes.bool,
  setCloseMenuOptions: PropTypes.func,
  closeMenuOptions: PropTypes.bool,
  userId: PropTypes.string,
  device: PropTypes.string,
  orgStructureMode: PropTypes.string,
  stackFile: PropTypes.bool
};

export default NavbarMenu;
