import React from 'react';
import PropTypes from 'prop-types';
import BaseModal, {ButtonPanel} from '../modals/base/modal';
import {withPortal} from '../modals/base/portal';
import SimpleButton from '../buttons/base/simple';
import CancelButton from '../buttons/base/cancel';

const Confirmation = ({onDismiss, handleSubmit, message = '', buttonText = ''}) => {
  const onCancel = e => {
    e.stopPropagation();
    onDismiss();
  };
  return (
    <BaseModal title="Confirm" onDismiss={onCancel}>
      <p>{message}</p>
      <ButtonPanel>
        <CancelButton onClick={onCancel}>Cancel</CancelButton>
        <SimpleButton onClick={handleSubmit}>{buttonText}</SimpleButton>
      </ButtonPanel>
    </BaseModal>
  );
};

Confirmation.propTypes = {
  onDismiss: PropTypes.func,
  handleSubmit: PropTypes.func,
  message: PropTypes.string,
  buttonText: PropTypes.string
};

export default withPortal(Confirmation);
