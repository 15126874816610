export const PLANS = {
  FREE: 'private-free-company',
  TEAM: 'private-team-500',
  ENTERPRISE: 'private-enterprise',
  STARTUP: 'private-startup-mar-2021'
};

export const ENTERPRISE_PLANS = [
  PLANS.FREE,
  PLANS.ENTERPRISE,
  PLANS.TEAM,
  PLANS.STARTUP,
  'private-startup-fixed',
  'private-free-personal'
];

export const ENTERPRISE_COMPANY_PLANS = [
  PLANS.FREE,
  PLANS.ENTERPRISE,
  PLANS.TEAM,
  PLANS.STARTUP,
  'private-startup-fixed'
];

export const ENTERPRISE_FREE_PLANS = [PLANS.FREE, 'private-free-personal'];

export const USER_PLANS = ['private-free-personal'];

export const STACK_FILE_FREE_PLANS = ['stack-file-free', 'stack-file-free-legacy'];

export const STACK_FILE_PRO_PLANS = [
  'stack-file-pro-4000',
  'stack-file-pro-2000',
  'stack-file-pro-1000',
  'stack-file-pro-500',
  'stack-file-pro-200',
  'stack-file-pro-100',
  'stack-file-pro-50'
];

export const STACK_FILE_PLANS = [...STACK_FILE_FREE_PLANS, ...STACK_FILE_PRO_PLANS];
