import React, {useState, useEffect, Fragment} from 'react';
import PropTypes from 'prop-types';
import {compose} from 'react-apollo';
import MobileHeader from '../../../../shared/library/menus/mobile-header';
import HeaderNotice, {HEADER_NOTICE_SEEN} from '../../../site/components/header-notice';
import {withLocalStorage} from '../../../../shared/enhancers/local-storage-enhancer';
import TOSUpdate from '../../../site/components/tos-update';
import Navbar from '../../../site/components/navbar';

const Header = ({contentRef, privateMode, currentUser, userId, storageProvider}) => {
  const [headerOffset, setHeaderOffset] = useState();
  // eslint-disable-next-line no-unused-vars
  const [stickyNavbar, setStickyNavbar] = useState(true);
  const [showHeaderNotice, setShowHeaderNotice] = useState(false);
  const [closeMenuOptions, setCloseMenuOptions] = useState(false);

  useEffect(() => {
    if (!storageProvider.getItem(HEADER_NOTICE_SEEN)) {
      if (privateMode) {
        setShowHeaderNotice(false);
      } else {
        if (
          window.location.pathname === '/enterprise' ||
          window.location.pathname === '/' ||
          window.location.pathname === '/home'
        ) {
          setShowHeaderNotice(false);
        } else {
          setShowHeaderNotice(true);
        }
      }
    }
  }, []);

  return (
    <Fragment>
      {currentUser && currentUser.showTosModal && <TOSUpdate />}
      {!privateMode && showHeaderNotice && (
        <HeaderNotice
          mobile={true}
          setHeaderOffset={setHeaderOffset}
          setShowHeaderNotice={setShowHeaderNotice}
        />
      )}

      <MobileHeader
        contentRef={contentRef}
        headerOffset={headerOffset}
        stickyNavbar={stickyNavbar}
        closeMenuOptions={closeMenuOptions}
        showHeaderNotice={showHeaderNotice}
        currentUser={currentUser}
      >
        <Navbar
          mobile={true}
          setCloseMenuOptions={setCloseMenuOptions}
          closeMenuOptions={closeMenuOptions}
          userId={userId}
        />
      </MobileHeader>
    </Fragment>
  );
};
Header.propTypes = {
  contentRef: PropTypes.any,
  privateMode: PropTypes.object,
  currentUser: PropTypes.object,
  userId: PropTypes.string,
  storageProvider: PropTypes.object
};

export default compose(withLocalStorage('HeaderNotice', '3'))(Header);
