import React, {useContext, useState} from 'react';
import glamorous from 'glamorous';
import BackArrowIcon from '../icons/back-arrow-icon.svg';
import {CHARCOAL, WHITE} from '../../style/colors';
import BaseText from '../typography/text';
import PropTypes from 'prop-types';
import {NavigationContext} from '../../enhancers/router-enhancer';
import {redirectTo} from '../../utils/navigation';
import Confirmation from '../confirmation-modal';

const Container = glamorous.div({
  margin: '10px 0',
  boxShadow: '0 1px 3px 0 #e1e1e1',
  backgroundColor: WHITE,
  padding: 15,
  borderRadius: 4,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  ' svg': {
    marginRight: 7
  }
});

const Text = glamorous(BaseText)({
  fontSize: 14,
  fontWeight: 'bold',
  color: CHARCOAL
});

const BackLayer = glamorous.div({
  display: 'flex',
  alignItems: 'center'
});

const BackItems = glamorous.div({
  display: 'flex'
});

const Back = ({url, text, navigateContext = false, children, canSave = false}) => {
  const navigate = useContext(NavigationContext);
  const [confirm, setConfirm] = useState(false);

  const handleNavigate = () => {
    if (!canSave) {
      navigate(url);
    } else {
      setConfirm(true);
    }
  };

  const handleStageDismiss = () => {
    navigate(url);
  };

  return (
    <>
      <Container>
        <BackLayer onClick={navigateContext ? () => handleNavigate() : () => redirectTo(url)}>
          <BackArrowIcon /> <Text>{text}</Text>
        </BackLayer>
        <BackItems>{children}</BackItems>
      </Container>

      {confirm && (
        <Confirmation
          onDismiss={() => {
            setConfirm(false);
          }}
          handleSubmit={handleStageDismiss}
          message={'Are you sure you want to leave without saving your changes?'}
          buttonText={'Yes, Leave'}
        />
      )}
    </>
  );
};

Back.propTypes = {
  url: PropTypes.string,
  text: PropTypes.string,
  navigateContext: PropTypes.bool,
  children: PropTypes.node,
  canSave: PropTypes.bool
};

export default Back;
