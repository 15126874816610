//flatten list of orgs

export default function flattenList(list) {
  const result = [];

  function traverse(node) {
    result.push(node);

    if (node.childOrgs && node.childOrgs.length > 0) {
      for (const child of node.childOrgs) {
        traverse(child);
      }
    }
  }

  for (const orgWithChildOrgs of list) {
    traverse(orgWithChildOrgs);
  }
  return result;
}
