import React from 'react';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';
import GithubIcon from '../../library/icons/orgs/github_icon.svg';
import AzureIcon from '../../library/icons/orgs/azure_icon.svg';
import {MAKO} from '../../style/colors';
import OpenSourceIcon from '../../library/icons/open-source-icon.svg';
import BitbucketIcon from '../../library/icons/orgs/bitbucket_icon.svg';
import OrgShapeIcon from '../../library/icons/orgs/org_icon.svg';

export const VCS_ORG_PROVIDERS = {
  github: <GithubIcon />,
  azure: <AzureIcon />,
  bitbucket: <BitbucketIcon />,
  gitlab: <GithubIcon />
};

export const VCS_ORG_PROVIDERS_NAMES = {
  github: 'GitHub',
  azure: 'Azure',
  bitbucket: 'Bitbucket',
  gitlab: 'GitLab'
};

const Container = glamorous.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
});

const Organization = glamorous.div({
  margin: '0 7px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
});

const Name = glamorous.a({
  fontSize: 14,
  lineHeight: 1.3,
  marginLeft: 4,
  padding: 0,
  color: MAKO,
  marginRight: 5,
  cursor: 'pointer'
});

const OrgName = glamorous.div({
  fontSize: 14,
  lineHeight: 1.3,
  marginLeft: 4,
  padding: 0,
  color: MAKO,
  marginRight: 5,
  cursor: 'pointer'
});

const OrgIcon = glamorous.div({
  display: 'flex',
  marginLeft: 4.5,
  '> svg': {
    width: 14,
    height: 14
  }
});

const LinkToRepoContainer = glamorous.div({
  display: 'flex',
  alignItems: 'center'
});

const OrgShape = glamorous(OrgShapeIcon)({
  width: 14,
  height: 14
});

const InfoBox = ({org, bracketsCheck = true, customStyle = {}, linkToRepo, item}) => {
  const getVcsIcon = () => {
    return VCS_ORG_PROVIDERS[org.vcsProvider.toLowerCase()];
  };

  return org ? (
    <Container style={{...customStyle}}>
      <Organization>
        {bracketsCheck && '('}
        <OrgIcon>{org.vcsProvider ? getVcsIcon() : <OrgShape />}</OrgIcon>
        {org.vcsProvider ? (
          <Name href={org.orgUrl} title={`${org.name}`}>
            {org.name}
          </Name>
        ) : (
          <OrgName>{org.name}</OrgName>
        )}
        {bracketsCheck && ')'}
      </Organization>
      {linkToRepo && (
        <LinkToRepoContainer>
          {'/'}
          <OrgIcon style={{marginTop: 1}}>
            <OpenSourceIcon />
          </OrgIcon>
          <Name href={item.node.websiteUrl} title={`${item.node.slug}`}>
            {item.node.slug}
          </Name>
        </LinkToRepoContainer>
      )}
    </Container>
  ) : (
    <></>
  );
};

InfoBox.propTypes = {
  org: PropTypes.any,
  bracketsCheck: PropTypes.bool,
  customStyle: PropTypes.object,
  linkToRepo: PropTypes.bool,
  item: PropTypes.object
};
export default InfoBox;
