import React, {useState, useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import {debounce} from '../../utils/debounce';
import DefaultSearchIcon from '../../../shared/library/icons/search.svg';
import {WHITE} from '../../style/colors';

const SearchIcon = glamorous(DefaultSearchIcon)({
  margin: '0 12px 0 14px'
});

const SearchContainer = glamorous.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'space-between'
});

const SearchInputWrapper = glamorous.div({
  marginBottom: 5,
  height: 39,
  width: '100%',
  backgroundColor: WHITE,
  borderRadius: 4,
  display: 'flex',
  alignItems: 'center'
});

const SearchInput = glamorous.input({
  width: '100%',
  height: '100%',
  backgroundColor: WHITE,
  border: 0,
  fontSize: 13,
  lineHeight: 1.15,
  ':focus': {
    outline: 'none'
  }
});

const SearchView = ({placeholder, context}) => {
  const _context = useContext(context);
  const [searchText, setSearchText] = useState(_context.searchText);

  useEffect(() => {
    if (searchText) {
      getSearchedData(searchText);
    } else getSearchedData('');
  }, [searchText]);

  const getSearchedData = debounce(value => {
    _context.setSearchText(value);
  }, 500);

  const onSearch = value => {
    setSearchText(value);
  };

  return (
    <SearchContainer>
      <SearchInputWrapper>
        <SearchIcon />
        <SearchInput
          placeholder={placeholder}
          value={searchText}
          onChange={e => onSearch(e.target.value)}
        />
      </SearchInputWrapper>
    </SearchContainer>
  );
};

SearchView.defaultProps = {
  placeholder: 'Search '
};

SearchView.propTypes = {
  placeholder: PropTypes.string,
  context: PropTypes.object
};

export default SearchView;
