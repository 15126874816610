import React from 'react';
import PropTypes from 'prop-types';
import SignUpForPrivateStackshareBeta from '../../../bundles/enterprise/components/sign-up-for-private-stackshare-beta';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';

const SITE_KEY = process.env.GOOGLE_RECAPTCHA_SITE_KEY;

const StackShareEnterpriseForm = ({onDismiss, title, type, mobile, defaultValues}) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={SITE_KEY}>
      <SignUpForPrivateStackshareBeta
        onDismiss={onDismiss}
        title={title}
        type={type}
        mobile={mobile}
        defaultValues={defaultValues}
      />
    </GoogleReCaptchaProvider>
  );
};

StackShareEnterpriseForm.propTypes = {
  onDismiss: PropTypes.func,
  mobile: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.oneOf(['signup', 'lead']),
  defaultValues: PropTypes.object
};

export default StackShareEnterpriseForm;
