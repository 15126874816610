import React from 'react';
import {BITBUCKET_BLUE} from '../../../../style/colors';
import BitbucketUser from './../bitbucket_user.svg';
import GithubUser from './../github_user.svg';
import AzureUser from './../azure_user.svg';
import OktaUser from './../okta_user.svg';

export const FORCED_VCS_PROVIDERS = {
  github: {
    name: 'Github',
    slug: 'github',
    icon: <GithubUser />,
    backgroundColor: '#222',
    borderColor: '#222'
  },
  bitbucket: {
    name: 'Bitbucket',
    slug: 'bitbucket',
    icon: <BitbucketUser />,
    backgroundColor: BITBUCKET_BLUE,
    borderColor: BITBUCKET_BLUE
  },
  azure: {
    name: 'Azure',
    slug: 'azure',
    icon: <AzureUser />,
    backgroundColor: '#0072C6',
    borderColor: '#0072C6'
  },
  okta: {
    name: 'Okta',
    slug: 'okta',
    icon: <OktaUser />,
    backgroundColor: '#007DC1',
    borderColor: '#007DC1'
  }
};
