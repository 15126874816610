import {ENTERPRISE_PLANS} from './payment-plans';
export const planPermissionCheck = (privateMode, plan) => {
  const getPlan =
    privateMode && privateMode.plans && Boolean(privateMode.plans.some(item => item.slug === plan));
  return getPlan;
};

export const isEnterpriseCompany = company => {
  if (company) {
    const plans = company.plans.map(plan => plan.slug);
    return plans.some(plan => ENTERPRISE_PLANS.includes(plan));
  } else return false;
};
