import glamorous from 'glamorous';
import {ASH, BLACK, FOCUS_BLUE, GUNSMOKE, SHADOW, WHITE} from '../../../shared/style/colors';
import Simple from '../../../shared/library/buttons/base/simple';
import {PAGE_WIDTH} from '../../../shared/style/dimensions';
import BaseText from '../../../shared/library/typography/text';

export const Container = glamorous.div({
  boxShadow: '0 1px 3px 0 #cacaca',
  borderRadius: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: 25,
  backgroundColor: WHITE,
  marginTop: 20
});

export const Icon = glamorous.div(
  {
    width: 30,
    height: 30,
    borderRadius: 30,
    backgroundColor: '#ebf7fe',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 10,
    '> svg': {
      ' path': {
        fill: FOCUS_BLUE
      }
    }
  },
  ({iconSize, customCss}) => ({
    '> svg': {
      ...iconSize
    },
    ...customCss
  })
);

export const Title = glamorous.h3(horizontal =>
  horizontal
    ? {
        fontWeight: 600,
        fontSize: 18,
        margin: 0,
        color: '#808080'
      }
    : {
        fontSize: 14,
        fontWeight: 'bold',
        color: GUNSMOKE,
        margin: 0,
        textAlign: 'center'
      }
);

export const ChildrenContainer = glamorous.div(
  {
    borderTop: `1px solid ${ASH}`,
    width: '100%',
    margin: '20px 0',
    padding: '20px 25px 0 25px',
    flexGrow: 1
  },
  ({customHeight}) => ({
    height: customHeight,
    overflowY: customHeight ? 'scroll' : 'none',
    overflowX: customHeight ? 'hidden' : 'none'
  })
);

export const ButtonContainer = glamorous.div({
  backgroundColor: '#fafafa',
  width: '100%',
  height: 63,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

export const Button = glamorous(Simple)({
  height: 32,
  padding: '0 30px',
  borderRadius: 20,
  fontWeight: 'bold',
  fontSize: 11,
  backgroundColor: '#08a0ff',
  borderColor: '#08a0ff',
  textTransform: 'uppercase'
}).withComponent('a');

export const ReportContainer = glamorous.div({
  width: PAGE_WIDTH,
  margin: 'auto',
  padding: '20px 0 120px 0'
});

export const ItemContainer = glamorous.div({
  display: 'flex',
  alignItems: 'center',
  padding: '10px 0',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${ASH}`,
  '&:last-child': {
    borderBottom: 'none'
  }
});

export const ItemInformation = glamorous.div({
  margin: '15px 0',
  display: 'flex',
  alignItems: 'center'
});

export const Stats = glamorous.div({
  display: 'flex',
  alignItems: 'center'
});

export const Name = glamorous(BaseText)(
  {
    fontSize: 18,
    color: BLACK,
    fontWeight: 'bold'
  },
  ({ellipses}) =>
    ellipses
      ? {whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '70%'}
      : {}
);

export const SubInfo = glamorous(BaseText)({
  fontSize: 14,
  color: SHADOW
});

export const Details = glamorous.div({
  marginLeft: 12,
  display: 'flex',
  flexDirection: 'column'
});

export const NoResultsText = glamorous(BaseText)({
  fontSize: 14,
  color: SHADOW,
  fontWeight: 'bold'
});
