import React, {useContext} from 'react';
import {ASH, CHARCOAL} from '../../../../shared/style/colors';
import {BASE_TEXT} from '../../../../shared/style/typography';
import glamorous from 'glamorous';
import PopoverWithAnchor from '../../popovers/base-v2';
import {TOP} from '../../../constants/placements';
import {PrivateModeContext} from '../../../../shared/enhancers/private-mode-enchancer';
import VersionStageIcon from '../../../library/icons/version_icon.svg';

const OverrideButton = glamorous.div(
  {
    ...BASE_TEXT,
    letterSpacing: 0.2,
    color: CHARCOAL,
    display: 'flex',
    border: `1px solid ${ASH}`,
    borderRadius: 20,
    outline: 'none',
    background: 'none',
    padding: '9px 11px',
    height: 30,
    fontSize: 13,
    lineHeight: '14px',
    width: 'fit-content',
    alignItems: 'center',
    textDecoration: 'none',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    fontWeight: 'bold',
    '> svg': {
      width: 13,
      height: 13
    },
    ':hover': {
      color: CHARCOAL,
      border: `1px solid ${ASH}`
    }
  },
  ({customStyle}) => (customStyle ? customStyle : {})
);

const Container = glamorous.div({
  position: 'relative',
  display: 'flex',
  marginLeft: 10
});

const Anchor = glamorous.a(
  {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  ({editPermission}) => ({
    '&:hover': {
      color: CHARCOAL,
      cursor: editPermission ? 'pointer' : 'default'
    }
  })
);

const VersionStageIconStyled = glamorous(VersionStageIcon)({
  width: 13,
  height: 13,
  margin: '1px 0 0 3px'
});

const VersionStage = () => {
  const privateMode = useContext(PrivateModeContext);
  const company = privateMode?.slug;
  const editPermission =
    privateMode?.permissions?.edit ||
    privateMode?.adoptionStages?.some(stage => stage?.permissions?.edit);

  return (
    <Container onClick={e => e.stopPropagation()}>
      <PopoverWithAnchor
        padding={10}
        placement={TOP}
        customStyle={{
          width: 300,
          fontWeight: 'bold',
          boxShadow: '0px 11px 20px rgba(0, 0, 0, 0.0539515)',
          borderRadius: 5,
          fontSize: 11
        }}
        activateMode="hover"
        hidden={true}
        anchor={
          <OverrideButton>
            <Anchor
              href={`/companies/${company}/manage-stages`}
              onClick={event => !editPermission && event.preventDefault()}
              editPermission={editPermission}
            >
              Version Stages <VersionStageIconStyled />
            </Anchor>
          </OverrideButton>
        }
        arrowStyle={{
          marginBottom: -8
        }}
      >
        Specific versions of this tool/package have assigned stages
      </PopoverWithAnchor>
    </Container>
  );
};

export default VersionStage;
