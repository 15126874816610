import React from 'react';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';
import DownArrow from '../../../../shared/library/icons/drop_icon.svg';
import {CHARCOAL, FOCUS_BLUE, SHADOW, WHITE} from '../../../../shared/style/colors';
import {BASE_TEXT, WEIGHT} from '../../../../shared/style/typography';
import EnterpriseIcon from '../../../../shared/library/icons/enterprise-tag.svg';
import {
  DESKTOP,
  LAPTOP,
  LAPTOP_WIDTH,
  MOBILE_WIDTH,
  PHONE
} from '../../../../shared/style/breakpoints';
import CurvedArrowIcon from '../../../../shared/library/icons/curved_arrow.svg';
import {isEnterpriseCompany} from '../../../../shared/utils/plan-permission-check';

const Container = glamorous.div({
  display: 'flex',
  alignItems: 'center',
  padding: '0 5px',
  [DESKTOP]: {
    height: 68
  },
  [PHONE]: {
    display: 'block',
    width: '100%',
    padding: 0,
    paddingTop: 10,
    justifyContent: 'space-between'
  }
});

const DownArrowStyled = glamorous(DownArrow)({
  alignSelf: 'center',
  marginLeft: 3
});

const ToggleContainer = glamorous.div({
  backgroundColor: WHITE,
  cursor: 'default',
  top: '100%',
  padding: '8px 12px 15px',
  minWidth: 225,
  position: 'absolute',
  [`@media only screen and (min-width: ${MOBILE_WIDTH + 1}px)`]: {
    border: '1px solid #EBEBEB',
    borderTop: 0,
    boxShadow: '0px 2px 4px rgba(190, 190, 190, 0.5)',
    borderRadius: 4,
    zIndex: 10000,
    right: 0,
    marginLeft: 7
  },
  [`@media only screen and (max-width: ${LAPTOP_WIDTH}px) and (min-width: ${MOBILE_WIDTH +
    1}px)`]: {
    right: '5%'
  },
  [PHONE]: {
    position: 'relative',
    padding: '8px 0 15px'
  }
});

const NavOption = glamorous.a(({customStyle}) => ({
  ...BASE_TEXT,
  width: '100%',
  height: 38,
  display: 'flex',
  alignItems: 'center',
  boxSizing: 'border-box',
  fontStyle: 'normal',
  cursor: 'pointer',
  fontSize: 14,
  fontWeight: WEIGHT.BOLD,
  lineHeight: '19px',
  padding: '9px 0 10px 18px',
  '&:hover': {
    background: '#F3F8FB'
  },
  [LAPTOP]: {
    textDecoration: 'none',
    color: CHARCOAL
  },
  [PHONE]: {
    textDecoration: 'none',
    color: CHARCOAL,
    marginBottom: 15,
    fontSize: 16
  },
  ...customStyle
}));

const EnterpriseIconStyled = glamorous(EnterpriseIcon)({
  marginLeft: 8
});

const UserImageStyled = glamorous.img(
  {
    width: 40,
    height: 40,
    borderRadius: '50%',
    ':hover': {
      border: `2px solid ${FOCUS_BLUE}`
    },
    [PHONE]: {
      width: 32,
      height: 32
    }
  },
  ({customStyle}) => ({
    border: customStyle ? `2px solid ${FOCUS_BLUE}` : '2px solid transparent'
  })
);

const Username = glamorous.span({
  ...BASE_TEXT,
  marginLeft: 13,
  fontSize: 16,
  fontWeight: WEIGHT.BOLD,
  color: SHADOW
});

const AvatarContainer = glamorous.div(
  {
    display: 'flex',
    justifyContent: 'space-between',
    [PHONE]: {
      padding: '10px 16px 10px 11px',
      marginBottom: 16
    }
  },
  ({customStyle}) => ({
    [PHONE]: {
      background: customStyle && '#F3F8FB'
    }
  })
);

const Separator = glamorous.div({
  borderBottom: '1px solid #f1f1f1',
  margin: '12px 18px',
  width: '100%',
  [LAPTOP]: {
    margin: '12px 0'
  }
});

const CurvedArrowIconStyled = glamorous(CurvedArrowIcon)({
  marginRight: 7
});

const NavbarAvatar = ({
  currentUser,
  mobile,
  avatarRef,
  open,
  setOpen,
  setCloseMenuOptions,
  closeMenuOptions
}) => {
  const companies = currentUser && currentUser.companies ? currentUser.companies : [];
  const closeMenuCondition = mobile ? () => setCloseMenuOptions(!closeMenuOptions) : () => {};

  return (
    <>
      <Container onClick={() => setOpen(!open)} innerRef={avatarRef} data-testid="avatarDropdown">
        <AvatarContainer customStyle={open}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <UserImageStyled
              src={currentUser?.imageUrl}
              alt={currentUser?.username}
              customStyle={open}
            />
            {mobile && <Username>{currentUser?.username}</Username>}
          </div>
          <DownArrowStyled />
        </AvatarContainer>

        {open && (
          <ToggleContainer>
            <NavOption
              href={`/${currentUser?.username}`}
              onClick={() => closeMenuCondition()}
              data-testid="profileClick"
            >
              Profile
            </NavOption>
            <NavOption
              href="/settings/email"
              onClick={() => closeMenuCondition()}
              data-testid="emailSettingsClick"
            >
              Email Settings
            </NavOption>
            {companies.length !== 0 && <Separator />}

            {companies.length !== 0 && (
              <NavOption
                customStyle={{
                  cursor: 'default',
                  background: '#F3F8FB',
                  '&:hover': {
                    color: CHARCOAL,
                    background: '#F3F8FB'
                  }
                }}
                onClick={() => closeMenuCondition()}
              >
                My Companies
              </NavOption>
            )}
            {companies.map(company => (
              <NavOption
                key={company.id}
                href={`/companies/${company.slug}`}
                customStyle={{paddingLeft: 18, ':hover': {background: 'none'}}}
                onClick={() => closeMenuCondition()}
                data-testid="companyClick"
              >
                <CurvedArrowIconStyled />
                {company.name} {isEnterpriseCompany(company) && <EnterpriseIconStyled />}
              </NavOption>
            ))}
            <NavOption
              href="/create-stack/stack-type"
              onClick={() => closeMenuCondition()}
              data-testid="createStack"
            >
              Create a Stack
            </NavOption>
            <NavOption href="/submit" onClick={() => closeMenuCondition()} data-testid="addTools">
              Add my tools
            </NavOption>
            <NavOption
              href="/manage-companies/join"
              onClick={() => closeMenuCondition()}
              data-testid="joinCompany"
            >
              Join a company
            </NavOption>

            {currentUser?.stripePortalUrl && (
              <NavOption
                href={`${currentUser.stripePortalUrl}`}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => closeMenuCondition()}
                data-testid="paymentSettings"
              >
                Payment Settings
              </NavOption>
            )}

            <Separator />

            <NavOption
              href="/users/logout"
              onClick={event => {
                closeMenuCondition();
                event.preventDefault();
                if (window.analytics) {
                  window.analytics.reset();
                }
                if (window.amplitude) window.amplitude.reset();
                window.location = event.target.href;
              }}
              data-testid="logout"
            >
              Sign Out
            </NavOption>
          </ToggleContainer>
        )}

        {open && <div onClick={() => setOpen(false)} />}
      </Container>
    </>
  );
};

NavbarAvatar.propTypes = {
  currentUser: PropTypes.object,
  mobile: PropTypes.bool,
  avatarRef: PropTypes.func,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  setCloseMenuOptions: PropTypes.func,
  closeMenuOptions: PropTypes.bool
};

export default NavbarAvatar;
