import React, {Fragment} from 'react';
import {renderServiceTile} from '../library/cards/post';

export const formatToolList = _tools => {
  const tools = _tools.filter(tool => !tool.empty && !tool.loading);
  return tools
    .map((tool, index) =>
      index === tools.length - 1
        ? `and ${tool.name}`
        : `${tool.name}${tools.length > 2 ? ',' : ''} `
    )
    .join('');
};

export const sliceTools = (tools, start, end, otherDetails) => {
  const {sendAnalyticsEvent, withDetails, lazyLoadImages} = otherDetails;
  return (
    <Fragment>
      {tools.slice(start, end).map((tool, index) => {
        return (
          <Fragment key={index}>
            {renderServiceTile(tool, sendAnalyticsEvent, withDetails, lazyLoadImages)}
          </Fragment>
        );
      })}
    </Fragment>
  );
};
