import React from 'react';
import glamorous from 'glamorous';
import {formatDate} from '../../../shared/utils/format.js';
import {BASE_TEXT} from '../../../shared/style/typography';
import {
  TOOL_ADDED,
  TOOL_REMOVED,
  VERSION_CHANGE,
  // STAGE_CHANGE,
  MANUALLY_GET_ADVICE,
  MANUALLY_GIVE_ADVICE,
  MANUALLY_CHOICE,
  MANUALLY_MIGRATION,
  MANUALLY_PROTIP,
  MANUALLY_INSIGHT,
  AUTO_CHOICE,
  COMMENT_ON_DECISION,
  STACK_CREATED,
  STACK_DELETED,
  REMOVE_MEMBER,
  ADD_MEMBER,
  VULNERABILITY_DETECTED
} from './recent-activities';
import DefaultStackIcon from '../../../shared/library/icons/default-stack-icon.svg';
import HighVulnerabilityIcon from '../../../shared/library/icons/high-severity.svg';
import LowVulnerabilityIcon from '../../../shared/library/icons/low-severity.svg';
import CriticalVulnerabilityIcon from '../../../shared/library/icons/critical-severity.svg';
import ModerateVulnerabilityIcon from '../../../shared/library/icons/moderate-severity.svg';
import CheckmarkGreenIcon from '../../../shared/library/icons/checkmark-green.svg';

export const TOOL = 'tool';
export const ICON = 'icon';
export const USER = 'user';

const ServiceTile = glamorous.img({
  border: 0,
  borderRadius: 23,
  height: 23,
  width: 23,
  margin: '0 2px',
  marginTop: 2
});

const StackIcon = glamorous(DefaultStackIcon)({
  width: 16,
  height: 16,
  margin: '0 2px'
});

const Severity = glamorous.span(
  {
    ...BASE_TEXT,
    fontSize: 13,
    fontWeight: 'bold',
    lineHeight: 2,
    letterSpacing: 0.17
  },
  ({color}) => ({
    color: `${color} !important`
  })
);

const dummyImage = 'https://img.stackshare.io/no-img-open-source.png';
const toolDefaultImage = 'https://img.stackshare.io/no-img-open-source.png';

export const formatDataRecentActivities = ({recentActivities: rawData, slug: companySlug}) => {
  return rawData.edges
    .map(data => {
      const node = data.node;
      let activityType = '';
      let activityImage = dummyImage;
      let activityLink = '';
      let text = <></>;
      let date = formatDate('MMM dd', new Date(node.createdAt));
      let type = TOOL;
      let license = {};

      switch (node.action) {
        case 'added_tool':
          activityType = TOOL_ADDED;
          type = TOOL;
          activityImage = node.activityObject?.tool
            ? node.activityObject.tool.imageUrl
            : toolDefaultImage;
          activityLink = node.extraDetails?.pull_request_url
            ? node.extraDetails.pull_request_url
            : node.activityObject?.stack?.canonicalUrl;

          license =
            node.activityObject?.tool && node.activityObject.tool.license
              ? node.activityObject.tool.license
              : {};

          text = node.activityObject && (
            <>
              <span style={{fontWeight: 'bold'}}>
                {node.activityObject.tool ? node.activityObject.tool.name : '<deleted>'}
              </span>
              {adoptionStage(
                node.extraDetails?.current_version_stage || node.extraDetails?.adoption_stage
              )}
              &nbsp;was added&nbsp;
              {node.activityObject.stack && (
                <>
                  to&nbsp;
                  {stackData(node.activityObject.stack)}
                </>
              )}
              {node.whoDidIt && whoDidIt(node.whoDidIt)}
            </>
          );

          break;

        case 'removed_tool':
          activityType = TOOL_REMOVED;
          type = TOOL;
          activityImage = node.activityObject?.tool
            ? node.activityObject.tool.imageUrl
            : toolDefaultImage;
          activityLink = node.extraDetails?.pull_request_url
            ? node.extraDetails.pull_request_url
            : node.activityObject?.stack?.canonicalUrl;

          license =
            node.activityObject?.tool && node.activityObject.tool.license
              ? node.activityObject.tool.license
              : {};

          text = node.activityObject && (
            <>
              <span style={{fontWeight: 'bold'}}>
                {node.activityObject.tool ? node.activityObject.tool.name : '<deleted>'}
              </span>
              {adoptionStage(
                node.extraDetails?.current_version_stage || node.extraDetails?.adoption_stage
              )}
              &nbsp;was removed&nbsp;
              {node.activityObject.stack && (
                <>
                  from&nbsp;
                  {stackData(node.activityObject.stack)}
                </>
              )}
              {node.whoDidIt && whoDidIt(node.whoDidIt)}
            </>
          );

          break;

        case 'changed_version':
          activityType = VERSION_CHANGE;
          type = TOOL;
          activityImage = node.activityObject?.tool
            ? node.activityObject.tool.imageUrl
            : toolDefaultImage;
          activityLink = node.extraDetails?.pull_request_url
            ? `${node.extraDetails.pull_request_url}`
            : node.activityObject?.tool
            ? `/${node.activityObject.tool.slug}`
            : '';

          license =
            node.activityObject?.tool && node.activityObject.tool.license
              ? node.activityObject.tool.license
              : {};

          text = node.activityObject && (
            <>
              <span style={{fontWeight: 'bold'}}>
                {node.activityObject.tool ? node.activityObject.tool.name : '<deleted>'}
              </span>
              {adoptionStage(node.extraDetails?.adoption_stage)}
              &nbsp;was updated &nbsp;
              {node.activityObject.stack && (
                <>
                  in&nbsp;
                  {stackData(node.activityObject.stack)}
                </>
              )}
              {node.whoDidIt && whoDidIt(node.whoDidIt)}
              &nbsp;
              {node.activityObject.oldVersionNumber && (
                <>
                  from&nbsp;
                  <span>{node.activityObject.oldVersionNumber}</span>&nbsp;
                  {adoptionStage(node.extraDetails?.previous_version_stage)}
                </>
              )}
              &nbsp;
              {node.activityObject.newVersionNumber && (
                <>
                  to&nbsp;
                  <span>{node.activityObject.newVersionNumber}</span>&nbsp;
                  {adoptionStage(node.extraDetails?.current_version_stage)}
                </>
              )}
            </>
          );
          break;

        // case 'stage_change':
        //   activityType = STAGE_CHANGE;
        //   type = TOOL;
        //   activityImage = node.activityObject.tool.imageUrl;
        //   activityLink = `/${node.activityObject.tool.slug}`;
        //   text = node.activityObject.tool && (
        //     <>
        //       <span style={{fontWeight: 'bold'}}>{node.activityObject.tool.name}’s</span>
        //       &nbsp;stage was updated
        //       {node.whoDidIt && (
        //         <>
        //           &nbsp;by&nbsp;
        //           <ServiceTile src={node.whoDidIt.imageUrl} />
        //           &nbsp;
        //           <span style={{fontWeight: 'bold'}}>{node.whoDidIt.displayName}</span>
        //         </>
        //       )}
        //       &nbsp;
        //       {node.activityObject.previousStage && (
        //         <>
        //           from&nbsp;
        //           <span>{node.activityObject.previousStage}</span>&nbsp;
        //         </>
        //       )}
        //       {node.activityObject.currentStage && (
        //         <>
        //           to&nbsp;
        //           <span>{node.activityObject.currentStage}</span>
        //         </>
        //       )}
        //     </>
        //   );
        //   break;

        case 'decision_created':
          if (node.extraDetails?.type === MANUALLY_GET_ADVICE) {
            activityType = MANUALLY_GET_ADVICE;
            type = USER;
            activityImage = node.whoDidIt?.imageUrl;
            activityLink =
              node.activityObject && node.activityObject.id
                ? `/${node.whoDidIt?.username}/decisions/${node.activityObject.id}`
                : null;
            text = node.whoDidIt && node.activityObject && (
              <>
                <span style={{fontWeight: 'bold'}}>{node.whoDidIt.displayName}</span>&nbsp; asked
                for advice on&nbsp;
                {node.activityObject.subjectTools &&
                  limitNoOfTools(node.activityObject.subjectTools, 2)}
                {node.activityObject.subjectTools &&
                  pluralToolChecker(node.activityObject.subjectTools, 2)}
                {node.activityObject.stack && (
                  <>
                    in&nbsp;
                    {stackData(node.activityObject.stack)}
                  </>
                )}
              </>
            );
          } else if (node.extraDetails?.type === MANUALLY_GIVE_ADVICE) {
            activityType = MANUALLY_GIVE_ADVICE;
            type = USER;
            activityImage = node.whoDidIt ? node.whoDidIt.imageUrl : '';
            activityLink =
              node.activityObject && node.activityObject.id
                ? `/${node.whoDidIt?.username}/decisions/${node.activityObject.id}`
                : null;
            text = node.whoDidIt && node.activityObject && (
              <>
                <span style={{fontWeight: 'bold'}}>{node.whoDidIt.displayName}</span>
                &nbsp; gave advice on&nbsp;
                {node.activityObject.subjectTools &&
                  limitNoOfTools(node.activityObject.subjectTools, 2)}
                {node.activityObject.subjectTools &&
                  pluralToolChecker(node.activityObject.subjectTools, 2)}
                {node.activityObject.stack && (
                  <>
                    in&nbsp;
                    {stackData(node.activityObject.stack)}
                  </>
                )}
              </>
            );
          } else if (node.extraDetails?.type === MANUALLY_CHOICE) {
            activityType = MANUALLY_CHOICE;
            type = USER;
            activityImage = node.whoDidIt ? node.whoDidIt.imageUrl : '';
            activityLink =
              node.activityObject && node.activityObject.id
                ? `/${node.whoDidIt?.username}/decisions/${node.activityObject.id}`
                : null;
            if (node.activityObject && node.activityObject.fromTools?.length === 0) {
              text = node.whoDidIt && node.activityObject && (
                <>
                  <span style={{fontWeight: 'bold'}}>{node.whoDidIt.displayName}</span>
                  &nbsp; chose to add&nbsp;
                  {node.activityObject.toTools && limitNoOfTools(node.activityObject.toTools, 2)}
                  {node.activityObject.toTools && pluralToolChecker(node.activityObject.toTools, 2)}
                  &nbsp;{' '}
                  {node.activityObject.stack && (
                    <>
                      in&nbsp;
                      {stackData(node.activityObject.stack)}
                    </>
                  )}
                </>
              );
            } else if (node.activityObject && node.activityObject.toTools?.length === 0) {
              text = node.whoDidIt && node.activityObject && (
                <>
                  <span style={{fontWeight: 'bold'}}>{node.whoDidIt.displayName}</span>
                  &nbsp; chose to remove&nbsp;
                  {node.activityObject.fromTools &&
                    limitNoOfTools(node.activityObject.fromTools, 2)}
                  {node.activityObject.fromTools &&
                    pluralToolChecker(node.activityObject.fromTools, 2)}
                  &nbsp;
                  {node.activityObject.stack && (
                    <>
                      in&nbsp;
                      {stackData(node.activityObject.stack)}
                    </>
                  )}
                </>
              );
            } else {
              text = node.whoDidIt && node.activityObject && (
                <>
                  <span style={{fontWeight: 'bold'}}>{node.whoDidIt.displayName}</span>
                  &nbsp; chose to add&nbsp;
                  {node.activityObject.toTools && limitNoOfTools(node.activityObject.toTools, 2)}
                  {node.activityObject.toTools && pluralToolChecker(node.activityObject.toTools, 2)}
                  and to remove&nbsp;
                  {node.activityObject.fromTools &&
                    limitNoOfTools(node.activityObject.fromTools, 2)}
                  {node.activityObject.fromTools &&
                    pluralToolChecker(node.activityObject.fromTools, 2)}
                  {node.activityObject.stack && (
                    <>
                      in&nbsp;
                      {stackData(node.activityObject.stack)}
                    </>
                  )}
                </>
              );
            }
          } else if (node.extraDetails?.type === MANUALLY_MIGRATION) {
            activityType = MANUALLY_MIGRATION;
            type = USER;
            activityImage = node.whoDidIt ? node.whoDidIt.imageUrl : '';
            activityLink =
              node.activityObject && node.activityObject.id
                ? `/${node.whoDidIt?.username}/decisions/${node.activityObject.id}`
                : null;
            text = node.whoDidIt && node.activityObject && (
              <>
                <span style={{fontWeight: 'bold'}}>{node.whoDidIt.displayName}</span>
                &nbsp;migrated from&nbsp;
                {node.activityObject.fromTools && limitNoOfTools(node.activityObject.fromTools, 2)}
                {node.activityObject.fromTools &&
                  pluralToolChecker(node.activityObject.fromTools, 2)}
                to&nbsp;
                {node.activityObject.toTools && limitNoOfTools(node.activityObject.toTools, 2)}
                {node.activityObject.toTools && pluralToolChecker(node.activityObject.toTools, 2)}
                {node.activityObject.stack && (
                  <>
                    in&nbsp;
                    {stackData(node.activityObject.stack)}
                  </>
                )}
              </>
            );
          } else if (node.extraDetails?.type === MANUALLY_PROTIP) {
            activityType = MANUALLY_PROTIP;
            type = USER;
            activityImage = node.whoDidIt ? node.whoDidIt.imageUrl : '';
            activityLink =
              node.activityObject && node.activityObject.id
                ? `/${node.whoDidIt?.username}/decisions/${node.activityObject.id}`
                : null;
            text = node.whoDidIt && node.activityObject && (
              <>
                <span style={{fontWeight: 'bold'}}>{node.whoDidIt.displayName}</span>
                &nbsp;shared a protip on&nbsp;
                {node.activityObject.subjectTools &&
                  limitNoOfTools(node.activityObject.subjectTools, 2)}
                {node.activityObject.subjectTools &&
                  pluralToolChecker(node.activityObject.subjectTools, 2)}
                {node.activityObject.stack && (
                  <>
                    in&nbsp;
                    {stackData(node.activityObject.stack)}
                  </>
                )}
              </>
            );
          } else if (node.extraDetails?.type === MANUALLY_INSIGHT) {
            activityType = MANUALLY_INSIGHT;
            type = USER;
            activityImage = node.whoDidIt ? node.whoDidIt.imageUrl : '';
            activityLink =
              node.activityObject && node.activityObject.id
                ? `/${node.whoDidIt?.username}/decisions/${node.activityObject.id}`
                : null;
            text = node.whoDidIt && node.activityObject && (
              <>
                <span style={{fontWeight: 'bold'}}>{node.whoDidIt.displayName}</span>
                &nbsp;shared insights on&nbsp;
                {node.activityObject.subjectTools &&
                  limitNoOfTools(node.activityObject.subjectTools, 2)}
                {node.activityObject.subjectTools &&
                  pluralToolChecker(node.activityObject.subjectTools, 2)}
                {node.activityObject.stack && (
                  <>
                    in&nbsp;
                    {stackData(node.activityObject.stack)}
                  </>
                )}
              </>
            );
          } else if (node.extraDetails?.type === AUTO_CHOICE) {
            activityType = AUTO_CHOICE;
            type = USER;
            activityImage = node.whoDidIt ? node.whoDidIt.imageUrl : '';
            activityLink =
              node.activityObject && node.activityObject.id
                ? `/${node.whoDidIt?.username}/decisions/${node.activityObject.id}`
                : null;
            if (node.activityObject && node.activityObject.fromTools?.length === 0) {
              text = node.whoDidIt && node.activityObject && (
                <>
                  <span style={{fontWeight: 'bold'}}>{node.whoDidIt.displayName}</span>
                  &nbsp; chose to add&nbsp;
                  {node.activityObject.toTools && limitNoOfTools(node.activityObject.toTools, 2)}
                  {node.activityObject.toTools && pluralToolChecker(node.activityObject.toTools, 2)}
                  {node.activityObject.stack && (
                    <>
                      in&nbsp;
                      {stackData(node.activityObject.stack)}
                    </>
                  )}
                </>
              );
            } else if (node.activityObject && node.activityObject.toTools?.length === 0) {
              text = node.whoDidIt && node.activityObject && (
                <>
                  <span style={{fontWeight: 'bold'}}>{node.whoDidIt.displayName}</span>
                  &nbsp; chose to remove&nbsp;
                  {node.activityObject.fromTools &&
                    limitNoOfTools(node.activityObject.fromTools, 2)}
                  {node.activityObject.fromTools &&
                    pluralToolChecker(node.activityImage.fromTools, 2)}
                  &nbsp;{' '}
                  {node.activityObject.stack && (
                    <>
                      in&nbsp;
                      {stackData(node.activityObject.stack)}
                    </>
                  )}
                </>
              );
            } else {
              text = node.whoDidIt && node.activityObject && node.extraDetails && (
                <>
                  <span style={{fontWeight: 'bold'}}>{node.whoDidIt.displayName}</span>
                  &nbsp; chose to add&nbsp;
                  {node.extraDetails.toTools && limitNoOfTools(node.extraDetails.toTools, 2)}
                  {node.extraDetails.toTools && pluralToolChecker(node.extraDetails.toTools, 2)}
                  <>
                    and to remove&nbsp;
                    {node.activityObject.fromTools &&
                      limitNoOfTools(node.activityObject.fromTools, 2)}
                    {node.activityObject.fromTools &&
                      pluralToolChecker(node.activityObject.fromTools, 2)}
                    &nbsp;
                  </>
                  {node.activityObject.stack && (
                    <>
                      in&nbsp;
                      {stackData(node.activityObject.stack)}
                    </>
                  )}
                </>
              );
            }
          }
          break;

        case 'comment_on_decision':
          activityType = COMMENT_ON_DECISION;
          type = USER;
          activityImage = node.whoDidIt ? node.whoDidIt.imageUrl : '';
          activityLink =
            node.activityObject && node.activityObject.id
              ? `/${node.whoDidIt?.username}/decisions/${node.activityObject.commentableId}`
              : null;
          text = node.whoDidIt && node.activityObject && node.extraDetails && (
            <>
              <span style={{fontWeight: 'bold'}}>{node.whoDidIt.displayName}</span>
              &nbsp; commented on the decision on&nbsp;
              {node.extraDetails.tools && limitNoOfTools(node.extraDetails.tools, 2)}
              {node.extraDetails.tools && pluralToolChecker(node.extraDetails.tools, 2)}
            </>
          );
          break;

        case 'stack_created':
          activityType = STACK_CREATED;
          type = ICON;
          activityLink = node.activityObject && node.activityObject.canonicalUrl;
          if (node.extraDetails?.auto_created) {
            text = node.activityObject && (
              <>
                <span style={{fontWeight: 'bold'}}>{node.extraDetails?.name}</span>
                &nbsp;was added
              </>
            );
          } else {
            text = node.extraDetails && (
              <>
                <span style={{fontWeight: 'bold'}}>{node.extraDetails.name}</span>
                &nbsp;was added{node.whoDidIt && <>&nbsp;{whoDidIt(node.whoDidIt)} </>}
              </>
            );
          }
          break;

        case 'stack_deleted':
          activityType = STACK_DELETED;
          type = ICON;
          activityLink = '';
          if (node.extraDetails?.auto_created) {
            text = (
              <>
                <span style={{fontWeight: 'bold'}}>{node.extraDetails.name}</span>
                &nbsp;was removed
              </>
            );
          } else {
            text = node.extraDetails && (
              <>
                <span style={{fontWeight: 'bold'}}>{node.extraDetails.name}</span>
                &nbsp;was removed {node.whoDidIt && <>&nbsp;{whoDidIt(node.whoDidIt)} </>}
              </>
            );
          }
          break;

        case 'add_member':
          activityType = ADD_MEMBER;
          type = TOOL;
          activityImage = node.extraDetails ? node.extraDetails.company_image_url : '';
          activityLink = `/companies/${companySlug}/dashboard/members`;
          text = node.extraDetails && node.extraDetails.users?.length !== 0 && (
            <>
              <span style={{fontWeight: 'bold'}}>{node.extraDetails.users.length}</span>&nbsp;
              {node.extraDetails.users.length > 1 ? 'members' : 'member'} joined your team &nbsp;
              {limitNoOfUsers(node.extraDetails.users, 3)}
              &nbsp;
              {node.extraDetails.users.length > 3 ? `+${node.extraDetails.users.length - 3}` : null}
            </>
          );
          break;

        case 'remove_member':
          activityType = REMOVE_MEMBER;
          type = TOOL;
          activityImage = node.extraDetails ? node.extraDetails.company_image_url : '';
          activityLink = `/companies/${companySlug}/dashboard/members`;
          text = node.extraDetails && node.extraDetails.users?.length !== 0 && (
            <>
              <span style={{fontWeight: 'bold'}}>{node.extraDetails.users.length}</span>
              &nbsp; {node.extraDetails.users.length > 1 ? 'members' : 'member'}&nbsp; left your
              team&nbsp;
              {limitNoOfUsers(node.extraDetails.users, 3)}
              &nbsp;
              {node.extraDetails.users.length > 3 ? `+${node.extraDetails.users.length - 3}` : null}
            </>
          );
          break;

        case 'vulnerability_detected': {
          const severity =
            node.activityObject && VulnerabilityTypes[node.activityObject.severity?.toUpperCase()];
          activityType = VULNERABILITY_DETECTED;
          type = TOOL;
          activityImage = node.activityObject?.tool
            ? node.activityObject.tool.imageUrl
            : toolDefaultImage;
          activityLink = `/companies/${companySlug}/dashboard/vulnerabilities?cve=${
            node.activityObject?.cveId
          }`;

          license =
            node.activityObject?.tool && node.activityObject.tool.license
              ? node.activityObject.tool.license
              : {};

          text = node.activityObject && (
            <>
              A vulnerability &nbsp;
              <span style={{fontWeight: 'bold'}}>{node.activityObject.name}</span> with &nbsp;
              <Severity color={severity.color}>{severity.title}</Severity>&nbsp; severity was
              detected for{' '}
              <span style={{fontWeight: 'bold'}}>
                {node.activityObject.tool ? node.activityObject.tool.name : '<deleted>'}
              </span>
              .&nbsp;The vulnerable version is used in&nbsp;{' '}
              {node.activityObject.vulnerabilityStacks?.count > 1
                ? `${node.activityObject.vulnerabilityStacks.count} stacks:`
                : `${node.activityObject.vulnerabilityStacks.count} stack:`}{' '}
              &nbsp;
              {node.activityObject.vulnerabilityStacks?.edges.slice(0, 3).map(item => (
                <>
                  {' '}
                  <StackIcon />
                  &nbsp;
                  <span style={{fontWeight: 'bold'}}>{item.node.stack.name}</span>&nbsp;{' '}
                </>
              ))}
              {node.activityObject.vulnerabilityStacks?.count > 3 &&
                `+${node.activityObject.vulnerabilityStacks.count - 3} more.`}
            </>
          );
          break;
        }
      }

      return {
        activityType: activityType,
        activityImage: activityImage,
        activityLink: activityLink,
        text: text,
        date: date,
        type: type,
        license
      };
    })
    .filter(item => item.text);
};

const stackData = stack => {
  if (stack)
    return (
      <>
        <StackIcon />
        &nbsp;<span>{stack.name}</span>&nbsp;
      </>
    );
  else
    return (
      <>
        <StackIcon />
        &nbsp;<span>{'<deleted>'}</span>&nbsp;
      </>
    );
};

const whoDidIt = name => {
  return (
    <>
      by&nbsp;
      <ServiceTile src={name.imageUrl} />
      &nbsp;
      <span style={{fontWeight: 'bold'}}>{name.displayName}</span>
    </>
  );
};

const adoptionStage = stage => {
  return <i>{stage ? ` (Stage: ${stage})` : ''}</i>;
};

const limitNoOfTools = (tools, limit = 2) => {
  return tools.slice(0, limit).map(tool => (
    <>
      {' '}
      <ServiceTile src={tool.imageUrl} />
      &nbsp;
      <span style={{fontWeight: 'bold'}}>{tool.name}</span>&nbsp;&nbsp;
    </>
  ));
};

const limitNoOfUsers = (users, limit = 3) => {
  return users.slice(0, limit).map(user => (
    <>
      {' '}
      <ServiceTile src={user.image_url} />
      &nbsp;
      <span style={{fontWeight: 'bold'}}>{user.display_name}</span>&nbsp;&nbsp;
    </>
  ));
};

const pluralToolChecker = (tools, limit) => {
  return tools.length > limit ? `+${tools.length - 2} tools ` : null;
};

export const VulnerabilityTypes = {
  CRITICAL: {
    title: 'Critical',
    color: '#EB5757',
    icon: <CriticalVulnerabilityIcon />
  },
  HIGH: {
    title: 'High',
    color: '#F2994A',
    icon: <HighVulnerabilityIcon />
  },
  MODERATE: {
    title: 'Moderate',
    color: '#E8C300',
    icon: <ModerateVulnerabilityIcon />
  },
  LOW: {
    title: 'Low',
    color: '#4F4F4F',
    icon: <LowVulnerabilityIcon />
  }
};

export const EOLColorScheme = {
  REACHED_EOL: {
    title: 'Red',
    color: '#EB5757',
    icon: <CriticalVulnerabilityIcon />
  },
  EOL_IN_LESS_THAN_6_MONTHS: {
    title: 'Orange',
    color: '#F2994A',
    icon: <HighVulnerabilityIcon />
  },
  EOL_IN_MORE_THAN_6_MONTHS: {
    title: 'Green',
    color: '#71CB2D',
    icon: <CheckmarkGreenIcon />
  }
};
