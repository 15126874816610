import React, {Fragment} from 'react';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';
import {BLACK, WHITE} from '../../../../shared/style/colors';
import {SEARCH_HIT_NAMES, SECTION_NAMES} from '../../../../shared/constants/hits';
import DefaultSearchIcon from '../../../../shared/library/icons/search.svg';
import {ArrowContainer} from '../../../../shared/library/popovers/hint/styles';
import Arrow from '../../../../shared/library/popovers/hint/arrow.svg';
import {BOTTOM_END} from '../../../../shared/constants/placements';
import PrivateLockIcon from '../../../../shared/library/icons/blue-round-large.svg';
import {decodeImage} from '../../../../shared/library/search/site/site-search-results';
import {WEIGHT} from '../../../../shared/style/typography';
import {DESKTOP, LAPTOP, TABLET} from '../../../../shared/style/breakpoints';

const HiddenContainer = glamorous.div(
  {
    border: '1px solid #ededed',
    boxShadow: '0px 2px 11px -2px #e8e8e8',
    position: 'absolute',
    width: 250,
    marginTop: 20,
    fontSize: 12,
    paddingTop: 14,
    backgroundColor: WHITE,
    top: 45,
    transform: 'translateX(-50%)'
  },
  ({customStyle}) => ({
    [DESKTOP]: {
      left: customStyle ? '85%' : '80%'
    },
    [LAPTOP]: {
      left: customStyle ? '74%' : '66%'
    },
    [TABLET]: {
      left: customStyle ? '70%' : '65%'
    }
  })
);

const SearchLink = glamorous.a({
  display: 'inline-block',
  marginBottom: 15,
  padding: '0 14px'
});

const SearchIcon = glamorous(DefaultSearchIcon)({
  width: 13,
  height: 13,
  margin: '0 9px -2px 0'
});

const SectionContainer = glamorous.div({
  borderBottom: '1px solid rgba(0,0,0,0.0470588)',
  overflow: 'hidden',
  marginBottom: 10,
  padding: '0 14px 5px'
});

const HitName = glamorous.span({
  float: 'left',
  fontWeight: WEIGHT.BOLD
});

const MoreLink = glamorous.a({
  float: 'right',
  fontSize: 11,
  color: 'rgba(0,0,0,0.298039)'
});

const Image = glamorous.img({
  float: 'left',
  width: 32,
  height: 32
});

const Name = glamorous.span({
  float: 'left',
  margin: '7px 0 0 7px',
  color: BLACK,
  maxWidth: 175,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: 12,
  fontWeight: WEIGHT.BOLD
});

const ImageNameContainer = glamorous.a({
  overflow: 'hidden',
  display: 'inline-flex',
  padding: '5px 14px',
  ':hover': {
    background: '#f0f0f0'
  }
});

const PrivateLockIconStyled = glamorous(PrivateLockIcon)({
  margin: '8px 0 0 8px'
});

const SearchDropdown = ({hits, searchText, currentUser}) => {
  const searchResults =
    hits.length > 0 &&
    hits.reduce(
      (results, current) => {
        const typeKey =
          current.type === 'Service' && current.isPackage === true
            ? 'Package'
            : current.type === 'Service' && current.isPackage === false
            ? 'Tool'
            : current.type;

        if (!results[typeKey]) {
          results[typeKey] = {
            sequence: results.sequence,
            type: typeKey,
            list: [current]
          };
          results.sequence++;
          results.result.push(results[typeKey]);
        } else {
          results[typeKey].list.push(current);
        }

        return results;
      },
      {sequence: 1, result: []}
    ).result;

  return (
    <HiddenContainer customStyle={currentUser}>
      <ArrowContainer data-placement={BOTTOM_END} style={{left: '43%'}}>
        <Arrow />
      </ArrowContainer>
      <SearchLink href={searchText ? `/search/q=${searchText}` : '/search'}>
        <span>
          <SearchIcon />
          {searchText ? `Search '${searchText}'` : 'Type your search'}
        </span>
      </SearchLink>
      {searchResults.length > 0 &&
        searchResults.map((result, i) => {
          return (
            <Fragment key={i}>
              {result.list.length > 0 && (
                <>
                  <SectionContainer>
                    <HitName>{SECTION_NAMES[result.type]}</HitName>
                    <MoreLink
                      href={`/search/q=${searchText}#${SEARCH_HIT_NAMES[result.type]}`}
                      data-testid="searchMoreClick"
                    >
                      More
                    </MoreLink>
                  </SectionContainer>
                  {result.list.map((item, index) => (
                    <p key={index}>
                      <ImageNameContainer
                        href={item.type === 'User' ? item.username : item.canonicalUrl}
                        data-testid="searchResultClick"
                      >
                        <Image src={decodeImage(item.type, item.imageUrl)} />
                        <Name>
                          {item.type === 'Stackup'
                            ? item.prettySlug
                            : item.type === 'User'
                            ? item.username
                            : item.type === 'Post'
                            ? item.title
                            : item.name}
                        </Name>
                        {item.isPrivate && <PrivateLockIconStyled />}
                      </ImageNameContainer>
                    </p>
                  ))}
                </>
              )}
            </Fragment>
          );
        })}
    </HiddenContainer>
  );
};

SearchDropdown.propTypes = {
  hits: PropTypes.object,
  searchText: PropTypes.string,
  currentUser: PropTypes.object
};

export default SearchDropdown;
