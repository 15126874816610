import {useState, useEffect, useContext} from 'react';
import {trackEvent} from '../../../data/shared/mutations';
import {ApolloContext} from '../../enhancers/graphql-enhancer';

function useTrackEventClick() {
  const client = useContext(ApolloContext);
  const [fireMutation, setFireMutation] = useState(false);
  const [eventData, setEventData] = useState({
    eventSubtype: '',
    adId: ''
  });

  useEffect(() => {
    if (fireMutation && client) {
      client
        .mutate({
          mutation: trackEvent,
          variables: {...eventData}
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.log('Something went wrong!', err);
        });
      setFireMutation(false);
    }
  }, [fireMutation, client]);

  const trackEventClickFunc = (eventSubtype, adId) => {
    setEventData({
      eventSubtype,
      adId
    });
    setFireMutation(true);
  };

  return trackEventClickFunc;
}

export default useTrackEventClick;
