import {
  PROMPT_TYPE_STACK_DECISION,
  STRUCTURE_GET_ADVICE
} from '../../shared/library/composer/constants';

export const buildStackupSlug = tools => tools.map(t => t.slug).join('-vs-');

export const padTools = (tools, num) => {
  let paddedTools = Array(num).fill({empty: true});
  for (let i = 0; i < num; i++) {
    if (tools[i]) {
      paddedTools[i] = tools[i];
    }
  }
  return paddedTools;
};

export const formatStackupName = tools => {
  return tools
    .map((tool, index) => (index === tools.length - 1 ? ` ${tool.name}` : `${tool.name} vs`))
    .join('');
};

export const stackupFooterPresenter = stackup => {
  return [
    {
      title: 'Related Comparisons',
      items: stackup.cachedFooterRelatedStackups
    },
    {
      title: 'Trending Comparisons',
      items: stackup.footerTrendingStackups
    },
    {
      title: 'Top Comparisons',
      items: stackup.footerTopStackups
    }
  ];
};

export const buildPostForStackupAskQuestion = tools => ({
  id: null,
  decisionType: STRUCTURE_GET_ADVICE,
  subjectTools: tools,
  prompt: {
    promptId: null,
    promptType: PROMPT_TYPE_STACK_DECISION
  }
});

export const updateQuery = (previousResult, {fetchMoreResult}) => {
  if (!fetchMoreResult) {
    return previousResult;
  }

  const itemName = Object.keys(previousResult.stackupBySlug)[0];

  return {
    ...previousResult,
    stackupBySlug: {
      ...previousResult.stackupBySlug,
      [itemName]: {
        ...previousResult[itemName],
        ...fetchMoreResult.stackupBySlug[itemName],
        edges: [
          ...previousResult.stackupBySlug[itemName].edges,
          ...fetchMoreResult.stackupBySlug[itemName].edges
        ]
      }
    }
  };
};

//Below slugs are created just for SEO experiment and will be removed once it's done
// Here's the trello link to know more : https://trello.com/c/RzqFP8ZB

export const alternativesSlugs = [
  'next-js-vs-php-mvc',
  'qt-creator-vs-visual-studio-code',
  'skype-vs-viber',
  'alpine-linux-vs-arch-linux',
  'amazon-pay-vs-paypal',
  'angularjs-vs-vaadin',
  'apollo-vs-socket-io',
  'aws-codepipeline-vs-gitlab',
  'azure-search-vs-solr',
  'box-vs-microsoft-sharepoint',
  'cocos2d-x-vs-phaserio',
  'docker-vs-docker-machine',
  'dojo-vs-react',
  'druid-vs-influxdb',
  'flutter-vs-swiftui',
  'glitch-vs-heroku',
  'google-compute-engine-vs-kubernetes',
  'gulp-vs-npm',
  'heroku-vs-red-hat-openshift',
  'ionic-vs-nodejs',
  'jest-vs-webdriverio',
  'knex-js-vs-mongodb',
  'milanote-vs-miro',
  'nodejs-vs-spring',
  'postgresql-vs-spark',
  'rabbitmq-vs-rocketmq',
  'spacy-vs-tensorflow',
  'amazon-chime-vs-zoom',
  'amazon-eks-vs-rancher',
  'amazon-rds-for-mysql-vs-snowflake',
  'apache-beam-vs-apache-nifi',
  'aws-codecommit-vs-azure-repos',
  'aws-secrets-manager-vs-azure-key-vault',
  'azure-redis-cache-vs-redis',
  'c-sharp-vs-cplusplus-vs-python',
  'clion-vs-eclipse',
  'cobol-vs-python',
  'common-lisp-vs-python',
  'crowdstrike-vs-fortinet-security',
  'denodo-vs-presto',
  'docker-vs-packer',
  'expressjs-vs-socket-io',
  'gatsbyjs-vs-hugo-vs-jekyll',
  'gitlab-vs-gogs',
  'gorgias-vs-zendesk',
  'grafana-vs-graylog',
  'heroku-vs-nginx',
  'jersey-vs-spring-mvc',
  'logback-vs-slf4j',
  'openfire-vs-rocketchat',
  'phaserio-vs-pixi',
  'postgresql-for-visual-studio-code-vs-tableplus',
  'spring-vs-symfony',
  'alamofire-vs-retrofit',
  'amazon-pay-vs-paytm',
  'amazon-s3-vs-postgresql',
  'amazon-sns-vs-firebase-cloud-messaging',
  'apache-beam-vs-kafka-streams',
  'azure-container-instances-vs-azure-container-service',
  'bazel-vs-gradle',
  'calendly-vs-you-can-book-me',
  'denodo-vs-tableau',
  'eclipse-microprofile-vs-spring-boot',
  'git-vs-plastic-scm',
  'graphql-vs-reduxjs',
  'intellij-idea-vs-webstorm',
  'jira-vs-testrail',
  'julia-vs-scala',
  'meteor-vs-react',
  'nodejs-vs-swoole',
  'oracle-vs-sqlite',
  'r-language-vs-r-languageuby',
  'airflow-vs-aws-data-pipeline',
  'amazon-dynamodb-vs-elasticsearch',
  'amazon-pinpoint-vs-google-analytics',
  'amazon-sqs-vs-azure-storage',
  'apache-parquet-vs-mongodb',
  'aws-amplify-vs-aws-appsync',
  'azure-devops-vs-visual-studio-app-center',
  'azure-machine-learning-vs-tensorflow',
  'c-vs-matlab',
  'chalice-vs-flask',
  'cloudflare-vs-zscaler',
  'devextreme-vs-kendo-ui',
  'docker-vs-snap-ci',
  'dot-net-vs-rust',
  'fedora-vs-linux-mint',
  'flink-vs-ksql',
  'google-cloud-sql-vs-sqlite',
  'hockeyapp-vs-testflight',
  'ionic-vs-nativescript',
  'jetbrains-rider-vs-visual-studio',
  'kafka-vs-signalr',
  'kvm-vs-lxd',
  'linqpad-vs-visual-studio-code',
  'mariadb-vs-percona',
  'mybatis-vs-spring-data',
  'perforce-vs-svn',
  'react-vs-vaadin',
  'sublime-text-vs-xcode',
  'amazon-athena-vs-amazon-quicksight',
  'amazon-elasticache-vs-amazon-rds-for-mysql',
  'apache-nifi-vs-logstash',
  'asyncio-vs-gevent',
  'aws-codedeploy-vs-aws-opswork',
  'azure-devops-vs-monday',
  'azure-service-fabric-vs-istio',
  'bit-vs-storybook',
  'bootstrap-vs-squarespace',
  'cacti-vs-grafana',
  'centos-vs-linux-mint',
  'cloudflare-registrar-vs-google-domains',
  'createjs-vs-phaserio',
  'data-studio-vs-metabase',
  'delayed_job-vs-resque-vs-sidekiq',
  'elasticsearch-vs-meilisearch',
  'electron-vs-kivy',
  'formik-vs-react-hook-form',
  'github-vs-replit',
  'google-hangouts-chat-vs-lets-chat',
  'gunicorn-vs-unicorn',
  'java-ee-vs-spring',
  'jinja2-vs-react',
  'leaflet-vs-openstreetmap',
  'microsoft-teams-vs-ringcentral',
  'next-js-vs-react-boilerplate',
  'playwright-vs-webdriverio',
  'pytorch-vs-trax',
  'snowflake-vs-snowplow',
  'vue-cli-vs-webpack',
  'admob-vs-google-adsense',
  'amazon-api-gateway-vs-amazon-route-53-vs-punch',
  'amazon-kinesis-vs-kafka-vs-rabbitmq',
  'apache-httpd-vs-firebase',
  'arcgis-vs-postgis',
  'asana-vs-confluence-vs-trello',
  'avro-vs-grpc',
  'aws-x-ray-vs-jaeger',
  'azure-key-vault-vs-vault',
  'blackduck-vs-veracode',
  'bokeh-vs-shiny',
  'centos-vs-kali-linux',
  'codeigniter-vs-laravel-vs-symfony',
  'dapr-vs-istio',
  'datadog-vs-kibana-vs-new-relic',
  'dbeaver-vs-tableplus',
  'django-vs-dot-net-core',
  'docker-vs-webpack',
  'fedora-vs-linux-mint-vs-ubuntu',
  'flutter-vs-nativescript-vs-react-native',
  'goland-vs-visual-studio-code',
  'hazelcast-vs-memcached',
  'istio-vs-netflix-oss',
  'jenkins-x-vs-red-hat-openshift',
  'kafka-vs-mysql',
  'material-design-for-angular-vs-vuetify',
  'microsoft-sharepoint-vs-nextcloud',
  'n8n-vs-tray-io',
  'oh-my-zsh-vs-powershell',
  'react-native-vs-swiftui',
  'sketch-vs-zeplin',
  'amazon-aurora-vs-azure-cosmos-db',
  'amazon-sagemaker-vs-kubeflow',
  'apache-dubbo-vs-grpc',
  'asp-net-core-vs-mono',
  'aws-lambda-vs-kafka',
  'azure-search-vs-google-maps',
  'blender-vs-sketchup',
  'buildbot-vs-jenkins',
  'cockroachdb-vs-mysql',
  'courier-vs-dovecot',
  'django-vs-hibernate',
  'elasticsearch-vs-sumo-logic',
  'event-store-vs-rabbitmq',
  'flutter-vs-phonegap',
  'franz-vs-microsoft-teams',
  'glassfish-vs-oracle-weblogic-server',
  'graylog-vs-loki',
  'ionic-vs-nativescript-vs-react-native',
  'jquery-vs-lodash',
  'kafka-vs-streamsets',
  'kvm-vs-libvirt',
  'mailcatcher-vs-mailhog',
  'nginx-vs-zuul',
  'pouchdb-vs-rxdb',
  'python-vs-r-language-vs-r-languageuby',
  'rundeck-vs-stackstorm',
  'socket-io-vs-xmpp',
  'sublime-text-vs-vim',
  'adobe-dreamweaver-vs-atom',
  'aha-vs-asana',
  'amazon-ec2-vs-apache-httpd-vs-nginx',
  'ambassador-2-vs-traefik',
  'angularjs-vs-xamarin',
  'apache-pulsar-vs-rabbitmq',
  'atlassian-stash-vs-github',
  'aws-import-export-vs-aws-snowball-edge',
  'azure-data-factory-vs-spark',
  'blackduck-vs-coverity-scan',
  'bulma-vs-material-design',
  'cachet-vs-status-io-vs-statuspage-io',
  'clevertap-vs-google-analytics',
  'codesandbox-vs-replit',
  'cplusplus-vs-matlab-vs-python',
  'databricks-vs-datarobot',
  'dialogflow-vs-tensorflow',
  'discord-vs-teamviewer',
  'django-vs-jhipster',
  'docker-vs-lando',
  'eclipse-vs-xcode',
  'elm-vs-haskell',
  'gatsbyjs-vs-vuepress',
  'google-ai-platform-vs-kubeflow',
  'haml-vs-pug',
  'insomnia-rest-client-vs-soap-ui',
  'java-vs-matlab',
  'jfrog-artifactory-vs-maven',
  'julia-vs-octave',
  'librenms-vs-nagios',
  'material-design-vs-semantic-ui',
  'mattermost-vs-microsoft-teams',
  'microsoft-access-vs-microsoft-sql-server',
  'nodejs-vs-vapor',
  'postgresql-vs-stack-overflow',
  'processing-js-vs-python',
  'rocketchat-vs-zulip',
  'stencil-vs-svelte',
  'tailwind-css-vs-uikit',
  'akka-vs-project-reactor',
  'amazon-elasticsearch-service-vs-azure-search-vs-elasticsearch',
  'amazon-s3-vs-azure-storage-vs-google-cloud-storage',
  'ansible-vs-aws-codedeploy',
  'appdynamics-vs-kibana',
  'arch-linux-vs-raspbian',
  'aws-cloudtrail-vs-azure-search',
  'aws-elastic-beanstalk-vs-google-app-engine',
  'aws-step-functions-vs-google-cloud-dataflow',
  'babel-vs-browserify',
  'bitwarden-vs-keepass',
  'c-sharp-vs-cplusplus-vs-visual-basic',
  'cisco-asa-vs-cisco-firepower',
  'confluence-vs-monday',
  'css-modules-vs-emotion',
  'delphi-vs-python',
  'docker-swarm-vs-nomad',
  'elasticsearch-vs-google-bigquery',
  'etcd-vs-zookeeper',
  'f-sharp-vs-ocaml',
  'firebase-vs-rethinkdb',
  'foundation-vs-uikit',
  'git-vs-npm'
];

export const consSlugs = [
  'microsoft-sql-server-vs-sybase',
  'power-bi-vs-sap-crystal-reports',
  'sendbird-vs-twilio',
  'adobe-photoshop-vs-inkscape',
  'amazon-emr-vs-serverless',
  'anaconda-vs-pandas',
  'apiary-vs-swagger-ui',
  'aws-app-mesh-vs-azure-service-fabric',
  'aws-lambda-vs-google-cloud-run',
  'bootstrap-studio-vs-pingendo',
  'c-sharp-vs-javascript-vs-python',
  'django-vs-flask-vs-laravel',
  'docker-vs-rancher',
  'drawio-vs-react-diagrams',
  'element-ui-vs-vuetify',
  'fullstory-vs-google-analytics',
  'go-vs-swift',
  'google-meet-vs-webex',
  'hasura-vs-prisma',
  'ibm-watson-vs-microsoft-azure',
  'jenkins-vs-puppet',
  'jitsi-vs-skype',
  'mariadb-vs-mongodb-vs-mysql',
  'nginx-vs-varnish',
  'php-vs-typescript',
  'qt-vs-wxwidgets',
  'rust-vs-typescript',
  'amazon-appstream-vs-amazon-workspaces',
  'amazon-ec2-vs-amazon-s3',
  'amazon-linux-vs-windows',
  'ant-vs-visual-studio-code',
  'arcgis-vs-mapbox',
  'aws-mobile-hub-vs-signalr',
  'azure-monitor-vs-elk',
  'behave-vs-robot-frame-work',
  'clickup-vs-monday',
  'cloudflare-vs-nextdns',
  'codeigniter-vs-react-native',
  'contentful-vs-firebase',
  'dart-vs-typescript',
  'docker-hub-vs-helm',
  'easyocr-vs-tesseract-js',
  'findbugs-vs-sonarlint',
  'github-vs-phabricator',
  'google-cloud-functions-vs-google-cloud-run',
  'gradle-vs-sbt',
  'gulp-vs-yarn',
  'jboss-data-grid-vs-redis',
  'keycloak-vs-passport',
  'octobercms-vs-wordpress',
  'paypal-vs-paysera',
  'polymer-vs-stencil',
  'sequel-pro-vs-tableplus',
  'adyen-vs-paypal-vs-stripe',
  'amazon-ec2-vs-firebase',
  'amazon-route-53-vs-namecheap',
  'amazon-ses-vs-mailchimp',
  'angularjs-vs-handlebars',
  'atom-vs-emacs',
  'babylonjs-vs-three-js',
  'bokeh-vs-dash',
  'cypress-vs-react-testing-library',
  'dot-net-core-vs-nodejs-vs-spring-boot',
  'flyway-vs-sequelize',
  'grafana-vs-redash',
  'ibm-watson-vs-microsoft-bot-framework',
  'jinja-vs-mustache',
  'julia-vs-r-language',
  'memcached-vs-varnish',
  'next-js-vs-sailsjs',
  'opsgenie-vs-pagerduty-vs-victorops',
  'quarkus-vs-vert-x',
  'telegraf-vs-zabbix',
  'amazon-cognito-vs-passport',
  'amazon-lex-vs-azure-bot-service',
  'amazon-sagemaker-vs-databricks',
  'apache-httpd-vs-wildfly',
  'atom-vs-webstorm',
  'azure-data-studio-vs-microsoft-sql-server-management-studio',
  'azure-machine-learning-vs-python',
  'bitbucket-vs-gitbucket',
  'celery-vs-dramatiq',
  'clojure-vs-python',
  'databricks-vs-splunk',
  'dingtalk-vs-zoom',
  'dot-net-vs-mean',
  'fedora-vs-kali-linux',
  'flink-vs-kafka-streams',
  'google-cloud-pubsub-vs-socket-io',
  'hexo-vs-hugo-vs-jekyll',
  'ibm-watson-vs-tensorflow',
  'javascript-vs-perl-vs-php',
  'json-vs-php',
  'kubernetes-vs-vagrant',
  'lerna-vs-yarn',
  'loggly-vs-papertrail',
  'msmq-vs-rabbitmq',
  'panda-vs-pandas',
  'quokka-vs-wombat',
  'slack-vs-telegram',
  'amazon-api-gateway-vs-apigility-vs-kong',
  'amazon-dynamodb-vs-mysql',
  'angularjs-vs-codeigniter',
  'arch-linux-vs-windows-10',
  'aws-cloudtrail-vs-splunk-cloud',
  'axios-vs-redux-saga',
  'azure-functions-vs-faas',
  'basecamp-vs-g-suite',
  'bootstrap-vs-jquery',
  'brave-vs-safari',
  'centos-vs-cloudlinux',
  'chalice-vs-zappa',
  'clubhouse-vs-jira',
  'dask-vs-pandas',
  'db2-vs-postgresql',
  'eclipse-vs-selenium',
  'elasticsearch-vs-seq',
  'flair-vs-spacy',
  'gatsbyjs-vs-react-static',
  'google-cloud-platform-vs-siteground',
  'graylog-vs-prometheus',
  'hue-vs-zeppelin',
  'jenkins-vs-zuul',
  'js-chart-vs-recharts',
  'mautic-vs-sendy',
  'mysql-workbench-vs-pgadmin-2',
  'php-vs-xml-format',
  'power-bi-vs-splunk-enterprise',
  'react-native-vs-spring-boot',
  'symfony-vs-wordpress',
  'xml-format-vs-yaml',
  'airflow-vs-hadoop',
  'amazon-kinesis-firehose-vs-kafka',
  'apache-beam-vs-aws-glue',
  'apscheduler-vs-celery',
  'arch-linux-vs-fedora',
  'auth0-vs-json-web-token',
  'aws-elastic-load-balancing-vs-f5-big-ip',
  'azure-key-vault-vs-keepass',
  'beego-vs-gin-gonic',
  'blazor-vs-javascript',
  'bugsnag-vs-rollbar-vs-sentry',
  'cobol-vs-cplusplus',
  'cplusplus-vs-css-3',
  'datadog-vs-instana',
  'datagrip-vs-navicat',
  'django-vs-django-cms',
  'docker-swarm-vs-kubernetes-vs-rancher',
  'eslint-vs-jslint',
  'firebase-vs-google-analytics',
  'gitlab-ci-vs-teamcity',
  'grafana-vs-sentry',
  'influxdb-vs-mariadb',
  'java-vs-python-vs-r-language',
  'jstl-vs-thymeleaf',
  'laravel-vs-mean',
  'microsoft-iis-vs-xampp',
  'mosquitto-vs-vernemq',
  'notepad-plus-plus-vs-pycharm',
  'pytorch-vs-xgboost',
  'realm-vs-redux-persist',
  'webflow-vs-weebly',
  'amazon-redshift-vs-cassandra',
  'ansible-vs-jenkins-vs-puppet',
  'appium-vs-selendroid',
  'aws-codedeploy-vs-go-cd',
  'azure-cdn-vs-azure-redis-cache',
  'blackduck-vs-whitesource',
  'bugzilla-vs-mantis',
  'c-vs-php',
  'consul-vs-kong',
  'digitalocean-vs-google-compute-engine',
  'drupal-vs-laravel',
  'eureka-vs-zookeeper',
  'firebase-vs-mixpanel',
  'font-awesome-vs-google-fonts',
  'gevent-vs-gunicorn',
  'grafana-vs-thanos-2',
  'influxdb-vs-prometheus',
  'jib-vs-kaniko',
  'kafka-vs-rabbitmq-vs-zeromq',
  'kong-vs-krakend',
  'mac-os-x-vs-window-server',
  'mockito-vs-robolectric',
  'postman-vs-socket-io-vs-swagger-ui',
  'pyspark-vs-scala',
  'rstudio-vs-xcode',
  'sequelize-vs-sqlalchemy',
  'spark-vs-talend',
  'tilda-vs-wordpress',
  'aerospike-vs-redis',
  'amazon-dynamodb-vs-amazon-sqs',
  'amazon-quicksight-vs-google-analytics-vs-taplytics',
  'angularjs-vs-dart',
  'apache-hive-vs-apache-parquet',
  'appium-vs-visual-studio-code',
  'aws-codedeploy-vs-terraform',
  'aws-waf-vs-palo-alto-networks',
  'bitrise-vs-jenkins-x',
  'bugzilla-vs-redmine',
  'c-sharp-vs-php',
  'cassandra-vs-redis',
  'cmder-vs-conemu',
  'contentful-vs-netlify-cms',
  'crowdstrike-vs-okta',
  'demandware-vs-salesforce-commerce-cloud',
  'discord-vs-hangouts',
  'django-rest-framework-vs-go',
  'docker-vs-jenkins',
  'echarts-vs-highcharts',
  'elk-vs-wazuh',
  'freedcamp-vs-trello',
  'go-vs-python-vs-rust',
  'grpc-vs-zeromq',
  'hugo-vs-next-js',
  'intellij-idea-vs-vim',
  'javascript-vs-lua',
  'json-server-vs-mongodb',
  'kubernetes-vs-octopus-deploy',
  'looker-vs-superset',
  'matlab-vs-python-vs-visual-basic',
  'microsoft-access-vs-microsoft-powerapps',
  'mxnet-vs-tensorflow',
  'outsystems-vs-react-native',
  'primefaces-vs-primeng',
  'react-360-vs-react-vr',
  'splunk-vs-zabbix',
  'swiftui-vs-uikit',
  'airtable-vs-google-forms',
  'amazon-chime-vs-twilio',
  'amazon-rds-for-mysql-vs-digitalocean-managed-databases-vs-geniedb',
  'angularjs-vs-symfony',
  'appdynamics-vs-grafana',
  'arangodb-vs-neo4j',
  'auth0-vs-passport',
  'aws-elastic-beanstalk-vs-aws-opswork',
  'aws-iot-device-management-vs-google-cloud-iot-core',
  'azure-monitor-vs-kibana',
  'beekeeper-studio-vs-dbeaver',
  'bulma-vs-material-ui',
  'c-vs-lua',
  'cloudinary-vs-google-cloud-cdn',
  'create-react-app-vs-react-boilerplate',
  'datadog-vs-pagerduty',
  'django-vs-gin-gonic',
  'eclipse-che-vs-theia',
  'eslint-vs-sass-lint',
  'expressjs-vs-lumen',
  'firebase-hosting-vs-vercel',
  'flask-vs-rails',
  'geany-vs-visual-studio-code'
];

export const decisionSlugs = [
  'consul-vs-serf',
  'consul-vs-eureka',
  'amazon-dynamodb-vs-cloudant',
  'amazon-dynamodb-vs-azure-cosmos-db',
  'amazon-rds-for-mysql-vs-google-cloud-sql',
  'cleardb-vs-google-cloud-sql',
  'bitbucket-vs-github',
  'github-vs-gitlab',
  'github-vs-rhodecode',
  'github-vs-gitolite',
  'bitbucket-vs-gitlab',
  'bitbucket-vs-gogs',
  'bitbucket-vs-upsource',
  'gitbucket-vs-gitlab',
  'gitlab-vs-gogs',
  'gitlab-vs-upsource',
  'amazon-ec2-vs-rackspace-cloud-servers',
  'amazon-ec2-vs-digitalocean',
  'amazon-ec2-vs-linode',
  'amazon-ec2-vs-webfaction',
  'microsoft-azure-vs-rackspace-cloud-servers',
  'linode-vs-rackspace-cloud-servers',
  'digitalocean-vs-microsoft-azure',
  'linode-vs-microsoft-azure',
  'digitalocean-vs-google-compute-engine',
  'digitalocean-vs-webfaction',
  'google-compute-engine-vs-linode',
  'linode-vs-scaleway',
  'amazon-emr-vs-google-bigquery',
  'amazon-redshift-vs-google-bigquery',
  'google-bigquery-vs-qubole',
  'google-bigquery-vs-xplenty',
  'hipchat-vs-slack',
  'campfire-vs-slack',
  'hall-vs-slack',
  'fogbugz-vs-jira',
  'bugzilla-vs-jira',
  'browserling-vs-browserstack',
  'karma-runner-vs-sauce-labs',
  'rainforest-qa-vs-selenium',
  'karma-runner-vs-selenium',
  'amazon-ebs-vs-amazon-s3',
  'amazon-s3-vs-google-cloud-storage',
  'amazon-ebs-vs-google-cloud-storage',
  'amazon-route-53-vs-dyn',
  'amazon-route-53-vs-dnsimple',
  'amazon-route-53-vs-dns-made-easy',
  'dns-made-easy-vs-google-cloud-dns',
  'bitcasa-vs-dropbox',
  'blazemeter-vs-loader-io',
  'flood-io-vs-loader-io',
  'squarespace-vs-yola',
  'google-analytics-vs-piwik',
  'bigpanda-vs-pagerduty',
  'cronitor-vs-pagerduty',
  'jasmine-vs-mocha',
  'jasmine-vs-jest',
  'jasmine-vs-qunit',
  'ansible-vs-puppet',
  'capistrano-vs-chef',
  'ansible-vs-aws-opswork',
  'ansible-vs-fabric',
  'ansible-vs-shipit',
  'cloudcms-vs-contentful',
  'cloudcms-vs-prismic-io',
  'docker-vs-vagrant-cloud',
  'amazon-sqs-vs-ironmq',
  'amazon-sqs-vs-rabbitmq',
  'amazon-sqs-vs-kafka',
  'amazon-sqs-vs-celery',
  'amazon-sqs-vs-nsq',
  'ironmq-vs-kafka',
  'activemq-vs-cloudamqp',
  'kafka-vs-rabbitmq',
  'celery-vs-rabbitmq',
  'nsq-vs-rabbitmq',
  'activemq-vs-kafka',
  'activemq-vs-celery',
  'kafka-vs-zeromq',
  'celery-vs-kafka',
  'kafka-vs-nsq',
  'celery-vs-zeromq',
  'rsmq-vs-zeromq',
  'celery-vs-nsq',
  'gearman-vs-nsq',
  'kissmetrics-vs-mixpanel',
  'crosswalk-vs-xamarin',
  'nativescript-vs-xamarin',
  'react-native-vs-xamarin',
  'ionic-vs-sencha-touch',
  'framework7-vs-phonegap',
  'ionic-vs-phonegap',
  'crosswalk-vs-ionic',
  'apache-cordova-vs-crosswalk',
  'apache-cordova-vs-framework7',
  'nativescript-vs-react-native',
  'ionic-vs-react-native',
  'bugsnag-vs-sentry',
  'raygun-vs-sentry',
  'errbit-vs-sentry',
  'bugsnag-vs-raygun',
  'bugsnag-vs-overops',
  'bugsnag-vs-rollbar',
  'supportbee-vs-zendesk',
  'groovehq-vs-zendesk',
  'amplitude-vs-flurry',
  'amplitude-vs-localytics',
  'asana-vs-trello',
  'flow-vs-trello',
  'redmine-vs-trello',
  'aha-vs-trello',
  'asana-vs-confluence',
  'asana-vs-huddle',
  'asana-vs-roadmunk',
  'basecamp-vs-confluence',
  'confluence-vs-redmine',
  'confluence-vs-roadmunk',
  'aha-vs-roadmunk',
  'algolia-vs-elasticsearch',
  'azure-search-vs-elasticsearch',
  'algolia-vs-amazon-cloudsearch',
  'mailgun-vs-twilio-sendgrid',
  'mailgun-vs-postmark',
  'mailgun-vs-sendwithus',
  'amazon-ses-vs-twilio-sendgrid',
  'mailjet-vs-twilio-sendgrid',
  'amazon-ses-vs-mandrill',
  'amazon-ses-vs-mailjet',
  'nexmo-vs-twilio',
  'tropo-vs-twilio',
  'github-pages-vs-webflow',
  'bitballoon-vs-netlify',
  'github-pages-vs-netlify',
  'gooddata-vs-looker',
  'looker-vs-mode',
  'mode-vs-periscope',
  'shopify-vs-spree',
  'magento-vs-spree',
  'semaphore-vs-travis-ci',
  'jenkins-vs-travis-ci',
  'teamcity-vs-travis-ci',
  'go-cd-vs-travis-ci',
  'circleci-vs-drone-io',
  'buildkite-vs-circleci',
  'buildbot-vs-circleci',
  'circleci-vs-go-cd',
  'jenkins-vs-semaphore',
  'buildkite-vs-drone-io',
  'buildbot-vs-drone-io',
  'jenkins-vs-shippable',
  'jenkins-vs-snap-ci',
  'buildbot-vs-jenkins',
  'jenkins-vs-strider',
  'buildbot-vs-teamcity',
  'amazon-cognito-vs-auth0',
  'auth0-vs-satellizer',
  'auth0-vs-omniauth',
  'amazon-cognito-vs-devise',
  'amazon-sns-vs-urban-airship',
  'amazon-rds-for-postgresql-vs-elephantsql',
  'sublime-text-vs-textmate',
  'emacs-vs-sublime-text',
  'gedit-vs-sublime-text',
  'neovim-vs-sublime-text',
  'coteditor-vs-sublime-text',
  'textmate-vs-vim',
  'atom-vs-textmate',
  'notepad-plus-plus-vs-vim',
  'atom-vs-vim',
  'codemirror-vs-vim',
  'atom-vs-emacs',
  'brackets-vs-emacs',
  'atom-vs-notepad-plus-plus',
  'codemirror-vs-notepad-plus-plus',
  'brackets-vs-gedit',
  'atom-vs-coteditor',
  'brackets-vs-codemirror',
  'librato-vs-new-relic',
  'appsignal-vs-new-relic',
  'datadog-vs-new-relic',
  'blackfire-vs-new-relic',
  'datadog-vs-scout',
  'appdynamics-vs-logicmonitor',
  'appsignal-vs-skylight',
  'datadog-vs-skylight',
  'datadog-vs-instrumental',
  'heroku-vs-red-hat-openshift',
  'engine-yard-cloud-vs-heroku',
  'appharbor-vs-heroku',
  'heroku-vs-ninefold',
  'deis-vs-heroku',
  'heroku-vs-tsuru',
  'clever-cloud-vs-heroku',
  'braintree-vs-stripe',
  'stripe-vs-zuora',
  'paypal-vs-stripe',
  'spreedly-vs-stripe',
  'chargebee-vs-stripe',
  'adyen-vs-stripe',
  'amazon-fps-vs-braintree',
  'braintree-vs-killbill',
  'chargebee-vs-recurly',
  'chargify-vs-paypal',
  'paypal-vs-wepay',
  'gocardless-vs-paypal',
  'chargebee-vs-spreedly',
  'adyen-vs-gocardless',
  'firebase-vs-pusher',
  'firebase-vs-simperium',
  'firebase-vs-firehose',
  'pubnub-vs-pusher',
  'pusher-vs-simperium',
  'pusher-vs-socket-io',
  'faye-vs-pubnub',
  'nats-vs-pubnub',
  'faye-vs-socket-io',
  'nats-vs-socket-io',
  'bower-vs-npm',
  'browserify-vs-npm',
  'framer-vs-origami',
  'django-vs-rails',
  'padrino-vs-rails',
  'dot-net-vs-rails',
  'mojolicious-vs-rails',
  'rails-vs-trailblazer',
  'django-vs-laravel',
  'grails-vs-laravel',
  'laravel-vs-revel',
  'laravel-vs-play',
  'laravel-vs-mean',
  'laravel-vs-symfony',
  'laravel-vs-phalcon',
  'kohana-vs-laravel',
  'laravel-vs-volt',
  'laravel-vs-sane-stack',
  'django-vs-padrino',
  'django-vs-revel',
  'django-vs-play',
  'django-vs-lift-web-framework',
  'atmosphere-vs-django',
  'django-vs-symfony',
  'django-vs-phalcon',
  'django-vs-php-mvc',
  'django-vs-volt',
  'django-vs-dropwizard',
  'django-vs-webapp2',
  'dot-net-vs-spring',
  'mean-vs-spring',
  'spring-vs-symfony',
  'spring-vs-yii',
  'spring-vs-yesod'
];
