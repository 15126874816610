export const toolLoadingCounter = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20
];

export const membersLoadingCounter = [1, 2, 3, 4, 5];

export const membersReportsLoadingCounter = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const stacksLoadingCounter = [1, 2, 3, 4, 5, 6];

export const filtersLoadingCounter = [1, 2, 3, 4, 5];

export const recentActivitiesLoadingCounter = [1, 2, 3, 4, 5, 6];

export const recentActivitiesReportsLoadingCounter = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20
];

export const vulnerabilityReportsLoadingCounter = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
