export const PLANS = {
  ENTERPRISE: 'private-enterprise',
  FREE: 'private-free-company',
  STARTUP: 'private-startup-mar-2021',
  TEAM: 'private-team-500'
};

export const CTAS = {
  SELECT: 'Select',
  UPGRADE: 'Upgrade',
  DOWNGRADE: 'Downgrade',
  REQUEST_DEMO: 'Request a Demo',
  CONTACT_US: 'Contact Us'
};
