import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {ASH, CHARCOAL, FOCUS_BLUE, CATHEDRAL} from '../../../../shared/style/colors';
import {BASE_TEXT} from '../../../../shared/style/typography';
import glamorous from 'glamorous';
import RenameIcon from '../../icons/edit-new.svg';
import AssignStageIcon from '../../icons/assign-stage.svg';
import AssignStageIconBig from '../../icons/assign-stage-big.svg';
import {PrivateModeContext} from '../../../../shared/enhancers/private-mode-enchancer';
import PopoverWithInteractive from '../../popovers/interactive';
import {TOP} from '../../../constants/placements';
import StageContextContainer from './context';
import {truncateText} from '../../../../shared/utils/truncate-text';
import ContextIcon from '../../icons/context.svg';
import Arrow, {ARROW_TOP} from './arrow.jsx';
import {PopperOffsetContext} from '../../popovers/interactive';
import sanitizeData from './../../../../shared/utils/sanitize-data';
import History from './history';

const OverrideButton = glamorous.a(
  {
    ...BASE_TEXT,
    width: 'fit-content',
    letterSpacing: 0.2,
    color: CHARCOAL,
    display: 'flex',
    border: `1px solid ${ASH}`,
    borderRadius: 8,
    outline: 'none',
    background: 'none',
    paddingLeft: 8,
    paddingRight: 8,
    alignItems: 'center',
    cursor: 'pointer',
    textDecoration: 'none',
    boxSizing: 'border-box',
    height: 18,
    justifyContent: 'space-between',
    margin: '0 10px',
    fontSize: 11,
    ':hover': {
      color: CHARCOAL
    },
    ' span': {
      fontWeight: 'bold',
      marginRight: 7,
      maxWidth: 100,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '.viewMode': {
      ' span': {
        margin: 0,
        fontWeight: 'bold',
        marginRight: 7,
        maxWidth: 100,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      },
      cursor: 'default'
    }
  },
  ({customStyle}) => (customStyle ? customStyle : {})
);

const Container = glamorous.div(
  {
    position: 'relative',
    display: 'flex'
  },
  ({customStyle}) => (customStyle ? customStyle : {})
);

const AssignStageContainer = glamorous.a(
  {
    display: 'flex',
    marginLeft: 7,
    '> svg': {
      width: '92px !important',
      height: '18px !important'
    }
  },
  ({editPermission, customStyle}) => ({
    ...customStyle,
    pointerEvents: !editPermission && 'none',
    '&:hover': {
      cursor: editPermission ? 'pointer' : 'default'
    }
  })
);

const Anchor = glamorous.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '&:hover': {
    color: CHARCOAL
  }
});

const InfoBox = glamorous.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%'
});

const ContextText = glamorous.p({
  fontSize: 12,
  lineHeight: '18px',
  marginBottom: 0,
  color: CHARCOAL,
  whiteSpace: 'break-spaces',
  overflow: 'hidden',
  '> p': {
    marginBottom: 0,
    '>a': {
      color: FOCUS_BLUE,
      cursor: 'pointer',
      ':hover': {
        textDecoration: 'underline'
      }
    }
  }
});

const ContextContainer = glamorous.div(
  {
    position: 'relative',
    marginTop: 16,
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid #e7e7e7`,
    borderRadius: 4,
    padding: '21px 19px',
    maxWidth: 400,
    height: 'auto'
  },
  ({customStyle}) => (customStyle ? customStyle : {})
);

const TooltipText = glamorous.div({
  '> p': {
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '18px',
    color: CHARCOAL,
    margin: 0,
    '>a': {
      color: FOCUS_BLUE,
      cursor: 'pointer',
      ':hover': {
        textDecoration: 'underline'
      }
    }
  }
});

const EditIcon = glamorous(RenameIcon)({
  width: '10px !important',
  height: '10px !important',
  marginLeft: '0 !important'
});

const ContextCheckIcon = glamorous(ContextIcon)({
  marginRight: '5px !important',
  width: '8px !important',
  height: '8px !important',
  marginLeft: '0 !important'
});

const AddContextText = glamorous.a({
  ...BASE_TEXT,
  fontWeight: 'normal',
  lineHeight: '22px',
  fontSize: 13,
  color: FOCUS_BLUE,
  ':hover': {
    cursor: 'pointer',
    textDecoration: 'underline'
  }
});

const Divider = glamorous.div({
  margin: `27px 0 20px 0`,
  borderBottom: `1px solid #e4e4e4`,
  width: '100%'
});

const AdoptionStage = ({
  name = '',
  canEdit,
  customStyle,
  showAssignStage,
  isContext = false,
  stage,
  toolSlug,
  tool,
  showStageHistory = false
}) => {
  const privateMode = useContext(PrivateModeContext);
  const company = privateMode && privateMode.slug;
  const stages = privateMode && privateMode.adoptionStages;
  const editPermission = canEdit && name;
  const showViewMode = !canEdit && name;
  const isContextCheck = stage && stage.context && stage.context.htmlContent;
  const isTool = tool && tool.toolType;
  const defaultUrl = `/companies/${company}/manage-stages${isTool ? '' : '?package=true'}`;
  const techRadarUrl = `/companies/${company}/technology-radar?tool=${toolSlug}`;
  const stageHistoryCount = tool?.adoptionStageHistories?.count;
  const hasVersionRules = tool?.versionRules?.count > 0;
  const [showStageContextContainer, setShowStageContextContainer] = useState(false);
  const [showAssignStagePermission, setShowAssignStagePermission] = useState(false);
  const [showEditButton, setShowEditButton] = useState(false);

  useEffect(() => {
    if (showAssignStage && canEdit && !name) setShowAssignStagePermission(true);
    if (canEdit && name) setShowEditButton(true);
  }, []);

  useEffect(() => {
    if (toolSlug) {
      setShowStageContextContainer(false);

      if (showAssignStage && canEdit && !name) {
        setShowAssignStagePermission(true);
        setShowEditButton(false);
      } else if (canEdit && name) {
        setShowEditButton(true);
        setShowAssignStagePermission(false);
      } else if (!canEdit && name) {
        setShowEditButton(false);
        setShowAssignStagePermission(false);
      }
    }
  }, [toolSlug, name]);

  return !isContext ? (
    <Container onClick={e => e.stopPropagation()} customStyle={customStyle}>
      {editPermission && (
        <CTAButton
          name={name}
          icon={<EditIcon />}
          className="editMode"
          onClick={techRadarUrl}
          customStyle={{
            borderRadius: 20,
            padding: '9px 11px',
            height: 30,
            fontSize: 13,
            lineHeight: '14px',
            width: 'fit-content',
            ':hover': {
              border: `1px solid ${CATHEDRAL}`
            }
          }}
          activateMode={isContextCheck ? 'hover' : 'manual'}
          stage={stage}
          isContextCheck={isContextCheck}
          tool={tool}
        />
      )}
      {showAssignStagePermission && (
        <AssignStageContainer
          href={defaultUrl}
          target="_self"
          editPermission={canEdit}
          className="assignViewMode"
          customStyle={{
            '> svg': {
              width: '109px !important',
              height: '26px !important'
            }
          }}
        >
          <AssignStageIcon />
        </AssignStageContainer>
      )}
      {showViewMode && (
        <CTAButton
          name={name}
          className="viewMode"
          activateMode={isContextCheck ? 'hover' : 'manual'}
          stage={stage}
          isContextCheck={isContextCheck}
          customStyle={{
            borderRadius: 20,
            padding: '9px 11px',
            height: 30,
            fontSize: 13,
            lineHeight: '14px'
          }}
          tool={tool}
        />
      )}
      {showStageHistory && !hasVersionRules && <History data={tool} count={stageHistoryCount} />}
    </Container>
  ) : (
    // when context is available
    <Container
      customStyle={{
        flexDirection: 'column',
        marginTop: showStageContextContainer ? 27 : 0
      }}
    >
      {!showStageContextContainer && <Divider />}
      {showEditButton && name && (
        <InfoBox>
          <CTAButton
            name={name}
            icon={<EditIcon />}
            className="editMode"
            onClick={() => {
              setShowStageContextContainer(true);
              setShowEditButton(false);
            }}
            activateMode={'manual'}
            customStyle={{
              borderRadius: 20,
              padding: '9px 11px',
              height: 30,
              fontSize: 13,
              lineHeight: '14px',
              marginLeft: 0,
              ':hover': {
                border: `1px solid ${CATHEDRAL}`
              }
            }}
            isContextCheck={false}
            tool={tool}
          />
          <ContextContainer
            customStyle={{
              padding: isContextCheck ? '21px 19px' : '9px 16px'
            }}
          >
            {isContextCheck ? (
              <>
                <ContextText
                  dangerouslySetInnerHTML={{
                    __html: sanitizeData(stage.context.htmlContent)
                  }}
                />
                <Arrow side={ARROW_TOP} />
              </>
            ) : (
              <AddContextText
                onClick={() => {
                  setShowStageContextContainer(true);
                  setShowEditButton(false);
                }}
              >
                <div>Add context for stage</div>
                <Arrow side={ARROW_TOP} />
              </AddContextText>
            )}
          </ContextContainer>
        </InfoBox>
      )}
      {showAssignStagePermission && !name && (
        <AssignStageContainer
          href="#"
          onClick={e => {
            e.preventDefault();
            setShowStageContextContainer(true);
            setShowAssignStagePermission(false);
          }}
          customStyle={{
            marginLeft: 0,
            '> svg': {
              width: '109px !important',
              height: '26px !important'
            }
          }}
          editPermission={canEdit}
        >
          <AssignStageIconBig />
        </AssignStageContainer>
      )}
      {showStageContextContainer && (
        <StageContextContainer
          stages={stages}
          toolStage={stage}
          toolSlug={toolSlug}
          onCloseHandler={() => {
            setShowStageContextContainer(false);
            if (showAssignStage && canEdit && !name) {
              setShowAssignStagePermission(true);
            } else setShowEditButton(true);
          }}
        />
      )}
      {showViewMode && (
        <InfoBox>
          <CTAButton
            name={name}
            className="viewMode"
            activateMode="manual"
            customStyle={{
              marginLeft: 0,
              borderRadius: 20,
              padding: '9px 11px',
              height: 30,
              fontSize: 13,
              lineHeight: '14px'
            }}
            isContextCheck={false}
            tool={tool}
          />
          {isContextCheck && (
            <ContextContainer
              customStyle={{
                padding: isContextCheck ? '21px 19px' : '10px 19px'
              }}
            >
              <>
                <ContextText
                  dangerouslySetInnerHTML={{
                    __html: sanitizeData(stage.context.htmlContent)
                  }}
                />
                <Arrow side={ARROW_TOP} />
              </>
            </ContextContainer>
          )}
        </InfoBox>
      )}
      {showStageHistory && !hasVersionRules && <History data={tool} count={stageHistoryCount} />}
    </Container>
  );
};

const CTAButton = ({
  name,
  icon,
  className,
  onClick,
  customStyle,
  activateMode,
  stage,
  isContextCheck,
  tool
}) => {
  const privateMode = useContext(PrivateModeContext);
  const company = privateMode && privateMode.slug;
  const stageContextContent =
    stage && stage.context && stage.context.htmlContent ? stage.context.htmlContent : '';
  const text =
    stageContextContent.length > 500
      ? truncateText(stageContextContent, 500, '...') +
        `<a href="/companies/${company}/technology-radar?tool=${tool.slug}">read more</a>`
      : stageContextContent;
  const title = `<div style="margin-bottom:7px;"><i><strong>${
    tool.name
  }</strong> has been marked as <strong>${name}</strong> with the following context:</i></div>`;

  return (
    <PopperOffsetContext.Provider value="0,0">
      <PopoverWithInteractive
        padding={0}
        placement={TOP}
        customStyle={{
          width: 300,
          boxShadow: '0px 11px 20px rgba(0, 0, 0, 0.0539515)',
          borderRadius: 5
        }}
        activateMode={activateMode}
        hidden={true}
        anchor={
          <OverrideButton
            className={className}
            href={onClick}
            onClick={e => {
              e.stopPropagation();
            }}
            target="_self"
            customStyle={customStyle}
          >
            <Anchor onClick={typeof onClick === 'function' ? () => onClick() : () => {}}>
              {isContextCheck && <ContextCheckIcon />}
              <span>{name}</span>
              {icon ? icon : null}
            </Anchor>
          </OverrideButton>
        }
        arrowStyle={{
          marginBottom: -5,
          marginTop: -6
        }}
        delay={500}
        flipPadding={{
          top: 70
        }}
      >
        {stageContextContent && (
          <>
            <TooltipText
              dangerouslySetInnerHTML={{
                __html: sanitizeData(title + text)
              }}
              style={{
                height: 'auto',
                whiteSpace: 'break-spaces',
                overflow: 'hidden',
                width: 'inherit',
                padding: '13px 16px',
                textAlign: 'start'
              }}
            />
          </>
        )}
      </PopoverWithInteractive>
    </PopperOffsetContext.Provider>
  );
};

CTAButton.propTypes = {
  name: PropTypes.string,
  icon: PropTypes.any,
  className: PropTypes.string,
  onClick: PropTypes.func,
  customStyle: PropTypes.object,
  activateMode: PropTypes.oneOf(['hover', 'manual']),
  stage: PropTypes.object,
  isContextCheck: PropTypes.bool,
  tool: PropTypes.object
};

AdoptionStage.propTypes = {
  name: PropTypes.object,
  canEdit: PropTypes.bool,
  customStyle: PropTypes.object,
  showAssignStage: PropTypes.bool,
  isContext: PropTypes.bool,
  stage: PropTypes.object,
  toolSlug: PropTypes.string,
  tool: PropTypes.object,
  showStageHistory: PropTypes.bool
};

AdoptionStage.defaultProps = {
  name: null,
  canEdit: false,
  customStyle: {},
  showAssignStage: true
};

export default AdoptionStage;
