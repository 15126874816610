import gql from 'graphql-tag';

export const techRadar = gql`
  query techRadar($slugs: [String!]) {
    currentPrivateCompany {
      id
      slug
      adoptionStages(slugs: $slugs) {
        id
        name
        slug
        order
        permissions {
          edit
        }
        tools {
          count
          edges {
            node {
              id
              name
              slug
              imageUrl
              layer {
                slug
                name
              }
              function {
                name
                slug
              }
              category {
                name
                slug
              }
              adoptionStageContext
            }
          }
        }
      }
    }
  }
`;

export const approvedTools = gql`
  query approvedTools($query: String) {
    approvedTools(query: $query) {
      count
      edges {
        node {
          id
          name
          slug
          imageUrl
          layer {
            slug
            name
          }
        }
      }
    }
  }
`;

export const toolBySlug = gql`
  query tool($id: ID!) {
    tool(id: $id) {
      id
      name
      slug
      title
      imageUrl
      canonicalUrl
      function {
        name
        slug
      }
      layer {
        slug
        name
      }
      toolType
      adoptionStageContext
      adoptionStage {
        id
        slug
        name
      }
      adoptionStageHistories {
        count
      }
      category {
        name
        slug
      }
      versionRules {
        count
      }
    }
  }
`;

export const toolStageHistory = gql`
  query toolStageHistory($id: ID!, $first: Int, $after: String) {
    tool(id: $id) {
      id
      name
      slug
      title
      imageUrl
      adoptionStageContext
      adoptionStage {
        id
        slug
        name
      }
      adoptionStageHistories(first: $first, after: $after) {
        count
        edges {
          node {
            whoDidIt {
              id
              username
              path
            }
            action
            extraDetails
            createdAt
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`;
