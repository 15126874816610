import {
  functionFilters,
  categoryFilters,
  layerFilters,
  applicationFilters,
  packageFilters,
  stageFilters,
  tagFilters,
  vulnerabilityFilters,
  teamsFilters,
  stacksFilters,
  stageExclusionFilters,
  licenseFilters,
  visibilityFilters,
  endOfLifeFilters
} from '../../../data/company-dashboard/queries';
import {
  userFunctionFilters,
  userCategoryFilters,
  userLayerFilters,
  userApplicationFilters,
  userPackageFilters,
  userStageFilters,
  userTagFilters,
  userVulnerabilityFilters,
  userTeamsFilters,
  userStacksFilters,
  userLicenseFilters,
  userVisibilityFilters,
  userEndOfLifeFilters
} from '../../../data/user-dashboard/queries';

export const SEPARATOR = '=';

export const filterQueryKeys = {
  ['f' + SEPARATOR]: 'FUNCTION',
  ['c' + SEPARATOR]: 'CATEGORY',
  ['l' + SEPARATOR]: 'LAYER',
  ['a' + SEPARATOR]: 'STACK_APP',
  ['p' + SEPARATOR]: 'PACKAGE_MANAGER',
  ['s' + SEPARATOR]: 'ADOPTION_STAGE',
  ['t' + SEPARATOR]: 'STACK_TAG',
  ['te' + SEPARATOR]: 'TEAMS',
  ['st' + SEPARATOR]: 'STACKS',
  ['se' + SEPARATOR]: 'VULNERABILITY',
  ['e' + SEPARATOR]: 'STAGE_EXCLUSION',
  ['li' + SEPARATOR]: 'LICENSE',
  ['v' + SEPARATOR]: 'STACK_VISIBILITY',
  ['eol' + SEPARATOR]: 'END_OF_LIFE'
};

export const getQueryData = (startsWith, item) => {
  if (startsWith)
    return item
      .split(startsWith)[1]
      .split('%2C')
      .map(temp => ({
        slug: temp,
        checked: true
      }));
  else return [];
};

export const mapFilter = (filter, currentOrg) => {
  switch (filter) {
    case FILTERS.FUNCTION:
      return {
        heading: 'Tool Group',
        isSearch: true,
        query: functionFilters,
        type: FILTERS.FUNCTION
      };
    case FILTERS.CATEGORY:
      return {
        heading: 'Category',
        isSearch: true,
        query: categoryFilters,
        type: FILTERS.CATEGORY
      };
    case FILTERS.LAYER:
      return {
        heading: 'Layer',
        isSearch: false,
        query: layerFilters,
        type: FILTERS.LAYER
      };
    case FILTERS.STACK_APP:
      return {
        heading: 'Applications',
        isSearch: false,
        query: applicationFilters,
        type: FILTERS.STACK_APP,
        variables: {orgId: currentOrg && currentOrg.id}
      };
    case FILTERS.PACKAGE_MANAGER:
      return {
        heading: 'Package Manager',
        isSearch: false,
        query: packageFilters,
        type: FILTERS.PACKAGE_MANAGER,
        variables: {orgId: currentOrg && currentOrg.id}
      };
    case FILTERS.ADOPTION_STAGE:
      return {
        heading: 'Stages (Include)',
        isSearch: false,
        query: stageFilters,
        type: FILTERS.ADOPTION_STAGE,
        toolTip: 'Show stacks that contain tools in the selected stage.'
      };
    case FILTERS.STACK_TAG:
      return {
        heading: 'Tag',
        isSearch: false,
        query: tagFilters,
        type: FILTERS.STACK_TAG
      };

    case FILTERS.VULNERABILITY:
      return {
        heading: 'Vulnerabilities Severity',
        isSearch: false,
        query: vulnerabilityFilters,
        type: FILTERS.VULNERABILITY,
        staticValues: true,
        toolTip:
          'Show stacks that contain tools/packages with vulnerabilities of the selected severity.'
      };

    case FILTERS.TEAMS:
      return {
        heading: 'Teams',
        isSearch: true,
        query: teamsFilters,
        type: FILTERS.TEAMS,
        variables: {orgId: currentOrg && currentOrg.id}
      };

    case FILTERS.STACKS:
      return {
        heading: 'Stacks',
        isSearch: true,
        query: stacksFilters,
        type: FILTERS.STACKS,
        variables: {orgId: currentOrg && currentOrg.id}
      };

    case FILTERS.STAGE_EXCLUSION:
      return {
        heading: 'Stages (Exclude)',
        isSearch: false,
        query: stageExclusionFilters,
        type: FILTERS.STAGE_EXCLUSION,
        toolTip: 'Do not show stacks that contain tools in the selected stage.'
      };

    case FILTERS.LICENSE:
      return {
        heading: 'Licenses',
        isSearch: true,
        query: licenseFilters,
        type: FILTERS.LICENSE,
        variables: {orgId: currentOrg && currentOrg.id}
      };

    case FILTERS.STACK_VISIBILITY:
      return {
        heading: 'Stack Visibility',
        isSearch: false,
        query: visibilityFilters,
        staticValues: true,
        type: FILTERS.STACK_VISIBILITY
      };

    case FILTERS.END_OF_LIFE:
      return {
        heading: 'End Of Life',
        isSearch: false,
        query: endOfLifeFilters,
        staticValues: true,
        type: FILTERS.END_OF_LIFE
      };

    default:
      return {
        heading: '',
        isSearch: false,
        query: null,
        type: ''
      };
  }
};

export const userMapFilter = filter => {
  switch (filter) {
    case FILTERS.FUNCTION:
      return {
        heading: 'Tool Group',
        isSearch: true,
        query: userFunctionFilters,
        type: FILTERS.FUNCTION
      };
    case FILTERS.CATEGORY:
      return {
        heading: 'Category',
        isSearch: true,
        query: userCategoryFilters,
        type: FILTERS.CATEGORY
      };
    case FILTERS.LAYER:
      return {
        heading: 'Layer',
        isSearch: false,
        query: userLayerFilters,
        type: FILTERS.LAYER
      };
    case FILTERS.STACK_APP:
      return {
        heading: 'Application',
        isSearch: false,
        query: userApplicationFilters,
        type: FILTERS.STACK_APP
      };
    case FILTERS.PACKAGE_MANAGER:
      return {
        heading: 'Package Manager',
        isSearch: false,
        query: userPackageFilters,
        type: FILTERS.PACKAGE_MANAGER
      };
    case FILTERS.ADOPTION_STAGE:
      return {
        heading: 'Stage',
        isSearch: false,
        query: userStageFilters,
        type: FILTERS.ADOPTION_STAGE
      };
    case FILTERS.STACK_TAG:
      return {
        heading: 'Tag',
        isSearch: false,
        query: userTagFilters,
        type: FILTERS.STACK_TAG
      };
    case FILTERS.VULNERABILITY:
      return {
        heading: 'Severity',
        isSearch: false,
        query: userVulnerabilityFilters,
        type: FILTERS.VULNERABILITY,
        staticValues: true
      };

    case FILTERS.TEAMS:
      return {
        heading: 'Teams',
        isSearch: true,
        query: userTeamsFilters,
        type: FILTERS.TEAMS
      };

    case FILTERS.STACKS:
      return {
        heading: 'Stacks',
        isSearch: true,
        query: userStacksFilters,
        type: FILTERS.STACKS
      };

    case FILTERS.LICENSE:
      return {
        heading: 'Licenses',
        isSearch: true,
        query: userLicenseFilters,
        type: FILTERS.LICENSE
      };

    case FILTERS.STACK_VISIBILITY:
      return {
        heading: 'Stack Visibility',
        isSearch: false,
        query: userVisibilityFilters,
        staticValues: true,
        type: FILTERS.STACK_VISIBILITY
      };

    case FILTERS.END_OF_LIFE:
      return {
        heading: 'End Of Life',
        isSearch: false,
        query: userEndOfLifeFilters,
        staticValues: true,
        type: FILTERS.END_OF_LIFE
      };

    default:
      return {
        heading: '',
        isSearch: false,
        query: null,
        type: ''
      };
  }
};

// Note: All filters values are mapped to the BE query. So if any filter name would come in future then making a change
// over here will do the job.
export const FILTERS = {
  FUNCTION: 'functions',
  CATEGORY: 'categories',
  LAYER: 'layers',
  STACK_APP: 'stackApps',
  PACKAGE_MANAGER: 'packageManagersFromStacks',
  ADOPTION_STAGE: 'adoptionStages',
  STACK_TAG: 'privateTags',
  VULNERABILITY: 'vulnerabilitySeverities',
  TEAMS: 'teams',
  STACKS: 'stacks',
  STAGE_EXCLUSION: 'adoptionStagesExclusion',
  LICENSE: 'licenses',
  STACK_VISIBILITY: 'visibilities',
  END_OF_LIFE: 'endOfLifeStatuses'
};

//This shows Filter to Tool fields mapping, like we sent functions in filter query, in return we get tool info with function.
export const FILTER_TOOL_MAPPING = {
  functions: 'function',
  categories: 'category',
  layers: 'layer'
};

export const setLocally = filters => {
  if (filters) localStorage.setItem('filters', JSON.stringify(filters));
};

export const getFilterArrayFromObject = obj => {
  if (!obj) return [];
  if (Object.keys(obj).length > 0) {
    let final = [];
    Object.keys(obj).forEach(element => {
      let mappedData =
        obj[element] &&
        obj[element].map(item => {
          return {
            ...item,
            filterType: element
          };
        });
      final = [...final, ...mappedData];
    });
    return final;
  } else return [];
};

export const mapArrayOfObject = obj => {
  if (!obj) return {};
  if (Object.keys(obj).length > 0) {
    let final = {};
    Object.keys(obj).forEach(element => {
      if (obj[element].length > 0) {
        let mappedData = obj[element].map(item => item.slug);
        final[element] = mappedData;
      }
    });
    return final;
  } else return {};
};

export const checkFilterValue = (typeFilters, data) => {
  return data.map(item => {
    const checked = typeFilters.some(temp => temp.slug === item.slug);
    return {...item, checked: checked};
  });
};

export const getFiltersQuery = () => {
  if (typeof window !== 'undefined' && window.location) {
    const filterQuery = {};
    const data = window?.location?.search && window.location.search.split('?')[1].split('&');

    if (data.length > 0)
      data.forEach(item => {
        const getFilterType = item.slice(0, item.indexOf(SEPARATOR)) + SEPARATOR;
        if (FILTERS[filterQueryKeys[getFilterType]])
          filterQuery[FILTERS[filterQueryKeys[getFilterType]]] = getQueryData(getFilterType, item);
      });

    return filterQuery;
  }
};
