import React, {useState, useEffect} from 'react';
import {Query} from 'react-apollo';
import {flattenEdges} from '../../shared/utils/graphql';
import {organizationsQuery} from '../../data/shared/queries';
import {serializeUrl as serializeUrlFunction} from '../../bundles/company-dashboard/shared/utils';
import flattenList from '../utils/orgs/flattened-org-list';
import {SERIALIZATION_TYPES} from '../../bundles/company-dashboard/shared/utils';
export const OrganizationContext = React.createContext({});

export const ORG_TYPES = {
  ALL: {
    id: 'all',
    vcsProvider: '',
    slug: 'all',
    name: 'All Organizations'
  },
  NONE: {
    id: '',
    vcsProvider: '',
    slug: '',
    name: ''
  }
};

export const ORG_STRUCTURE_MODES = {
  vcs: 'vcs',
  hybrid: 'hybrid',
  flexible: 'flexible'
};

const withOrganization = (serializeUrl = false) => Component => props => {
  const [currentOrg, setCurrentOrg] = useState(ORG_TYPES.ALL);
  const url = new URL(window.location);
  const urlParams = url.searchParams;

  let context = {
    loading: false,
    totalOrgsCount: 0,
    list: [],
    flatList: [],
    hasNextPage: false,
    endCursor: null,
    currentOrg,
    orgStructureMode: null
  };

  useEffect(() => {
    if (context.list.length > 0) findOrg();
  }, [window.location.search, context.list.length]);

  const findOrg = () => {
    const flatList = context.flatList;
    const tempOrg = urlParams.get('org') || JSON.parse(localStorage.getItem('currentOrg'))?.slug;
    const item = flatList.find(org => org.slug === tempOrg);
    if (item) {
      setOrg(item);
    } else setOrg(ORG_TYPES.ALL);
  };

  const setOrg = org => {
    if (org) {
      setCurrentOrg(org);
      localStorage.setItem('currentOrg', JSON.stringify(org));
    }

    if (org.slug === ORG_TYPES.ALL.slug) urlParams.delete('org');
    else urlParams.set('org', org.slug);

    if (serializeUrl) serializeUrlFunction(SERIALIZATION_TYPES.MANUAL, {org});
  };

  return (
    <Query
      query={organizationsQuery}
      variables={{query: '', first: 10, slug: urlParams.get('org')}}
    >
      {({loading, error, data}) => {
        if (loading) {
          context.loading = true;
        } else if (error) {
          context = {
            ...context,
            loading: false
          };
          localStorage.setItem('currentOrg', JSON.stringify(ORG_TYPES.NONE));
        } else if (data.currentPrivateCompany === null) {
          context = {
            ...context,
            loading: false
          };
          localStorage.setItem('currentOrg', JSON.stringify(ORG_TYPES.NONE));
        } else if (data.currentPrivateCompany) {
          const orgs = data.currentPrivateCompany.rootOrgs;
          const orgsList = flattenEdges(orgs).filter(org => org);
          const flatList = flattenList([...orgsList]);

          context = {
            ...context,
            totalOrgsCount: data.currentPrivateCompany.totalOrgsCount,
            loading: false,
            list: [ORG_TYPES.ALL, ...orgsList],
            flatList: [ORG_TYPES.ALL, ...flatList],
            hasNextPage: orgs.pageInfo.hasNextPage,
            endCursor: orgs.pageInfo.endCursor,
            orgStructureMode:
              data.currentPrivateCompany.orgStructureMode || ORG_STRUCTURE_MODES.vcs,
            setOrg
          };

          if (orgsList.length > 0) findOrg();
        }

        return (
          <OrganizationContext.Provider value={context}>
            <Component {...props} />
          </OrganizationContext.Provider>
        );
      }}
    </Query>
  );
};

export default withOrganization;
