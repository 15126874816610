import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import {Loader} from '../../loaders/loader';
import DownChevronIcon from '../../icons/chevron-down-icon.svg';
import {CHARCOAL, TARMAC, GREY, ERROR_RED, BIT_GREY, FOCUS_BLUE} from '../../../style/colors';
import {BASE_TEXT, WEIGHT} from '../../../style/typography';
import CalendarIcon from '../../icons/grey-calendar.svg';
import {formatDate} from '../../../utils/format';
import {toolStageHistory} from '../../../../data/tech-radar/queries';
import {ApolloContext} from '../../../enhancers/graphql-enhancer';
import {flattenEdges} from '../../../utils/graphql';
import * as Sentry from '@sentry/browser';
import {generateNumbers} from '../../../utils/number';
import ContextIcon from '../../../../shared/library/icons/context.svg';
import VersionStageIcon from '../../../../shared/library/icons/version_icon.svg';

const ChevronIcon = glamorous(DownChevronIcon)({
  width: 10,
  height: 10,
  fill: TARMAC
});

const ChevronRightIcon = glamorous(DownChevronIcon)({
  width: 10,
  height: 10,
  transform: 'rotate(270deg)',
  fill: TARMAC
});

const CalendarIconStyled = glamorous(CalendarIcon)({
  width: 13,
  height: 13,
  marginRight: 12,
  marginLeft: 3
});

const ContextIconStyled = glamorous(ContextIcon)({
  width: 13,
  height: 13,
  marginRight: 12,
  marginLeft: 3
});

const VersionStageIconStyled = glamorous(VersionStageIcon)({
  width: 13,
  height: 13,
  marginRight: 12,
  marginLeft: 3
});

const MainContainer = glamorous.div(
  {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 16
  },
  ({customStyle}) => ({
    ...customStyle
  })
);

const Container = glamorous.div({
  display: 'flex',
  flexDirection: 'column',
  maxHeight: 300,
  overflowY: 'scroll',
  overflowX: 'hidden',
  transition: '3s ease',
  '::-webkit-scrollbar': {
    width: 5
  },

  '::-webkit-scrollbar-thumb': {
    borderRadius: 5,
    backgroundColor: 'rgba(0,0,0,.5)',
    boxShadow: ' 0 0 1px rgba(255,255,255,.5)'
  },
  '>:last-child': {
    border: 0
  }
});

const ShowDetails = glamorous.div(
  {
    cursor: 'pointer',
    height: 27,
    display: 'flex',
    alignItems: 'center',
    marginBottom: 18,
    ' > span': {
      height: 18,
      width: 18,
      background: '#ECECEC',
      display: 'flex',
      borderRadius: 50,
      alignItems: 'center',
      justifyContent: 'center',
      padding: 5,
      marginRight: 10
    }
  },
  ({headingStyle}) => ({
    ...headingStyle
  })
);

const Title = glamorous.p({
  margin: 0,
  padding: 0,
  ...BASE_TEXT,
  fontSize: 13,
  color: CHARCOAL,
  lineHeight: '40px'
});

const Item = glamorous.div({
  display: 'flex',
  flexDirection: 'column',
  borderBottom: `1px solid ${GREY}`
});

const ItemTitle = glamorous.p(
  {
    marginBottom: 6,
    padding: 0,
    ...BASE_TEXT,
    fontSize: 12,
    fontWeight: WEIGHT.BOLD,
    lineHeight: '14px',
    color: CHARCOAL,
    display: 'flex',
    alignItems: 'center'
  },
  ({customStyle}) => ({
    ...customStyle
  })
);

const ItemDescription = glamorous.p(
  {
    marginBottom: 6,
    padding: 0,
    ...BASE_TEXT,
    fontSize: 12,
    color: CHARCOAL,
    lineHeight: '14px',
    display: 'flex',
    alignItems: 'center'
  },
  ({customStyle}) => ({
    ...customStyle
  })
);

const ItemMetaData = glamorous.p({
  ...BASE_TEXT,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  color: TARMAC,
  marginBottom: 20,
  fontSize: 12
});

const DateText = glamorous.span({
  margin: 0,
  padding: 0,
  ...BASE_TEXT,
  fontSize: 11,
  color: TARMAC
});

const Author = glamorous.a({
  margin: 0,
  padding: 0,
  ...BASE_TEXT,
  fontSize: 11,
  color: TARMAC,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
    cursor: 'pointer',
    color: `${FOCUS_BLUE} !important`
  }
});

const ErrorText = glamorous.p({
  margin: '6px 0',
  padding: 0,
  ...BASE_TEXT,
  fontSize: 12,
  color: ERROR_RED,
  lineHeight: '18px'
});

const NoData = glamorous.p({
  ...BASE_TEXT,
  fontSize: 14,
  color: BIT_GREY,
  lineHeight: '18px',
  margin: '3px 0',
  padding: 0,
  textAlign: 'center',
  fontStyle: 'italic'
});

const History = ({
  count = 0,
  data = null,
  fixData = false,
  isOpen = true,
  customStyle = {},
  headingStyle = {}
}) => {
  const client = useContext(ApolloContext);
  const [open, setOpen] = useState(!!isOpen);
  const [historyData, setHistoryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [endCursor, setEndCursor] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(false);

  const fetchHistory = async (isPaginated = false) => {
    try {
      const queryData = await client.query({
        query: toolStageHistory,
        fetchPolicy: 'network-only',
        variables: {
          id: data.id,
          first: 5,
          after: isPaginated ? endCursor : null
        }
      });

      const hasData = queryData?.data?.tool;
      const dataKey = 'adoptionStageHistories';
      const history = hasData[dataKey] ? flattenEdges(hasData[dataKey]) : [];

      if (history.length > 0) {
        if (isPaginated) {
          setHistoryData([...historyData, ...history]);
        } else {
          setHistoryData([...history]);
        }
        setLoading(false);
        setEndCursor(hasData[dataKey].pageInfo?.endCursor);
        setHasNextPage(hasData[dataKey].pageInfo?.hasNextPage);
      }
    } catch (error) {
      setLoading(false);
      setErrorMsg('Error fetching stage history data, please check after some time');
      Sentry.captureException({
        error,
        tool: data?.tool?.name,
        page: 'Tech Radar > Stage History'
      });
    }
  };

  useEffect(() => {
    //Make API call to get stage history data when open is true
    if (count === 0) {
      setHistoryData([]);
      setLoading(false);
      setErrorMsg(null);
      setHasNextPage(false);
      setEndCursor(null);
      return;
    }

    if (open && !fixData && data) {
      setLoading(true);
      fetchHistory();
    }

    //For static data
    if (fixData && open && data) {
      setLoading(true);

      //Modify data to match the API response
      const dataKey = 'adoptionStageHistories';
      const history = flattenEdges(data[dataKey]);
      setTimeout(() => {
        setHistoryData(history);
        setLoading(false);
      }, 1000);
    }
  }, [open, count, data?.id]);

  const onScrollMore = ({currentTarget}) => {
    if (currentTarget.scrollTop + currentTarget.clientHeight >= currentTarget.scrollHeight - 10) {
      if (hasNextPage && endCursor) fetchHistory(true);
    }
  };

  if (!open) {
    //Show Stage History title
    return (
      <MainContainer customStyle={customStyle}>
        <ShowDetails
          onClick={() => {
            setOpen(prevState => !prevState);
          }}
          headingStyle={headingStyle}
        >
          <span>
            <ChevronRightIcon />
          </span>
          <Title>Stage History &nbsp;({count})</Title>
        </ShowDetails>
      </MainContainer>
    );
  }

  return (
    <MainContainer customStyle={customStyle}>
      <ShowDetails
        onClick={() => {
          setOpen(prevState => !prevState);
        }}
        headingStyle={headingStyle}
      >
        <span>
          <ChevronIcon />
        </span>
        <Title>Stage History &nbsp;({count})</Title>
      </ShowDetails>
      <Container onScroll={e => onScrollMore(e)}>
        {loading &&
          open &&
          generateNumbers(1, 5)
            .splice(0, count)
            .map(i => (
              <Loader w={'100%'} h={90} key={i} animate customStyle={{marginBottom: 10}} />
            ))}

        {!loading &&
          open &&
          historyData.length > 0 &&
          historyData.map((item, index) => <HistoryItem key={index} item={item} index={index} />)}

        {historyData.length === 0 && open && !loading && (
          <NoData>-- No History available --</NoData>
        )}

        {errorMsg && <ErrorText>{errorMsg}</ErrorText>}
      </Container>
    </MainContainer>
  );
};

History.propTypes = {
  count: PropTypes.number,
  data: PropTypes.object,
  fixData: PropTypes.bool,
  isOpen: PropTypes.bool,
  customStyle: PropTypes.object,
  headingStyle: PropTypes.object
};

const HistoryItem = ({item, index}) => {
  return (
    <Item index={index}>
      <ItemTitle
        customStyle={{
          marginTop: index === 0 ? 0 : 20,
          fontStyle: item.extraDetails?.old_adoption_stage ? 'normal' : 'italic',
          color: item.extraDetails?.old_adoption_stage ? CHARCOAL : BIT_GREY
        }}
      >
        {item.extraDetails?.old_adoption_stage ? (
          <>
            <VersionStageIconStyled />
            {item.extraDetails.old_adoption_stage}
          </>
        ) : (
          <>
            <VersionStageIconStyled />
            Unassigned
          </>
        )}
      </ItemTitle>
      <ItemDescription
        customStyle={{
          fontStyle: item.extraDetails?.old_context ? 'normal' : 'italic',
          color: item.extraDetails?.old_context ? CHARCOAL : BIT_GREY
        }}
      >
        {item.extraDetails?.old_context ? (
          <>
            <ContextIconStyled />
            {item.extraDetails.old_context}
          </>
        ) : (
          <>
            <ContextIconStyled />
            No Context added
          </>
        )}
      </ItemDescription>
      <ItemMetaData>
        <CalendarIconStyled />
        <DateText>{formatDate('MMM dd', Date.parse(item.createdAt))}</DateText>
        {item.whoDidIt && (
          <>
            &nbsp;by&nbsp;
            <Author href={item.whoDidIt.path}>{item.whoDidIt.username}</Author>
          </>
        )}
      </ItemMetaData>
    </Item>
  );
};

HistoryItem.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number
};

export default React.memo(History);
