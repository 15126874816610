const handleFileDownload = async (fileURL, name = 'tech') => {
  try {
    const response = await fetch(`${fileURL}`);
    if (!response.ok) {
      throw new Error('Error fetching file content');
    }
    const fileContent = await response.text();
    const fileExtension = fileURL
      .split('?')[0]
      .split('.')
      .pop()
      .toLowerCase();

    const fileName = `${name}.${fileExtension}`;

    const blob = new Blob([fileContent], {type: 'text/plain'});
    const url = URL.createObjectURL(blob);

    const link = typeof document !== 'undefined' ? document.createElement('a') : null;
    link.href = url;
    link.download = fileName;
    link.click();

    URL.revokeObjectURL(url);

    return {
      fileExtension,
      fileName,
      status: 'success'
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return {
      status: 'error',
      fileExtension: '',
      fileName: ''
    };
  }
};

export default handleFileDownload;
