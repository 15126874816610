export const STACKS_SORT = 'stacks_count';
export const MEMBERS_SORT = 'members_count';
export const TEAMS_SORT = 'teams_count';
export const POPULARITY_SORT = 'popularity';
export const VERSIONS_SORT = 'versions_count';
export const VULNERABILITY_SORT = 'vulnerabilities';

export const SORT = {
  STACKS_SORT: 'stacks_count',
  MEMBERS_SORT: 'members_count',
  TEAMS_SORT: 'teams_count',
  POPULARITY_SORT: 'popularity',
  VERSIONS_SORT: 'versions_count',
  VULNERABILITY_SORT: 'vulnerabilities'
};

// export const SORT_DIRECTION = {
//   descending: 'descending',
//   ascending: 'ascending',
//   DESCENDING: 'DESC',
//   ASCENDING: 'AESC'
// };
