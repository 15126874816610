export const FEED_DWELL_CARD = 'feed_dwell_card';
export const FEED_PROMPT_SEEN = 'feed_prompt_seen';
export const FEED_CLICK_DISMISS_PROMPT = 'feed_click_dismiss_prompt';
export const FEED_CLICK_CARD = 'feed_click_card';
export const FEED_CLICK_CARD_REPLY = 'feed_click_card_reply';
export const FEED_CLICK_CARD_COMMENTS = 'feed_click_card_comments';
export const FEED_CLICK_WRITE_COMMENT = 'feed_click_write_comment';
export const FEED_CLICK_SUBMIT_COMMENT = 'feed_click_submit_comment';
export const FEED_CLICK_CANCEL_COMMENT = 'feed_click_cancel_comment';
export const FEED_CLICK_DELETE_COMMENT = 'feed_click_delete_comment';
export const FEED_CLICK_EDIT_COMMENT = 'feed_click_edit_comment';
export const FEED_CLICK_DELETE_DECISION = 'feed_click_delete_decision';
export const FEED_CLICK_EDIT_DECISION = 'feed_click_edit_decision';
export const FEED_CLICK_CARD_USER = 'feed_click_card_user';
export const FEED_CLICK_CARD_UPVOTE = 'feed_click_card_upvote';
export const FEED_CLICK_CARD_FLAG = 'feed_click_card_flag';
export const FEED_CLICK_CARD_BOOKMARK = 'feed_click_card_bookmark';
export const FEED_CLICK_CARD_COMPANY = 'feed_click_card_company';
export const FEED_CLICK_CARD_STACK = 'feed_click_card_stack';
export const FEED_CLICK_CARD_ADD = 'Add';
export const FEED_CLICK_CARD_REMOVE = 'Remove';
export const FEED_CLICK_CARD_TYPE_DECISION = 'StackDecision';
export const FEED_CLICK_CARD_TYPE_COMMENT = 'Comment';
export const FEED_CLICK_CARD_TYPE_ARTICLE = 'Article';
export const FEED_CLICK_CARD_SOURCE_TITLE = 'Title';
export const FEED_CLICK_CARD_SOURCE_ICON = 'Icon';
export const FEED_CLICK_CARD_SOURCE_TYPE = 'Type';
export const FEED_CLICK_CARD_SOURCE_AUTHOR = 'Author';
export const FEED_CLICK_CARD_SOURCE_DOMAIN = 'Domain';
export const FEED_CLICK_CARD_SOURCE_CARD = 'Card';
export const FEED_CLICK_CARD_SOURCE_SEE_MORE = 'See More';
export const FEED_CLICK_CARD_SOURCE_SEE_LESS = 'See Less';
export const FEED_HOVER_TOOL_POPOVER = 'feed_hover_tool_popover';
export const FEED_CLICK_TOOL_POPOVER = 'feed_click_tool_popover';
export const FEED_CLICK_TOOL_FOLLOW = 'feed_click_tool_follow';
export const FEED_CLICK_TOOL_FOLLOW_LOCATION_TOOL_POPOVER = 'Tool Popover';
export const FEED_CLICK_TOOL_FOLLOW_LOCATION_TRENDING = 'Trending';
export const FEED_CLICK_TOOL_FOLLOW_LOCATION_FOLLOW_PANEL = 'Follow Panel';
export const FEED_CLICK_ARTICLE_POINTS = 'feed_click_article_points';
export const FEED_CLICK_SIGNUP = 'feed_click_signup';
export const FEED_CLICK_SUBSCRIBE = 'feed_click_subscribe';
export const FEED_CLICK_SIGNUP_SOURCE_GITHUB = 'Github';
export const FEED_CLICK_SIGNUP_SOURCE_BITBUCKET = 'Bitbucket';
export const FEED_CLICK_SIGNUP_SOURCE_GITLAB = 'Gitlab';
export const FEED_CLICK_SIGNUP_SOURCE_TWITTER = 'Twitter';
export const FEED_CLICK_SIGNUP_SOURCE_GOOGLE = 'Google';
export const FEED_CLICK_FEEDS = 'feed_click_feeds';
export const FEED_CLICK_FEEDS_MY_FEED = 'My Feed';
export const FEED_CLICK_FEEDS_ALL = 'All';
export const FEED_CLICK_FEEDS_ADVICE = 'Advice';
export const FEED_CLICK_FEEDS_MY_DECISIONS = 'My Decisions';
export const FEED_CLICK_CARD_SHARE = 'feed_click_card_share';
export const FEED_CLICK_CARD_SHARE_FACEBOOK = 'Facebook';
export const FEED_CLICK_CARD_SHARE_MAIL = 'Mail';
export const FEED_CLICK_CARD_SHARE_TWITTER = 'Facebook';
export const FEED_CLICK_CARD_SHARE_LINKEDIN = 'Linkedin';
export const FEED_CLICK_LOAD_MORE = 'feed_click_load_more';
export const FEED_CLICK_MARKDOWN_HELP = 'feed_click_markdown_help';
export const FEED_CLICK_MANAGE_TOOLS = 'feed_click_manage_tools';
export const FEED_CARD_SOURCE_STACKUP = 'stackshare-stackup';
export const FEED_CARD_SOURCE_STORY = 'stackshare-story';
export const FEED_CARD_SOURCE_DECISION = 'stackshare-decision';
export const FEED_CLICK_COMPOSER_HIDE_NOTICE = 'feed_click_composer_hide_notice';
export const FEED_FOCUS_COMPOSER = 'feed_focus_composer';
export const FEED_RESTORE_COMPOSER = 'feed_restore_composer';
export const FEED_FIRST_CHANGE_COMPOSER = 'feed_first_change_composer';
export const FEED_SEARCH_COMPOSER_TOOL = 'feed_search_composer_tool';
export const FEED_SEARCH_COMPOSER_TOPIC = 'feed_search_composer_topic';
export const FEED_SEARCH_COMPOSER_COMPANY = 'feed_search_composer_company';
export const FEED_CHOOSE_COMPOSER_TOOL = 'feed_choose_composer_tool';
export const FEED_CHOOSE_COMPOSER_TOPIC = 'feed_choose_composer_topic';
export const FEED_CHOOSE_COMPOSER_COMPANY = 'feed_choose_composer_company';
export const FEED_CLEAR_COMPOSER_COMPANY = 'feed_clear_composer_company';
export const FEED_CLICK_COMPOSER_SHARE_DECISION = 'feed_click_composer_share_decision';
export const FEED_CLICK_COMPOSER_POPULAR_DECISIONS = 'feed_click_composer_popular_decisions';
export const FEED_CLICK_COMPOSER_ADD_LINK = 'feed_click_composer_add_link';
export const FEED_FIRST_CHANGE_COMPOSER_ADD_LINK = 'feed_first_change_composer_add_link';
export const FEED_SHOW_COMPOSER_RULE = 'feed_show_composer_rule';
export const FEED_TRACK_VIEWS = 'feed_track_views';
export const DECISION_SOURCE_FEED = 'feed';
export const DECISION_SOURCE_PERMALINK = 'permalink';
export const ONBOARDING_CHECKLIST_SHOW = 'onboarding_checklist_show';
export const ONBOARDING_CHECKLIST_CLICK = 'onboarding_checklist_click';
export const ONBOARDING_CHECKLIST_HINT = 'onboarding_checklist_hint';
