import React, {useState} from 'react';
import {updateService} from '../../../../../data/feed/mutations';
import PropTypes from 'prop-types';
import {Mutation} from 'react-apollo';
import FollowButton from '..';
import {withCurrentUser} from '../../../../enhancers/current-user-enhancer';
import {NavigationContext} from '../../../../enhancers/router-enhancer';
import {SIGN_IN_PATH} from '../../../../constants/paths';
import {onboardingChecklist} from '../../../../../data/feed/queries';
import {toolBySlugMinimal} from '../../../../../data/tool-profile/queries';

export const FollowServiceButton = ({serviceId, following, currentUser, onToggle}) => {
  const [followingTool, setFollowingTool] = useState(following);

  return (
    <Mutation
      mutation={updateService}
      variables={{serviceId, following: !following}}
      optimisticResponse={{
        __typename: 'Mutation',
        updateService: {
          id: serviceId,
          __typename: 'Service',
          following: !following
        }
      }}
      refetchQueries={[
        {query: onboardingChecklist},
        {query: toolBySlugMinimal, variables: {id: serviceId}, fetchPolicy: 'network-only'}
      ]}
    >
      {updateService => (
        <NavigationContext.Consumer>
          {navigate => (
            <FollowButton
              following={followingTool}
              onToggle={() => {
                if (currentUser && !currentUser.loading && !currentUser.impersonated) {
                  updateService(serviceId, !following);
                  setFollowingTool(!following);
                  onToggle && onToggle();
                } else if (!currentUser) {
                  navigate(SIGN_IN_PATH);
                }
              }}
            />
          )}
        </NavigationContext.Consumer>
      )}
    </Mutation>
  );
};

FollowServiceButton.propTypes = {
  currentUser: PropTypes.object,
  serviceId: PropTypes.any,
  following: PropTypes.bool,
  onToggle: PropTypes.func
};

export default withCurrentUser(FollowServiceButton);
