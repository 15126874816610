import React, {useState} from 'react';
import glamorous from 'glamorous';
import {SigninDesktopModal} from '../../../../shared/library/modals/signin';
import {LAPTOP, PHONE} from '../../../../shared/style/breakpoints';
import GhostButton from '../../../../shared/library/buttons/base/ghost';
import {FONTS, WEIGHT} from '../../../../shared/style/typography';
import {FOCUS_BLUE, WHITE} from '../../../../shared/style/colors';

const Container = glamorous.div({
  [LAPTOP]: {
    width: '100%',
    margin: 'auto'
  },
  [PHONE]: {
    padding: '0 2px 15px 3px'
  }
});

const Login = glamorous(GhostButton)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 38,
  padding: '10px 15px',
  width: '100%',
  whiteSpace: 'nowrap',
  fontSize: 13,
  fontFamily: FONTS.CEREBRI_SANS_PRO,
  zIndex: 99,
  fontWeight: WEIGHT.ULTRA_BOLD,
  ':focus': {
    background: FOCUS_BLUE,
    color: WHITE
  },
  ':hover': {
    background: FOCUS_BLUE,
    color: WHITE
  },
  [PHONE]: {
    marginTop: 20
  }
});

const NavbarLogin = () => {
  const [isSignInModalOpen, setSignInModalOpen] = useState(false);
  return (
    <Container>
      <Login
        color={FOCUS_BLUE}
        data-testid="loginClick"
        onClick={() => {
          setSignInModalOpen(true);
        }}
      >
        Sign up/Login
      </Login>
      {isSignInModalOpen && <SigninDesktopModal onDismiss={() => setSignInModalOpen(false)} />}
    </Container>
  );
};

export default NavbarLogin;
