import {QUERY, SORT as SORT_SEPARATOR} from '../constants/query-options';
import {SORT} from '../constants/sort-options';
import {ORG_TYPES} from '../../../shared/enhancers/organization-enhancer';
import {TOOLS, PACKAGES, STACKS, VULNERABILITY, TECH_RADAR} from '../constants/paths';
import {getFilterArrayFromObject, FILTERS} from '../../../shared/library/filters/utils';

export const SERIALIZATION_TYPES = {
  MANUAL: 'manual',
  AUTO: 'auto'
};

export const SUPPORT = {
  FILTERS: [TOOLS, PACKAGES, STACKS, VULNERABILITY, TECH_RADAR],
  SORT: [TOOLS, PACKAGES],
  LAYER: [TECH_RADAR]
};

export const setLocally = (filters, sort) => {
  if (filters) localStorage.setItem('filters', JSON.stringify(filters));
  if (sort) localStorage.setItem('sort', JSON.stringify(sort));
};

export const updateQuery = (previousResult, {fetchMoreResult}) => {
  if (!fetchMoreResult) {
    return previousResult;
  }

  const itemName = Object.keys(previousResult.currentPrivateCompany)[3];

  return {
    ...previousResult,
    currentPrivateCompany: {
      ...previousResult.currentPrivateCompany,
      [itemName]: {
        ...previousResult.currentPrivateCompany[itemName],
        ...fetchMoreResult.currentPrivateCompany[itemName],
        edges: [
          ...previousResult.currentPrivateCompany[itemName].edges,
          ...fetchMoreResult.currentPrivateCompany[itemName].edges
        ]
      }
    }
  };
};

export const updateDashboardQuery = (previousResult, {fetchMoreResult}) => {
  if (!fetchMoreResult) {
    return previousResult;
  }

  const filter = Object.keys(previousResult.currentPrivateCompany.dashboardFilters)[0];
  return {
    ...previousResult,
    currentPrivateCompany: {
      ...previousResult.currentPrivateCompany,
      dashboardFilters: {
        ...previousResult.currentPrivateCompany.dashboardFilters,
        [filter]: {
          ...previousResult.currentPrivateCompany.dashboardFilters[filter],
          ...fetchMoreResult.currentPrivateCompany.dashboardFilters[filter],
          edges: [
            ...previousResult.currentPrivateCompany.dashboardFilters[filter].edges,
            ...fetchMoreResult.currentPrivateCompany.dashboardFilters[filter].edges
          ]
        }
      }
    }
  };
};

export const getQueryData = (startsWith, item) => {
  if (startsWith)
    return item
      .split(startsWith)[1]
      .split('%2C')
      .map(temp => ({
        slug: temp,
        checked: true
      }));
  else return [];
};

export const getKeyByValue = (obj, value) => {
  return Object.keys(obj).find(key => obj[key] === value);
};

export const serializeUrl = (type = SERIALIZATION_TYPES.AUTO, payload = {}) => {
  if (typeof window !== 'undefined' && window.location) {
    const url = new URL(window.location);
    const urlParams = url.searchParams;
    const getFilterArray = localStorage.getItem('filters')
      ? getFilterArrayFromObject(JSON.parse(localStorage.getItem('filters')))
      : [];
    const parseOrg = localStorage.getItem('currentOrg')
      ? JSON.parse(localStorage.getItem('currentOrg'))
      : '';
    const sort = localStorage.getItem('sort') ? JSON.parse(localStorage.getItem('sort')) : '';

    const layer = localStorage.getItem('layer') ? JSON.parse(localStorage.getItem('layer')) : '';

    const currentPath = location.pathname.split('/').pop();

    const filterSupported = SUPPORT.FILTERS.findIndex(item => item === currentPath);
    const sortSupported = SUPPORT.SORT.findIndex(item => item === currentPath);
    const layerSupported = SUPPORT.LAYER.findIndex(item => item === currentPath);

    let final = {};

    if (urlParams.has('org')) final['org'] = [urlParams.get('org')];
    else if (parseOrg && parseOrg.slug && parseOrg.slug !== ORG_TYPES.ALL.slug) {
      final['org'] = [parseOrg.slug];
    }

    if (type === SERIALIZATION_TYPES.MANUAL && payload?.org?.slug) {
      if (payload?.org?.slug !== ORG_TYPES.ALL.slug) final['org'] = [payload.org.slug];
      else delete final['org'];
    }

    if (urlParams.has('tool')) final['tool'] = [urlParams.get('tool')];
    if (urlParams.has('cve')) final['cve'] = [urlParams.get('cve')];
    if (layer && layerSupported > -1) final['layer'] = [layer];

    if (filterSupported > -1)
      getFilterArray.map(item => {
        const getFilterType = getKeyByValue(QUERY, getKeyByValue(FILTERS, item.filterType));
        const modifyType = getFilterType && getFilterType.replace(/=/g, '');
        if (final[modifyType]) final[modifyType].push(item.slug);
        else final[modifyType] = [item.slug];
      });

    if (sort && sortSupported > -1)
      final[SORT_SEPARATOR] = [getKeyByValue(QUERY, getKeyByValue(SORT, sort))];

    url.search = '';

    Object.keys(final).map(key => {
      urlParams.set(key, final[key].join(','));
    });

    history.replaceState({path: url.href}, '', url.href);
  }
};

export function transformData(data, value = null) {
  if (data) {
    return data.map((item, index) => {
      let separatedWords = item.split('_');
      let firstWord = separatedWords[0].charAt(0).toUpperCase() + separatedWords[0].slice(1);
      let formattedName = [firstWord, ...separatedWords.slice(1)].join(' ');

      if (item.includes('eol')) {
        formattedName = formattedName.replace(/eol/i, 'EOL');
      }

      return {
        id: index + '_' + item,
        name: formattedName,
        slug: item
      };
    });
  }
  return value;
}
