import gql from 'graphql-tag';

export const dashboardMembers = gql`
  query dashboardMembers($orgId: ID) {
    currentPrivateCompany {
      id
      slug
      members(orgId: $orgId, first: 4) {
        count
        edges {
          node {
            id
            displayName
            imageUrl
          }
        }
      }
    }
  }
`;

export const dashboardTeams = gql`
  query dashboardTeams($orgId: ID) {
    currentPrivateCompany {
      id
      slug
      teams(orgId: $orgId, first: 6) {
        count
        edges {
          node {
            id
            name
            imageUrl
          }
        }
      }
    }
  }
`;

export const dashboardStacks = gql`
  query dashboardStacks($orgId: ID, $after: String, $first: Int) {
    currentPrivateCompany {
      id
      repoStacksCount
      slug
      privateMode
      azureAppInstalled
      githubAppInstalled
      githubAppSettingsUrl
      stacksCount(orgId: $orgId)
      stacksList(orgId: $orgId, after: $after, first: $first) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            id
            slug
            name
            path
            private
            repoStack
            services(withoutPackages: true) {
              id
              name
              slug
              title
              imageUrl
              layer {
                slug
              }
            }
          }
        }
      }
    }
  }
`;

export const dashboardTools = gql`
  query dashboardTools($orgId: ID) {
    currentPrivateCompany {
      id
      toolsCount(orgId: $orgId)
      filterableToolsFromStacks(orgId: $orgId, first: 20) {
        edges {
          node {
            id
            companyMajorVersions {
              count
            }
            name
            imageUrl
          }
        }
      }
    }
  }
`;

export const dashboardPackageManagers = gql`
  query dashboardPackageManagers($orgId: ID) {
    currentPrivateCompany {
      id
      packagesCountFromStacks(orgId: $orgId)
      packageManagersFromStacks(orgId: $orgId, first: 50) {
        edges {
          node {
            id
            name
            imageUrl
            packagesCount(orgId: $orgId)
          }
        }
      }
    }
  }
`;

export const dashboardRecentActivities = gql`
  query dashboardRecentActivities($orgId: ID, $first: Int, $after: String) {
    currentPrivateCompany {
      id
      slug
      recentActivities(orgId: $orgId, first: $first, after: $after) {
        count
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            action
            createdAt
            whoDidIt {
              id
              username
              displayName
              imageUrl
            }
            extraDetails
            activityObject {
              ... on Comment {
                id
                content
                commentableId
              }
              ... on Stack {
                id
                name
                canonicalUrl
              }
              ... on User {
                id
                username
                imageUrl
              }
              ... on StackChange {
                tool {
                  id
                  name
                  slug
                  imageUrl
                  adoptionStage {
                    id
                    slug
                    name
                  }
                  license {
                    name
                    url
                  }
                }
                stack {
                  id
                  name
                  canonicalUrl
                }
                newVersionNumber
                oldVersionNumber
                previousStage
                currentStage
                previousVersionStage
                currentVersionStage
              }
              ... on StackDecision {
                id
                stack {
                  name
                }
                subjectTools {
                  id
                  name
                  slug
                  imageUrl
                }
                toTools {
                  id
                  imageUrl
                  name
                  slug
                }
                fromTools {
                  id
                  imageUrl
                  name
                  slug
                }
              }
              ... on Vulnerability {
                cveId
                name
                severity
                url
                detectedAt
                tool {
                  id
                  name
                  imageUrl
                  license {
                    name
                    url
                  }
                }
                vulnerabilityStacks {
                  count
                  edges {
                    node {
                      stack {
                        slug
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const reportMembers = gql`
  query reportMembers($after: String, $first: Int, $orgId: ID, $queryVar: String) {
    currentPrivateCompany {
      id
      name
      slug
      members(first: $first, after: $after, orgId: $orgId, query: $queryVar) {
        pageInfo {
          hasNextPage
          endCursor
        }
        count
        edges {
          node {
            id
            displayName
            title
            imageUrl
            path
            teams {
              count
            }
            contributedStacksCount
            privateToolsCount(withoutPackages: true)
          }
        }
      }
    }
  }
`;

export const reportTeams = gql`
  query reportTeams($after: String, $first: Int, $orgId: ID, $queryVar: String) {
    currentPrivateCompany {
      id
      name
      slug
      teams(first: $first, after: $after, orgId: $orgId, query: $queryVar) {
        pageInfo {
          hasNextPage
          endCursor
        }
        count
        edges {
          node {
            imageUrl
            canonicalUrl
            id
            name
            description
            stacksCount
            toolsCount(withoutPackages: true)
            membersCount
          }
        }
      }
    }
  }
`;

export const reportTools = gql`
  query reportTools(
    $after: String
    $first: Int
    $orderBy: OrderInput
    $filters: Hash
    $queryVar: String
    $orgId: ID
    $companySlug: String!
  ) {
    currentPrivateCompany {
      id
      name
      slug
      filterableToolsFromStacks(
        first: $first
        after: $after
        orderBy: $orderBy
        filters: $filters
        query: $queryVar
        orgId: $orgId
      ) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            id
            title
            name
            slug
            imageUrl
            teams(orgId: $orgId) {
              count
            }
            membersCount(orgId: $orgId)
            privateCompanyStacksCount(orgId: $orgId)
            toolType
            adoptionStageContext
            adoptionStage(companySlug: $companySlug) {
              id
              name
              slug
            }
            companyMajorVersions(orgId: $orgId) {
              count
            }
            versionRules {
              count
            }
          }
        }
      }
      toolsCount(orgId: $orgId)
    }
  }
`;

export const reportToolsCount = gql`
  query reportToolsCount(
    $after: String
    $first: Int
    $orderBy: OrderInput
    $filters: Hash
    $queryVar: String
    $orgId: ID
  ) {
    currentPrivateCompany {
      id
      name
      slug
      filterableToolsFromStacks(
        first: $first
        after: $after
        orderBy: $orderBy
        filters: $filters
        query: $queryVar
        orgId: $orgId
      ) {
        count
      }
    }
  }
`;

export const toolStats = gql`
  query toolStats($orgId: ID!, $fieldAccess: Boolean!) {
    currentPrivateCompany {
      id
      name
      slug
      stacksList(orgId: $orgId) {
        count
      }
      stacksCountOfCurrentAndDescendantOrgs(orgId: $orgId) @include(if: $fieldAccess)
      members(orgId: $orgId) {
        count
      }
      teams(orgId: $orgId) {
        count
      }
    }
  }
`;

export const toolMetaData = gql`
  query toolMetaData($id: ID!, $orgId: ID) {
    tool(id: $id) {
      id
      name
      slug
      type
      path
      imageUrl
      latestVersionNumber
      companyMajorVersions(orgId: $orgId) {
        edges {
          node {
            stackItems {
              edges {
                node {
                  versionNumber
                }
              }
            }
          }
        }
      }
      license {
        name
        url
      }
      pressUrl
    }
  }
`;

export const reportPackages = gql`
  query reportPackages(
    $after: String
    $first: Int
    $orderBy: OrderInput
    $filters: Hash
    $queryVar: String
    $orgId: ID
    $companySlug: String!
  ) {
    currentPrivateCompany {
      id
      name
      slug
      filterablePackagesFromStacks(
        first: $first
        after: $after
        orderBy: $orderBy
        filters: $filters
        query: $queryVar
        orgId: $orgId
      ) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            companyMajorVersions(orgId: $orgId) {
              count
            }
            id
            title
            name
            slug
            imageUrl
            teams(orgId: $orgId) {
              count
            }
            membersCount(orgId: $orgId)
            privateCompanyStacksCount(orgId: $orgId)
            toolType
            adoptionStageContext
            adoptionStage(companySlug: $companySlug) {
              id
              name
              slug
            }
            packageManager {
              name
              miniImageUrl
              slug
            }
            versionRules {
              count
            }
          }
        }
      }
      packagesCountFromStacks(orgId: $orgId)
    }
  }
`;

export const reportPackagesCount = gql`
  query reportPackagesCount(
    $after: String
    $first: Int
    $orderBy: OrderInput
    $filters: Hash
    $queryVar: String
    $orgId: ID
  ) {
    currentPrivateCompany {
      id
      name
      slug
      filterablePackagesFromStacks(
        first: $first
        after: $after
        orderBy: $orderBy
        filters: $filters
        query: $queryVar
        orgId: $orgId
      ) {
        count
      }
    }
  }
`;

export const reportStacks = gql`
  query reportStacks($after: String, $first: Int, $queryVar: String, $filters: Hash, $orgId: ID) {
    currentPrivateCompany {
      id
      name
      slug
      stacksList(first: $first, after: $after, query: $queryVar, filters: $filters, orgId: $orgId) {
        pageInfo {
          hasNextPage
          endCursor
        }
        count
        edges {
          node {
            id
            identifier
            slug
            name
            path
            privateToolsCount
            teams {
              count
            }
            org {
              id
              name
              slug
              imageUrl
              vcsProvider
              orgUrl
            }
            membersCount
            websiteUrl
            private
            repoStack
            services(withoutPackages: true) {
              id
              name
              slug
              title
              imageUrl
              layer {
                slug
              }
            }
            packagesCount
            vulnerabilitiesCount
          }
        }
      }
      azureAppInstalled
      githubAppInstalled
      privateMode
      githubAppSettingsUrl
      members {
        count
      }
      repoStacksCount
    }
  }
`;

export const filterableToolsFromStacks = gql`
  query filterableToolsFromStacks(
    $after: String
    $first: Int
    $queryVar: String
    $filters: Hash
    $orderBy: Hash
  ) {
    currentPrivateCompany {
      id
      slug
      filterableToolsFromStacks(
        after: $after
        first: $first
        query: $queryVar
        filters: $filters
        orderBy: $orderBy
      ) {
        pageInfo {
          hasNextPage
          endCursor
        }
        count
        edges {
          node {
            id
            companyMajorVersions {
              count
            }
            title
            name
            slug
            imageUrl
            path
            stacksCount
            teams {
              count
            }
            membersCount
            privateCompanyStacks {
              count
            }
          }
        }
      }
    }
  }
`;

export const filterablePackagesFromStacks = gql`
  query filterablePackagesFromStacks($id: ID!, $queryVar: String) {
    company(id: $id) {
      id
      slug
      filterablePackagesFromStacks(query: $queryVar) {
        edges {
          node {
            id
            companyMajorVersions {
              count
            }
            title
            name
            imageUrl
            path
            stacksCount
            teams {
              count
            }
            membersCount
            privateCompanyStacks {
              count
            }
            packageManager {
              name
              miniImageUrl
            }
          }
        }
      }
    }
  }
`;

export const toolTeams = gql`
  query toolTeams($id: ID!, $first: Int, $after: String, $orgId: ID) {
    tool(id: $id) {
      id
      slug
      teams(orgId: $orgId, after: $after, first: $first) {
        count
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            id
            name
            imageUrl
            canonicalUrl
          }
        }
      }
    }
  }
`;

export const toolMembers = gql`
  query toolMembers($id: ID!, $orgId: ID, $first: Int, $after: String) {
    tool(id: $id) {
      id
      usersViaContributedStacks(orgId: $orgId, after: $after, first: $first) {
        count
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            id
            displayName
            imageUrl
            path
          }
        }
      }
    }
  }
`;

export const toolStacks = gql`
  query toolStacks($id: ID!, $first: Int, $after: String, $orgId: ID) {
    tool(id: $id) {
      id
      privateStacks: privateCompanyStacks(orgId: $orgId, after: $after, first: $first) {
        count
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            id
            name
            path
          }
        }
      }
    }
  }
`;

export const toolVersions = gql`
  query toolVersions($id: ID!, $orgId: ID) {
    tool(id: $id) {
      id
      name
      slug
      imageUrl
      companyMajorVersions(orgId: $orgId) {
        count
        edges {
          node {
            number
            stackItems {
              count
            }
          }
        }
      }
    }
  }
`;

export const versionData = gql`
  query versionData($id: ID!, $versionNumber: String!, $orgId: ID, $first: Int, $after: String) {
    tool(id: $id) {
      id
      name
      slug
      imageUrl
      releasePolicyUrl
      companyMajorVersions(versionNumber: $versionNumber, orgId: $orgId) {
        count
        edges {
          node {
            number
            stackItems(first: $first, after: $after) {
              count
              edges {
                node {
                  stack {
                    id
                    name
                    path
                  }
                  sourceFilename
                  displayCheckRunUrl
                  versionNumber
                  versionReleasedAt
                  endOfLifeAt
                  eolStatus
                  highestVulnerability {
                    url
                    severity
                    name
                    detectedAt
                  }
                  versionStage {
                    id
                    name
                    slug: id
                  }
                  versionStageContext
                }
              }
              pageInfo {
                hasNextPage
                endCursor
              }
            }
          }
        }
      }
    }
  }
`;

export const packageTeams = gql`
  query packageTeams($id: ID!) {
    tool(id: $id) {
      id
      slug
      teams {
        count
        edges {
          node {
            id
            name
            imageUrl
            canonicalUrl
          }
        }
      }
    }
  }
`;

export const packageMembers = gql`
  query packageMembers($id: ID!) {
    tool(id: $id) {
      id
      usersViaContributedStacks {
        count
        edges {
          node {
            id
            displayName
            imageUrl
            path
          }
        }
      }
    }
  }
`;

export const packageStacks = gql`
  query packageStacks($id: ID!) {
    tool(id: $id) {
      id
      privateStacks: privateCompanyStacks {
        count
        edges {
          node {
            id
            name
            path
          }
        }
      }
    }
  }
`;

export const packageVersions = gql`
  query packageVersions($id: ID!) {
    tool(id: $id) {
      id
      name
      imageUrl
      companyMajorVersions {
        edges {
          node {
            number
            stackItems {
              count
              edges {
                node {
                  stack {
                    id
                    name
                    path
                  }
                  sourceFilename
                  displayCheckRunUrl
                  versionNumber
                  highestVulnerability {
                    url
                    severity
                    name
                    detectedAt
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const stackTeams = gql`
  query stackTeams($id: String!, $first: Int, $after: String) {
    stackProfile(identifier: $id) {
      id
      name
      slug
      teams(after: $after, first: $first) {
        count
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            id
            name
            imageUrl
            canonicalUrl
          }
        }
      }
    }
  }
`;

export const stackTools = gql`
  query stackTools($id: String!, $query: String) {
    stackProfile(identifier: $id) {
      id
      name
      slug
      tools(query: $query, withoutPackages: true) {
        id
        path
        type
        name
        imageUrl
      }
    }
  }
`;

export const stackPackages = gql`
  query stackPackages($id: String!, $first: Int, $after: String, $query: String) {
    stackProfile(identifier: $id) {
      id
      name
      slug
      packages(after: $after, first: $first, query: $query) {
        count
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            path
            name
            imageUrl
            type
            packageManager {
              slug
              name
              miniImageUrl
            }
          }
        }
      }
    }
  }
`;

export const stackApps = gql`
  query stackApps($id: String!) {
    stackProfile(identifier: $id) {
      id
      slug
      name
      stackApps {
        count
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            name
            id
            slug
            imageUrl
            canonicalUrl
          }
        }
      }
    }
  }
`;

export const stackContributors = gql`
  query stackContributors($id: String!, $first: Int, $after: String) {
    stackProfile(identifier: $id) {
      id
      name
      slug
      contributors(after: $after, first: $first) {
        count
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            path
            username
            displayName
            imageUrl
          }
        }
      }
    }
  }
`;

export const stackVulnerabilities = gql`
  query stackVulnerabilities($id: String!, $first: Int, $after: String) {
    stackProfile(identifier: $id) {
      id
      name
      slug
      vulnerabilities(after: $after, first: $first) {
        count
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            url
            name
            severity
            tool {
              name
              path
            }
          }
        }
      }
    }
  }
`;

export const stackAdoptionStages = gql`
  query stackAdoptionStages($id: String!) {
    stackProfile(identifier: $id) {
      id
      name
      slug
      path
      adoptionStages
    }
  }
`;

export const company = gql`
  query company {
    currentPrivateCompany {
      id
      slug
      githubAppInstalled
      azureAppInstalled
      azureInstallationToken
      githubOrgUrl
      packagesCountFromStacks
      dashboardInsights {
        mostUsedTools
        potentialTechDebt
      }
      recentActivities {
        count
      }
    }
  }
`;

export const dashboardStackApps = gql`
  query dashboardStackApps($orgId: ID!) {
    currentPrivateCompany {
      repoStacksCount
      id
      slug
      stackApps(orgId: $orgId) {
        count
        edges {
          node {
            id
            canonicalUrl
            description
            name
            stacks {
              count
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const functionFilters = gql`
  query functionFilters($queryVar: String, $after: String, $first: Int) {
    currentPrivateCompany {
      id
      slug
      dashboardFilters {
        functions(query: $queryVar, first: $first, after: $after) {
          pageInfo {
            hasNextPage
            endCursor
          }
          count
          edges {
            node {
              id
              name
              slug
            }
          }
        }
      }
    }
  }
`;

export const categoryFilters = gql`
  query categoryFilters($queryVar: String, $after: String, $first: Int) {
    currentPrivateCompany {
      id
      slug
      dashboardFilters {
        categories(query: $queryVar, first: $first, after: $after) {
          pageInfo {
            hasNextPage
            endCursor
          }
          count
          edges {
            node {
              id
              name
              slug
            }
          }
        }
      }
    }
  }
`;

export const layerFilters = gql`
  query layerFilters {
    currentPrivateCompany {
      id
      slug
      dashboardFilters {
        layers {
          pageInfo {
            hasNextPage
            endCursor
          }
          count
          edges {
            node {
              id
              name
              slug
            }
          }
        }
      }
    }
  }
`;

export const applicationFilters = gql`
  query applicationFilters($queryVar: String, $orgId: ID, $after: String, $first: Int) {
    currentPrivateCompany {
      id
      slug
      dashboardFilters {
        stackApps(query: $queryVar, orgId: $orgId, first: $first, after: $after) {
          pageInfo {
            hasNextPage
            endCursor
          }
          count
          edges {
            node {
              id
              name
              slug
            }
          }
        }
      }
    }
  }
`;

export const packageFilters = gql`
  query packageFilters($queryVar: String, $orgId: ID, $after: String, $first: Int) {
    currentPrivateCompany {
      id
      slug
      dashboardFilters {
        packageManagersFromStacks(query: $queryVar, orgId: $orgId, first: $first, after: $after) {
          pageInfo {
            hasNextPage
            endCursor
          }
          count
          edges {
            node {
              id
              name
              slug
            }
          }
        }
      }
    }
  }
`;

export const stageFilters = gql`
  query stageFilters($queryVar: String, $after: String, $first: Int) {
    currentPrivateCompany {
      id
      slug
      dashboardFilters {
        adoptionStages(query: $queryVar, first: $first, after: $after) {
          pageInfo {
            hasNextPage
            endCursor
          }
          count
          edges {
            node {
              id
              name
              slug
            }
          }
        }
      }
    }
  }
`;

export const stageExclusionFilters = gql`
  query stageExclusionFilters($queryVar: String, $after: String, $first: Int) {
    currentPrivateCompany {
      id
      slug
      dashboardFilters {
        adoptionStagesExclusion(query: $queryVar, first: $first, after: $after) {
          pageInfo {
            hasNextPage
            endCursor
          }
          count
          edges {
            node {
              id
              name
              slug
            }
          }
        }
      }
    }
  }
`;

export const tagFilters = gql`
  query tagFilters($queryVar: String, $after: String, $first: Int) {
    currentPrivateCompany {
      id
      slug
      dashboardFilters {
        privateTags(query: $queryVar, first: $first, after: $after) {
          pageInfo {
            hasNextPage
            endCursor
          }
          count
          edges {
            node {
              id
              name
              slug
            }
          }
        }
      }
    }
  }
`;

export const licenseFilters = gql`
  query licenseFilters($queryVar: String, $orgId: ID, $first: Int, $after: String) {
    currentPrivateCompany {
      id
      slug
      dashboardFilters {
        licenses(query: $queryVar, orgId: $orgId, first: $first, after: $after) {
          pageInfo {
            hasNextPage
            endCursor
          }
          count
          edges {
            node {
              id: name
              name
              slug: name
            }
          }
        }
      }
    }
  }
`;

export const appliedFilters = gql`
  query appliedFilters(
    $functions: [String!]
    $categories: [String!]
    $layers: [String!]
    $stackApps: [String!]
    $adoptionStages: [String!]
    $adoptionStagesExclusion: [String!]
    $privateTags: [String!]
    $packageManagersFromStacks: [String!]
    $teams: [String!]
    $stacks: [String!]
    $licenses: [String!]
    $functionsAccess: Boolean!
    $categoriesAccess: Boolean!
    $layersAccess: Boolean!
    $stackAppsAccess: Boolean!
    $adoptionStagesAccess: Boolean!
    $adoptionStagesExclusionAccess: Boolean!
    $privateTagsAccess: Boolean!
    $packageManagersFromStacksAccess: Boolean!
    $teamsAccess: Boolean!
    $stacksAccess: Boolean!
    $licensesAccess: Boolean!
  ) {
    currentPrivateCompany {
      id
      slug
      dashboardFilters {
        categories(slugs: $categories) @include(if: $categoriesAccess) {
          edges {
            node {
              id
              name
              slug
            }
          }
        }
        functions(slugs: $functions) @include(if: $functionsAccess) {
          edges {
            node {
              id
              name
              slug
            }
          }
        }
        layers(slugs: $layers) @include(if: $layersAccess) {
          edges {
            node {
              id
              name
              slug
            }
          }
        }
        stackApps(slugs: $stackApps) @include(if: $stackAppsAccess) {
          edges {
            node {
              id
              name
              slug
            }
          }
        }
        adoptionStages(slugs: $adoptionStages) @include(if: $adoptionStagesAccess) {
          edges {
            node {
              id
              name
              slug
            }
          }
        }
        privateTags(slugs: $privateTags) @include(if: $privateTagsAccess) {
          edges {
            node {
              id
              name
              slug
            }
          }
        }
        packageManagersFromStacks(slugs: $packageManagersFromStacks)
          @include(if: $packageManagersFromStacksAccess) {
          edges {
            node {
              id
              name
              slug
            }
          }
        }
        teams(slugs: $teams) @include(if: $teamsAccess) {
          edges {
            node {
              id
              name
              slug
            }
          }
        }
        stacks(slugs: $stacks) @include(if: $stacksAccess) {
          edges {
            node {
              id
              name
              slug
            }
          }
        }
        adoptionStagesExclusion(slugs: $adoptionStagesExclusion)
          @include(if: $adoptionStagesExclusionAccess) {
          edges {
            node {
              id
              name
              slug
            }
          }
        }

        licenses(names: $licenses) @include(if: $licensesAccess) {
          edges {
            node {
              id: name
              name
              slug: name
            }
          }
        }
      }
    }
  }
`;

export const dashboardInsights = gql`
  query dashboardInsights($orgId: ID) {
    currentPrivateCompany {
      id
      dashboardInsights(orgId: $orgId) {
        vulnerabilities
        mostUsedTools
        potentialTechDebt
      }
    }
  }
`;

export const accountVulnerabilities = gql`
  query vulnerabilities(
    $orgId: ID
    $first: Int
    $searchQuery: String
    $after: String
    $filters: Hash
  ) {
    currentPrivateCompany {
      id
      name
      slug
      vulnerabilityDashboard(orgId: $orgId) {
        vulnerabilities(
          first: $first
          after: $after
          searchQuery: $searchQuery
          filters: $filters
        ) {
          pageInfo {
            hasNextPage
            endCursor
          }
          count
          edges {
            node {
              id
              cveId
              ghsaId
              name
              description
              severity
              url
              detectedAt
              stacksCount
              tool {
                name
                slug
                imageUrl
                packageUrl
                latestVersionNumber
                path
                license {
                  name
                  url
                }
                pressUrl
              }
              vulnerabilityStacks(filters: $filters) {
                count
              }
            }
          }
        }
        severityCounts(searchQuery: $searchQuery, filters: $filters)
      }
    }
  }
`;

export const vulnerabilityFilters = gql`
  query vulnerabilityFilters {
    currentPrivateCompany {
      id
      slug
      dashboardFilters {
        vulnerabilitySeverities
      }
    }
  }
`;

export const visibilityFilters = gql`
  query visibilityFilters {
    currentPrivateCompany {
      id
      slug
      dashboardFilters {
        visibilities
      }
    }
  }
`;

export const endOfLifeFilters = gql`
  query endOfLifeFilters {
    currentPrivateCompany {
      id
      slug
      dashboardFilters {
        endOfLifeStatuses
      }
    }
  }
`;

export const teamsFilters = gql`
  query teamsFilters($queryVar: String, $orgId: ID, $after: String, $first: Int) {
    currentPrivateCompany {
      id
      slug
      dashboardFilters {
        teams(query: $queryVar, orgId: $orgId, first: $first, after: $after) {
          pageInfo {
            hasNextPage
            endCursor
          }
          count
          edges {
            node {
              id
              name
              slug
            }
          }
        }
      }
    }
  }
`;

export const stacksFilters = gql`
  query stacksFilters($queryVar: String, $orgId: ID, $after: String, $first: Int) {
    currentPrivateCompany {
      id
      slug
      dashboardFilters {
        stacks(query: $queryVar, orgId: $orgId, first: $first, after: $after) {
          pageInfo {
            hasNextPage
            endCursor
          }
          count
          edges {
            node {
              id
              name
              slug
            }
          }
        }
      }
    }
  }
`;

export const vulnerabilityInfo = gql`
  query vulnerabilityInfo($id: ID!, $orgId: ID!, $filters: Hash, $first: Int, $after: String) {
    currentPrivateCompany {
      name
      id
      vulnerability(id: $id, orgId: $orgId) {
        id
        cveId
        ghsaId
        name
        description
        severity
        url
        detectedAt
        stacksCount
        tool {
          name
          imageUrl
          packageUrl
          latestVersionNumber
          path
        }
        vulnerabilityStacks(filters: $filters, first: $first, after: $after) {
          edges {
            node {
              stack {
                slug
                name
                path
                slug
                stackApps(filters: $filters) {
                  count
                  edges {
                    node {
                      name
                      canonicalUrl
                      slug
                    }
                  }
                }
              }
              stackItem {
                versionNumber
                sourceFilename
                displayCheckRunUrl
              }
              firstPatchedVersion
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    }
  }
`;
