export const TOOLS = 'Tool';
export const PACKAGES = 'Package';
export const COMPANIES = 'Company';
export const STACKS = 'Stack';
export const CATEGORIES = 'Category';
export const USERS = 'User';
export const STACKUPS = 'Stackup';
export const POSTS = 'Post';
export const FUNCTION = 'Function';

export const SEARCH_HIT_NAMES = {
  [TOOLS]: 'tools',
  [PACKAGES]: 'packages',
  [COMPANIES]: 'companies',
  [STACKS]: 'stacks',
  [CATEGORIES]: 'categories',
  [USERS]: 'users',
  [STACKUPS]: 'stackups',
  [POSTS]: 'posts',
  [FUNCTION]: 'groups'
};

export const SECTION_NAMES = {
  [TOOLS]: 'Tools',
  [PACKAGES]: 'Packages',
  [COMPANIES]: 'Companies',
  [STACKS]: 'Stacks',
  [CATEGORIES]: 'Categories',
  [USERS]: 'Users',
  [STACKUPS]: 'Stackups',
  [POSTS]: 'Posts',
  [FUNCTION]: 'Groups'
};
