import React from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import {ASH, CATHEDRAL, ERROR_RED, GUNSMOKE, TARMAC} from '../../../style/colors';
import {BASE_TEXT, WEIGHT} from '../../../style/typography';

const Container = glamorous.div({
  ...BASE_TEXT,
  display: 'flex',
  flexDirection: 'column',
  ' label': {
    fontWeight: WEIGHT.ULTRA_BOLD,
    marginBottom: 5,
    color: TARMAC
  }
});

const Input = glamorous.input(
  {
    width: '100%',
    boxSizing: 'border-box',
    ...BASE_TEXT,
    height: 45,
    lineHeight: '45px',
    fontSize: 14,
    padding: '0 15px',
    borderRadius: 2,
    color: CATHEDRAL,
    caretColor: CATHEDRAL,
    WebkitAppearance: 'none',
    '::placeholder': {
      ...BASE_TEXT,
      fontSize: 14,
      lineHeight: '45px',
      color: GUNSMOKE
    },
    ':focus': {
      outline: 'none'
    }
  },
  ({error, borderWidth = '2'}) => ({
    border: error ? `${borderWidth}px solid ${ERROR_RED}` : `1px solid ${ASH}`,
    ':focus': {
      border: error ? `${borderWidth}px solid ${ERROR_RED}` : `1px solid ${GUNSMOKE}`
    }
  })
);

const Error = glamorous.div({
  ...BASE_TEXT,
  fontSize: 14,
  color: ERROR_RED,
  marginTop: 6,
  letterSpacing: 0.4
});

const InputContainer = glamorous.div({
  display: 'flex'
});

const PhoneNumber = ({
  id,
  label,
  error,
  onChange,
  placeholder,
  value,
  style,
  borderWidth,
  type
}) => {
  return (
    <Container style={style}>
      {label && <label htmlFor={id}>{label}</label>}
      <InputContainer>
        <Input
          type={type}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          error={Boolean(error)}
          borderWidth={borderWidth}
        />
      </InputContainer>
      {error && <Error>{error}</Error>}
    </Container>
  );
};

PhoneNumber.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.any]),
  style: PropTypes.object,
  borderWidth: PropTypes.number,
  type: PropTypes.string
};

export default PhoneNumber;
