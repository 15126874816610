import React, {Component} from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import Indicator from '../../../../indicators/indeterminate/circular';
import StackIcon from '../../../../icons/chips/stack.svg';
import AddIcon from '../../../../icons/add-circle.svg';
import {withSendAnalyticsEvent} from '../../../../../enhancers/analytics-enhancer';
import {stackLabel, stackImage, stackResultsPresenter} from '../../../utils';
import {IMAGE_TYPE_USER, STACK_OWNER_TYPE_COMPANY, STACK_OWNER_TYPE_USER} from '../../../constants';
import {
  SearchContainer,
  Panel,
  InputPanel,
  Input,
  IndicatorWrapper,
  List,
  Item,
  Name,
  AddIconWrapper
} from '../company-meta/search';
import {COMPOSER_SEARCH_STACK} from '../../../../../constants/analytics';
import {allStacks} from '../../../../../../data/shared/queries';
import {debounce} from '../../../../../utils/debounce';

const StyledList = glamorous(List)({
  maxHeight: 704,
  overflowY: 'scroll'
});

const Image = glamorous.img(
  {
    height: 32,
    width: 32
  },
  ({imageType}) => ({
    borderRadius: imageType === IMAGE_TYPE_USER ? '50%' : 0
  })
);

export class StackSearch extends Component {
  static propTypes = {
    onChoose: PropTypes.func,
    sendAnalyticsEvent: PropTypes.func,
    company: PropTypes.object,
    client: PropTypes.object,
    isPrivate: PropTypes.bool
  };

  static defaultProps = {
    myCompanies: []
  };

  state = {
    keyword: '',
    isSearching: false,
    allStacks: []
  };

  handleSearch = event => {
    const keyword = event.target.value;
    if (keyword && keyword.length > 0) {
      this.setState({isSearching: true});
      setTimeout(() => {
        this.setState({isSearching: false});
      }, 250);
    } else {
      this.setState({isSearching: false});
    }
    this.setState({keyword}, () => {
      this.props.sendAnalyticsEvent(COMPOSER_SEARCH_STACK, {
        keyword,
        ...stackResultsPresenter('results', this.state.allStacks)
      });
    });
  };

  getSearchedData = debounce(value => {
    this.props.client
      .query({
        query: allStacks,
        fetchPolicy: 'network-only',
        variables: {
          query: value,
          slug: this.props.company ? this.props.company.slug : ''
        }
      })
      .then(resp => {
        if (!resp.data.loading) {
          const allStacksResults = resp.data
            ? resp.data.allStacks.edges.map(item => {
                return {
                  ...item.node,
                  stackOwner: item.node.stackType === 'company' ? item.node.owner.name : 'Personal',
                  stackOwnerType:
                    item.node.stackType === 'company'
                      ? STACK_OWNER_TYPE_COMPANY
                      : STACK_OWNER_TYPE_USER
                };
              })
            : [];

          this.setState({
            allStacks: allStacksResults
          });
        }
      });
  }, 1000);

  handleAddNewStack = () => {
    window.open('/create-stack/scan', '_blank');
    this.props.onChoose(null);
  };

  inputRef = null;
  assignInput = el => (this.inputRef = el);

  componentDidMount() {
    this.inputRef.focus();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.keyword !== this.state.keyword) {
      this.getSearchedData(this.state.keyword);
    }
  }

  renderStack = stack => {
    const {identifier, imageUrl, name, type} = stack;
    return (
      <Item key={identifier} onClick={() => this.props.onChoose(stack)}>
        {imageUrl ? (
          <Image src={imageUrl} imageType={type} title={name} alt={name} />
        ) : (
          stackImage(type)
        )}
        <Name>{stackLabel({stack, bold: true})}</Name>
      </Item>
    );
  };

  render() {
    const {keyword, isSearching} = this.state;
    const {company, isPrivate} = this.props;

    const privateStacks = this.state.allStacks
      .filter(i => i.private)
      .map(stack => this.renderStack(stack));

    const publicStacks = this.state.allStacks
      .filter(i => !i.private)
      .map(stack => this.renderStack(stack));

    const personalAndPublicStacks = this.state.allStacks
      .filter(i => i.stackType !== 'company' || (i.stackType === 'company' && !i.private))
      .map(stack => this.renderStack(stack));

    return (
      <SearchContainer>
        <Panel>
          <InputPanel>
            <StackIcon />
            <Input
              innerRef={this.assignInput}
              placeholder="Find my stack…"
              value={keyword}
              onChange={this.handleSearch}
              data-testid="findMyStackBox"
            />
          </InputPanel>
          {isSearching && (
            <IndicatorWrapper>
              <Indicator size={32} />
            </IndicatorWrapper>
          )}
          <StyledList data-testid="stacksList">
            {keyword
              ? company
                ? isPrivate
                  ? privateStacks
                  : publicStacks
                : personalAndPublicStacks
              : ''}
            <Item onClick={this.handleAddNewStack}>
              <AddIconWrapper>
                <AddIcon />
              </AddIconWrapper>
              <Name data-testid="addNewStack">Add new stack&hellip;</Name>
            </Item>
          </StyledList>
        </Panel>
      </SearchContainer>
    );
  }
}

export default withSendAnalyticsEvent(StackSearch);
