import {filterQueryKeys} from '../../../shared/library/filters/utils';
export const SORT = 'sort';
export const ORG = 'org';

export const QUERY = {
  ...filterQueryKeys,
  stacks: 'STACKS_SORT',
  members: 'MEMBERS_SORT',
  teams: 'TEAMS_SORT',
  popularity: 'POPULARITY_SORT',
  versions: 'VERSIONS_SORT',
  DESC: 'descending',
  AESC: 'ascending'
};
