export const FEED = 'feed';
export const TYPE_ALL = 'all';
export const TYPE_TRENDING = 'trending';
export const TYPE_ADVICE = 'advice';
export const TYPE_PRIVATE_ADVICE = 'privateAdvice';
export const TYPE_USER = 'user';
export const TYPE_SERVICE = 'service';
export const TYPE_TOOL = 'tool';
export const TYPE_COMPANY = 'company';
export const TYPE_PRIVATE_FEED = 'privateFeed';
export const TYPE_USER_DECISION = 'decisions';
export const TYPE_MY_DECISION = 'myDecisions';
export const TYPE_STACK_DECISION = 'stackDecisions';
export const TYPE_FUNCTION = 'function';
export const TYPE_CATEGORY = 'category';
export const TYPE_LAYER = 'layer';
export const FEED_PATH_ALL = `/${FEED}/${TYPE_ALL}`;
export const FEED_PATH_USER = `/${FEED}`;
export const FEED_PATH_TRENDING = `/${FEED}/${TYPE_TRENDING}`;
export const FEED_PATH_ADVICE = `/${FEED}/${TYPE_ADVICE}`;
export const FEED_PATH_PUBLIC_ADVICE = `/${FEED}/${TYPE_ADVICE}/public`;
export const FEED_PUBLIC = 'feed/public';
export const FEED_PATH_MANAGE_TOOLS = `/${FEED}/manage_tools`;
export const ITEM_TYPE_ALL = 'all';
export const ITEM_TYPE_DECISIONS = 'decisions';
export const ITEM_TYPE_ARTICLES = 'articles';
export const TITLE_MY_DECISIONS = 'My Decisions | StackShare';
export const TITLE_FEED = 'Feed | StackShare';
export const TITLE_ADVICE = 'Stack Advice | StackShare';
export const TYPENAME_FEED_ITEM = 'FeedItem';
export const TYPENAME_FEED_ITEM_EDGE = 'FeedItemEdge';
export const TYPENAME_STACK_DECISION_EDGE = 'StackDecisionEdge';
