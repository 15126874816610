export const isUUID = uuid => uuid && typeof uuid === 'string' && uuid.indexOf('-') !== -1;

export function getFeedId({routeContext: {feedType}, feedContext, userId}) {
  let feedId = null;

  if (feedType === 'all') {
    // Trending
    feedId = 'all:1';
  } else if (feedType === 'user') {
    // My Feed
    feedId = `user:${userId}`;
  } else if (feedContext) {
    feedId = `${feedType}:${feedContext.id}`;
  }

  return feedId;
}
