import React from 'react';
import PropTypes from 'prop-types';
import {NAVBAR_HEIGHT} from '../../../../shared/style/dimensions';

export const NAV_ANCHOR_OFFSET = 110 + NAVBAR_HEIGHT;

const NavAnchor = ({id, offset = 0, span}) => (
  <div
    id={id}
    style={{
      pointerEvents: 'none',
      gridColumn: `span ${span}`,
      display: 'block',
      height: offset,
      marginTop: -offset
    }}
  />
);

NavAnchor.propTypes = {
  id: PropTypes.string,
  offset: PropTypes.number,
  span: PropTypes.number
};

export default NavAnchor;
