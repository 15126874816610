import gql from 'graphql-tag';

export const dashboardMembers = gql`
  query dashboardMembers {
    me {
      id
      impersonated
      members(first: 4) {
        count
        edges {
          node {
            id
            displayName
            imageUrl
          }
        }
      }
    }
  }
`;

export const dashboardTeams = gql`
  query dashboardTeams {
    me {
      id
      impersonated
      privateTeams(first: 6) {
        count
        edges {
          node {
            id
            name
            imageUrl
          }
        }
      }
    }
  }
`;

export const dashboardStacks = gql`
  query dashboardStacks($after: String, $first: Int) {
    me {
      id
      impersonated
      repoStacksCount
      hasPersonalGithubInstall
      # githubAppSettingsUrl
      repoStacksCount
      stacks(after: $after, first: $first) {
        pageInfo {
          hasNextPage
          endCursor
        }
        count
        edges {
          node {
            id
            slug
            name
            path
            private
            repoStack
            services(withoutPackages: true) {
              id
              name
              slug
              title
              imageUrl
              layer {
                slug
              }
            }
          }
        }
      }
    }
  }
`;

export const dashboardTools = gql`
  query dashboardTools {
    me {
      id
      impersonated
      filterableToolsFromStacks(first: 20) {
        count
        edges {
          node {
            id
            userMajorVersions {
              count
            }
            name
            imageUrl
          }
        }
      }
    }
  }
`;

export const dashboardPackageManagers = gql`
  query dashboardPackageManagers {
    me {
      id
      impersonated
      packagesCountFromStacks
      packageManagersFromStacks(first: 50) {
        edges {
          node {
            id
            name
            imageUrl
            packagesFromUserStacks {
              count
            }
          }
        }
      }
    }
  }
`;

export const dashboardRecentActivities = gql`
  query dashboardRecentActivities($first: Int, $after: String) {
    me {
      id
      impersonated
      username
      recentActivities(first: $first, after: $after) {
        count
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            action
            createdAt
            whoDidIt {
              id
              username
              displayName
              imageUrl
            }
            extraDetails
            activityObject {
              ... on Comment {
                id
                content
                commentableId
              }
              ... on Stack {
                id
                name
                canonicalUrl
              }
              ... on User {
                id
                username
                imageUrl
              }
              ... on StackChange {
                tool {
                  id
                  name
                  slug
                  imageUrl
                }
                stack {
                  id
                  name
                  canonicalUrl
                }
                newVersionNumber
                oldVersionNumber
                previousStage
                currentStage
              }
              ... on StackDecision {
                id
                stack {
                  name
                }
                subjectTools {
                  id
                  name
                  slug
                  imageUrl
                }
                toTools {
                  id
                  imageUrl
                  name
                  slug
                }
                fromTools {
                  id
                  imageUrl
                  name
                  slug
                }
              }
              ... on Vulnerability {
                cveId
                name
                severity
                url
                detectedAt
                tool {
                  name
                  imageUrl
                }
                vulnerabilityStacks {
                  count
                  edges {
                    node {
                      stack {
                        slug
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const reportTools = gql`
  query reportTools(
    $after: String
    $first: Int
    $orderBy: OrderInput
    $filters: Hash
    $queryVar: String
    $companySlug: String!
  ) {
    me {
      id
      impersonated
      filterableToolsFromStacks(
        first: $first
        after: $after
        orderBy: $orderBy
        filters: $filters
        query: $queryVar
      ) {
        pageInfo {
          hasNextPage
          endCursor
        }
        totalCount
        edges {
          node {
            userMajorVersions {
              count
            }
            id
            title
            name
            slug
            imageUrl
            path
            stacksCount
            accountHighestVulnerability {
              severity
            }
            myStacks {
              count
            }
            toolType
            adoptionStage(companySlug: $companySlug) {
              id
              name
              slug
            }
            adoptionStageContext
            versionRules {
              count
            }
          }
        }
      }
      stacks {
        count
      }
    }
  }
`;

export const toolMetaData = gql`
  query toolMetaData($id: ID!) {
    tool(id: $id) {
      id
      name
      slug
      type
      path
      imageUrl
      latestVersionNumber
      userMajorVersions {
        edges {
          node {
            stackItems {
              edges {
                node {
                  versionNumber
                }
              }
            }
          }
        }
      }
      license {
        name
        url
      }
      pressUrl
    }
  }
`;

export const reportPackages = gql`
  query reportPackages(
    $after: String
    $first: Int
    $orderBy: OrderInput
    $filters: Hash
    $queryVar: String
    $companySlug: String!
  ) {
    me {
      id
      impersonated
      filterablePackagesFromStacks(
        first: $first
        after: $after
        orderBy: $orderBy
        filters: $filters
        query: $queryVar
      ) {
        pageInfo {
          hasNextPage
          endCursor
        }
        totalCount
        edges {
          node {
            userMajorVersions {
              count
            }
            id
            title
            name
            imageUrl
            path
            type
            stacksCount
            accountHighestVulnerability {
              severity
            }
            myStacks {
              count
            }
            adoptionStage(companySlug: $companySlug) {
              id
              name
            }
            packageManager {
              name
              miniImageUrl
              slug
            }
          }
        }
      }
      stacks {
        count
      }
    }
  }
`;

export const reportPackagesCount = gql`
  query reportPackagesCount(
    $after: String
    $first: Int
    $orderBy: OrderInput
    $filters: Hash
    $queryVar: String
  ) {
    me {
      id
      impersonated
      filterablePackagesFromStacks(
        first: $first
        after: $after
        orderBy: $orderBy
        filters: $filters
        query: $queryVar
      ) {
        count
      }
    }
  }
`;

export const reportStacks = gql`
  query reportStacks($after: String, $first: Int, $filters: Hash, $searchQuery: String) {
    me {
      id
      impersonated
      stacks(first: $first, after: $after, filters: $filters, searchQuery: $searchQuery) {
        pageInfo {
          hasNextPage
          endCursor
        }
        totalCount
        edges {
          node {
            id
            slug
            name
            path
            identifier
            websiteUrl
            private
            repoStack
            toolsCount
            packagesCount
            vulnerabilitiesCount
            services(withoutPackages: true) {
              id
              name
              slug
              title
              imageUrl
              layer {
                slug
              }
            }
          }
        }
      }
      repoStacksCount
      hasPersonalGithubInstall
      username
      path
    }
  }
`;

export const reportToolsCount = gql`
  query reportToolsCount(
    $after: String
    $first: Int
    $orderBy: OrderInput
    $filters: Hash
    $queryVar: String
  ) {
    me {
      id
      impersonated
      filterableToolsFromStacks(
        first: $first
        after: $after
        orderBy: $orderBy
        filters: $filters
        query: $queryVar
      ) {
        count
      }
    }
  }
`;

export const reportStacksCount = gql`
  query reportStacksCount($after: String, $first: Int, $filters: Hash, $searchQuery: String) {
    me {
      id
      impersonated
      stacks(first: $first, after: $after, filters: $filters, searchQuery: $searchQuery) {
        count
      }
    }
  }
`;

export const filterableToolsFromStacks = gql`
  query filterableToolsFromStacks(
    $after: String
    $first: Int
    $queryVar: String
    $filters: Hash
    $orderBy: Hash
  ) {
    me {
      id
      impersonated
      filterableToolsFromStacks(
        after: $after
        first: $first
        query: $queryVar
        filters: $filters
        orderBy: $orderBy
      ) {
        pageInfo {
          hasNextPage
          endCursor
        }
        count
        edges {
          node {
            id
            userMajorVersions {
              count
            }
            title
            name
            slug
            imageUrl
            path
            stacksCount
            membersCount
            myStacks {
              count
            }
          }
        }
      }
    }
  }
`;

export const packagesFromPrivateStacks = gql`
  query packagesFromPrivateStacks($id: ID!, $queryVar: String) {
    company(id: $id) {
      id
      slug
      packagesFromPrivateStacks(query: $queryVar) {
        edges {
          node {
            id
            userMajorVersions {
              count
            }
            title
            name
            imageUrl
            path
            stacksCount

            membersCount
            myStacks {
              count
            }
            packageManager {
              name
              miniImageUrl
            }
          }
        }
      }
    }
  }
`;

export const toolTeams = gql`
  query toolTeams($id: ID!, $first: Int, $after: String) {
    tool(id: $id) {
      id
      slug
      privateTeams(after: $after, first: $first) {
        count
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            id
            name
            imageUrl
            canonicalUrl
          }
        }
      }
    }
  }
`;

export const toolMembers = gql`
  query toolMembers($id: ID!, $first: Int, $after: String) {
    tool(id: $id) {
      id
      privateUsersViaContributedStacks(after: $after, first: $first) {
        count
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            id
            displayName
            imageUrl
            path
          }
        }
      }
    }
  }
`;

export const toolStacks = gql`
  query toolStacks($id: ID!, $first: Int, $after: String) {
    tool(id: $id) {
      id
      privateStacks: myStacks(after: $after, first: $first) {
        count
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            id
            name
            path
          }
        }
      }
    }
  }
`;

export const toolVersions = gql`
  query toolVersions($id: ID!) {
    tool(id: $id) {
      id
      name
      imageUrl
      userMajorVersions {
        count
        edges {
          node {
            number
            stackItems {
              count
              edges {
                node {
                  stack {
                    id
                    name
                    path
                  }
                  sourceFilename
                  displayCheckRunUrl
                  versionNumber
                  highestVulnerability {
                    url
                    severity
                    name
                    detectedAt
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const packageTeams = gql`
  query packageTeams($id: ID!) {
    tool(id: $id) {
      id
      slug
      privateTeams {
        count
        edges {
          node {
            id
            name
            imageUrl
            canonicalUrl
          }
        }
      }
    }
  }
`;

export const packageMembers = gql`
  query packageMembers($id: ID!) {
    tool(id: $id) {
      id
      privateUsersViaContributedStacks {
        count
        edges {
          node {
            id
            displayName
            imageUrl
            path
          }
        }
      }
    }
  }
`;

export const packageStacks = gql`
  query packageStacks($id: ID!) {
    tool(id: $id) {
      id
      privateStacks: myStacks {
        count
        edges {
          node {
            id
            name
            path
          }
        }
      }
    }
  }
`;

export const packageVersions = gql`
  query packageVersions($id: ID!) {
    tool(id: $id) {
      id
      name
      imageUrl
      userMajorVersions {
        edges {
          node {
            number
            stackItems {
              count
              edges {
                node {
                  stack {
                    id
                    name
                    path
                  }
                  sourceFilename
                  displayCheckRunUrl
                  versionNumber
                  highestVulnerability {
                    url
                    severity
                    name
                    detectedAt
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const user = gql`
  query user {
    me {
      id
      impersonated
      hasPersonalGithubInstall
      packagesCountFromStacks
      dashboardInsights {
        mostUsedTools
        potentialTechDebt
      }
      recentActivities {
        count
      }
      shouldForceVcsConnection
    }
  }
`;

export const dashboardStackApps = gql`
  query dashboardStackApps {
    me {
      privateStacksRepoCount
      id
      impersonated
      slug
      stackApps {
        count
        edges {
          node {
            id
            canonicalUrl
            description
            name
            stacks {
              count
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const userFunctionFilters = gql`
  query userFunctionFilters($queryVar: String, $after: String, $first: Int) {
    me {
      id
      impersonated
      slug
      dashboardFilters {
        functions(query: $queryVar, first: $first, after: $after) {
          pageInfo {
            hasNextPage
            endCursor
          }
          count
          edges {
            node {
              id
              name
              slug
            }
          }
        }
      }
    }
  }
`;

export const userCategoryFilters = gql`
  query userCategoryFilters($queryVar: String, $after: String, $first: Int) {
    me {
      id
      impersonated
      slug
      dashboardFilters {
        categories(query: $queryVar, first: $first, after: $after) {
          pageInfo {
            hasNextPage
            endCursor
          }
          count
          edges {
            node {
              id
              name
              slug
            }
          }
        }
      }
    }
  }
`;

export const userLayerFilters = gql`
  query userLayerFilters {
    me {
      id
      impersonated
      slug
      dashboardFilters {
        layers {
          pageInfo {
            hasNextPage
            endCursor
          }
          count
          edges {
            node {
              id
              name
              slug
            }
          }
        }
      }
    }
  }
`;

export const userApplicationFilters = gql`
  query userApplicationFilters($queryVar: String, $after: String, $first: Int) {
    me {
      id
      impersonated
      slug
      dashboardFilters {
        stackApps(query: $queryVar, first: $first, after: $after) {
          pageInfo {
            hasNextPage
            endCursor
          }
          count
          edges {
            node {
              id
              name
              slug
            }
          }
        }
      }
    }
  }
`;

export const userPackageFilters = gql`
  query userPackageFilters($queryVar: String, $after: String, $first: Int) {
    me {
      id
      impersonated
      slug
      dashboardFilters {
        packageManagersFromStacks(query: $queryVar, first: $first, after: $after) {
          pageInfo {
            hasNextPage
            endCursor
          }
          count
          edges {
            node {
              id
              name
              slug
            }
          }
        }
      }
    }
  }
`;

export const userStageFilters = gql`
  query userStageFilters($queryVar: String, $after: String, $first: Int) {
    me {
      id
      impersonated
      slug
      dashboardFilters {
        adoptionStages(query: $queryVar, first: $first, after: $after) {
          pageInfo {
            hasNextPage
            endCursor
          }
          count
          edges {
            node {
              id
              name
              slug
            }
          }
        }
      }
    }
  }
`;

export const userTagFilters = gql`
  query userTagFilters($queryVar: String, $after: String, $first: Int) {
    me {
      id
      impersonated
      slug
      dashboardFilters {
        privateTags(query: $queryVar, first: $first, after: $after) {
          pageInfo {
            hasNextPage
            endCursor
          }
          count
          edges {
            node {
              id
              name
              slug
            }
          }
        }
      }
    }
  }
`;

export const userAppliedFilters = gql`
  query userAppliedFilters(
    $functions: [String!]
    $categories: [String!]
    $layers: [String!]
    $stackApps: [String!]
    $adoptionStages: [String!]
    $privateTags: [String!]
    $packageManagersFromStacks: [String!]
    $teams: [String!]
    $stacks: [String!]
    $licenses: [String!]
    $functionsAccess: Boolean!
    $categoriesAccess: Boolean!
    $layersAccess: Boolean!
    $stackAppsAccess: Boolean!
    $adoptionStagesAccess: Boolean!
    $privateTagsAccess: Boolean!
    $packageManagersFromStacksAccess: Boolean!
    $teamsAccess: Boolean!
    $stacksAccess: Boolean!
    $licensesAccess: Boolean!
  ) {
    me {
      id
      impersonated
      slug
      dashboardFilters {
        categories(slugs: $categories) @include(if: $categoriesAccess) {
          edges {
            node {
              id
              name
              slug
            }
          }
        }
        functions(slugs: $functions) @include(if: $functionsAccess) {
          edges {
            node {
              id
              name
              slug
            }
          }
        }
        layers(slugs: $layers) @include(if: $layersAccess) {
          edges {
            node {
              id
              name
              slug
            }
          }
        }
        stackApps(slugs: $stackApps) @include(if: $stackAppsAccess) {
          edges {
            node {
              id
              name
              slug
            }
          }
        }
        adoptionStages(slugs: $adoptionStages) @include(if: $adoptionStagesAccess) {
          edges {
            node {
              id
              name
              slug
            }
          }
        }
        privateTags(slugs: $privateTags) @include(if: $privateTagsAccess) {
          edges {
            node {
              id
              name
              slug
            }
          }
        }
        packageManagersFromStacks(slugs: $packageManagersFromStacks)
          @include(if: $packageManagersFromStacksAccess) {
          edges {
            node {
              id
              name
              slug
            }
          }
        }
        teams(slugs: $teams) @include(if: $teamsAccess) {
          edges {
            node {
              id
              name
              slug
            }
          }
        }
        stacks(slugs: $stacks) @include(if: $stacksAccess) {
          edges {
            node {
              id
              name
              slug
            }
          }
        }
        licenses(names: $licenses) @include(if: $licensesAccess) {
          edges {
            node {
              id: name
              name
              slug: name
            }
          }
        }
      }
    }
  }
`;

export const dashboardInsights = gql`
  query dashboardInsights {
    me {
      id
      impersonated
      slug
      dashboardInsights {
        mostUsedTools
        potentialTechDebt
        vulnerabilities
      }
    }
  }
`;

export const userVulnerabilities = gql`
  query userVulnerabilities($first: Int, $searchQuery: String, $after: String, $filters: Hash) {
    me {
      id
      impersonated
      vulnerabilityDashboard {
        vulnerabilities(
          first: $first
          after: $after
          searchQuery: $searchQuery
          filters: $filters
        ) {
          pageInfo {
            hasNextPage
            endCursor
          }
          totalCount
          edges {
            node {
              id
              cveId
              ghsaId
              name
              description
              severity
              url
              detectedAt
              stacksCount
              tool {
                name
                slug
                imageUrl
                packageUrl
                latestVersionNumber
                path
              }
              vulnerabilityStacks(filters: $filters) {
                count
              }
            }
          }
        }
      }
    }
  }
`;

export const userVulnerabilitiesCount = gql`
  query userVulnerabilitiesCount(
    $first: Int
    $searchQuery: String
    $after: String
    $filters: Hash
  ) {
    me {
      id
      impersonated
      vulnerabilityDashboard {
        vulnerabilities(
          first: $first
          after: $after
          searchQuery: $searchQuery
          filters: $filters
        ) {
          count
        }
      }
    }
  }
`;

export const userVulnerabilityFilters = gql`
  query userVulnerabilityFilters {
    me {
      id
      impersonated
      slug
      dashboardFilters {
        vulnerabilitySeverities
      }
    }
  }
`;
export const userTeamsFilters = gql`
  query teamsFilters($queryVar: String, $after: String, $first: Int) {
    me {
      id
      impersonated
      slug
      dashboardFilters {
        teams(query: $queryVar, first: $first, after: $after) {
          pageInfo {
            hasNextPage
            endCursor
          }
          count
          edges {
            node {
              id
              name
              slug
            }
          }
        }
      }
    }
  }
`;

export const userStacksFilters = gql`
  query stacksFilters($queryVar: String, $after: String, $first: Int) {
    me {
      id
      impersonated
      slug
      dashboardFilters {
        stacks(query: $queryVar, first: $first, after: $after) {
          pageInfo {
            hasNextPage
            endCursor
          }
          count
          edges {
            node {
              id
              name
              slug
            }
          }
        }
      }
    }
  }
`;

export const userLicenseFilters = gql`
  query licenseFilters($queryVar: String, $first: Int, $after: String) {
    me {
      id
      slug
      dashboardFilters {
        licenses(query: $queryVar, first: $first, after: $after) {
          pageInfo {
            hasNextPage
            endCursor
          }
          count
          edges {
            node {
              id: name
              name
              slug: name
            }
          }
        }
      }
    }
  }
`;

export const userVisibilityFilters = gql`
  query visibilityFilters {
    me {
      id
      slug
      dashboardFilters {
        visibilities
      }
    }
  }
`;

export const vulnerabilityInfo = gql`
  query vulnerabilityInfo($id: ID!) {
    me {
      id
      impersonated
      vulnerability(id: $id) {
        id
        cveId
        ghsaId
        name
        description
        severity
        url
        detectedAt
        stacksCount
        tool {
          name
          imageUrl
          packageUrl
          latestVersionNumber
          path
        }
        vulnerabilityStacks {
          edges {
            node {
              stack {
                slug
                name
                path
                stackApps {
                  count
                  edges {
                    node {
                      name
                      canonicalUrl
                    }
                  }
                }
              }
              stackItem {
                versionNumber
                sourceFilename
                displayCheckRunUrl
              }
              firstPatchedVersion
            }
          }
        }
      }
    }
  }
`;

export const stackTools = gql`
  query stackTools($id: String!, $query: String) {
    stackProfile(identifier: $id) {
      id
      name
      slug
      tools(query: $query, withoutPackages: true) {
        id
        path
        type
        name
        imageUrl
      }
    }
  }
`;

export const stackPackages = gql`
  query stackPackages($id: String!, $first: Int, $after: String, $query: String) {
    stackProfile(identifier: $id) {
      id
      name
      slug
      packages(after: $after, first: $first, query: $query) {
        count
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            path
            name
            imageUrl
            type
            packageManager {
              slug
              name
              miniImageUrl
            }
          }
        }
      }
    }
  }
`;

export const userEndOfLifeFilters = gql`
  query userEndOfLifeFilters {
    me {
      id
      slug
      dashboardFilters {
        endOfLifeStatuses
      }
    }
  }
`;

export const versionData = gql`
  query versionData($id: ID!, $first: Int, $after: String) {
    tool(id: $id) {
      id
      name
      imageUrl
      releasePolicyUrl
      userMajorVersions {
        count
        edges {
          node {
            number
            stackItems(first: $first, after: $after) {
              count
              edges {
                node {
                  stack {
                    id
                    name
                    path
                  }
                  sourceFilename
                  displayCheckRunUrl
                  versionNumber
                  versionReleasedAt
                  endOfLifeAt
                  eolStatus
                  highestVulnerability {
                    url
                    severity
                    name
                    detectedAt
                  }
                  versionStage {
                    id
                    name
                    slug: id
                  }
                  versionStageContext
                }
              }
              pageInfo {
                hasNextPage
                endCursor
              }
            }
          }
        }
      }
    }
  }
`;
