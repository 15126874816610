import glamorous from 'glamorous';
import * as glamor from 'glamor';
import {CYAN_BLUE} from '../../style/colors';

const spin = glamor.css.keyframes({
  to: {transform: 'rotate(360deg)'}
});

export const MEDIUM = 30;

export default glamorous.div(
  {
    borderRadius: '50%',
    animation: `${spin} 1s linear infinite`,
    padding: 8,
    aspectRatio: 1,
    margin: 'auto',
    background: (`radial-gradient(farthest-side, ${CYAN_BLUE} 94%, #0000) top/8px 8px no-repeat`,
    `conic-gradient(#0000 30%, ${CYAN_BLUE})`),
    '-webkit-mask': 'radial-gradient(farthest-side, #0000 calc(100% - 4px), #000 0)'
  },
  ({size = MEDIUM}) => ({
    width: size,
    height: size
  }),
  ({customStyle}) => (customStyle ? customStyle : {})
);
