import React from 'react';
import {POST_TYPE_ICONS} from '../../../shared/library/cards/post/constants';
import ChangeIcon from '../../../shared/library/icons/recent-activities/change_icon.svg';
import PlusGreenIcon from '../../../shared/library/icons/recent-activities/plus_green.svg';
import StageDarkIcon from '../../../shared/library/icons/recent-activities/stage_dark.svg';
import CommentIcon from '../../../shared/library/icons/recent-activities/icon_comment.svg';
import RedCrossIcon from '../../../shared/library/icons/recent-activities/remove_icon.svg';
import VulnerabilityIcon from '../../../shared/library/icons/recent-activities/vulnerabilities.svg';

export const TOOL_ADDED = 'added_tool';
export const TOOL_REMOVED = 'removed_tool';

export const VERSION_CHANGE = 'changed_version';
export const STAGE_CHANGE = 'stage_change';

export const DECISION_CREATED = 'decision_created';
//autoCreated = false that means manullay
export const MANUALLY_GET_ADVICE = 'getAdvice';
export const MANUALLY_GIVE_ADVICE = 'giveAdvice';
export const MANUALLY_CHOICE = 'tool';
export const MANUALLY_MIGRATION = 'migration';
export const MANUALLY_PROTIP = 'protip';
export const MANUALLY_INSIGHT = 'freeform';

export const AUTO_CHOICE = 'auto_choice';

export const COMMENT_ON_DECISION = 'comment_on_decision';

export const STACK_CREATED = 'stack_created';
export const STACK_DELETED = 'stack_deleted';

export const ADD_MEMBER = 'add_member';
export const REMOVE_MEMBER = 'remove_member';

export const VULNERABILITY_DETECTED = 'vulnerability_detected';

export const ACTIVITY_ICONS = {
  [TOOL_ADDED]: <PlusGreenIcon />,
  [TOOL_REMOVED]: <RedCrossIcon />,
  [VERSION_CHANGE]: <ChangeIcon />,
  [STAGE_CHANGE]: <StageDarkIcon />,
  [MANUALLY_CHOICE]: POST_TYPE_ICONS.tool,
  [MANUALLY_GET_ADVICE]: POST_TYPE_ICONS.getAdvice,
  [MANUALLY_GIVE_ADVICE]: POST_TYPE_ICONS.giveAdvice,
  [MANUALLY_MIGRATION]: POST_TYPE_ICONS.migration,
  [MANUALLY_PROTIP]: POST_TYPE_ICONS.protip,
  [MANUALLY_INSIGHT]: POST_TYPE_ICONS.freeform,
  [AUTO_CHOICE]: <PlusGreenIcon />,
  [COMMENT_ON_DECISION]: <CommentIcon />,
  [STACK_CREATED]: <PlusGreenIcon />,
  [STACK_DELETED]: <RedCrossIcon />,
  [ADD_MEMBER]: <PlusGreenIcon />,
  [REMOVE_MEMBER]: <RedCrossIcon />,
  [VULNERABILITY_DETECTED]: <VulnerabilityIcon />
};
