// New package types can be found here: https://stackshare.io/admin/package_manager

export const PACKAGES = {
  npm: {
    width: 76,
    height: 19
  },
  rubygems: {
    width: 76,
    height: 19
  },
  pypi: {
    width: 76,
    height: 19
  },
  nuget: {
    width: 76,
    height: 19
  },
  'go-packages': {
    width: 65,
    height: 15
  },
  maven: {
    width: 76,
    height: 19
  },
  terraform: {
    width: 76,
    height: 19
  },
  cocoapods: {
    width: 76,
    height: 19
  },
  helm: {
    width: 76,
    height: 19
  },
  'terraform-registry': {
    width: 76,
    height: 19
  },
  pub: {
    width: 76,
    height: 19
  },
  alcatraz: {
    width: 76,
    height: 19
  },
  inqlude: {
    width: 76,
    height: 19
  },
  haxelib: {
    width: 76,
    height: 19
  },
  hackage: {
    width: 76,
    height: 19
  },
  purescript: {
    width: 76,
    height: 19
  },
  nimble: {
    width: 76,
    height: 19
  },
  racket: {
    width: 76,
    height: 19
  },
  dub: {
    width: 76,
    height: 19
  },
  conda: {
    width: 76,
    height: 19
  },
  julia: {
    width: 76,
    height: 19
  },
  swiftpm: {
    width: 76,
    height: 19
  },
  carthage: {
    width: 76,
    height: 19
  },
  homebrew: {
    width: 76,
    height: 19
  },
  meteor: {
    width: 76,
    height: 19
  },
  cran: {
    width: 76,
    height: 19
  },
  clojars: {
    width: 76,
    height: 19
  },
  bower: {
    width: 76,
    height: 19
  },
  packagist: {
    width: 76,
    height: 19
  },
  'hugging-face-hub': {
    width: 76,
    height: 19
  }
};

export const PACKAGE_REPORTS = {
  npm: {
    width: 59,
    height: 21
  },
  rubygems: {
    width: 59,
    height: 21
  },
  pypi: {
    width: 59,
    height: 21
  },
  nuget: {
    width: 59,
    height: 21
  },
  'go-packages': {
    width: 52,
    height: 18
  },
  maven: {
    width: 59,
    height: 21
  },
  terraform: {
    width: 59,
    height: 21
  },
  cocoapods: {
    width: 59,
    height: 21
  },
  helm: {
    width: 59,
    height: 21
  },
  'terraform-registry': {
    width: 59,
    height: 21
  },
  pub: {
    width: 59,
    height: 21
  },
  alcatraz: {
    width: 59,
    height: 21
  },
  inqlude: {
    width: 59,
    height: 21
  },
  haxelib: {
    width: 59,
    height: 21
  },
  hackage: {
    width: 59,
    height: 21
  },
  purescript: {
    width: 59,
    height: 21
  },
  nimble: {
    width: 59,
    height: 21
  },
  racket: {
    width: 59,
    height: 21
  },
  dub: {
    width: 59,
    height: 21
  },
  conda: {
    width: 59,
    height: 21
  },
  julia: {
    width: 59,
    height: 21
  },
  swiftpm: {
    width: 59,
    height: 21
  },
  carthage: {
    width: 59,
    height: 21
  },
  homebrew: {
    width: 59,
    height: 21
  },
  meteor: {
    width: 59,
    height: 21
  },
  cran: {
    width: 59,
    height: 21
  },
  clojars: {
    width: 59,
    height: 21
  },
  bower: {
    width: 59,
    height: 21
  },
  packagist: {
    width: 59,
    height: 21
  },
  'hugging-face-hub': {
    width: 59,
    height: 21
  }
};
