import {PLANS, STACK_FILE_PLANS, ENTERPRISE_PLANS, STACK_FILE_FREE_PLANS} from './payment-plans';

//THIS FUNCTION RETURNS ALL PAYMENT PLANS OF THE USER.
export const userPlans = currentUser => {
  if (currentUser) {
    const allPlans = [];
    if (Array.isArray(currentUser.plans)) allPlans.push(...currentUser.plans);
    currentUser.companies.forEach(company => {
      if (Array.isArray(company.plans)) {
        allPlans.push(...company.plans);
      }
    });

    return allPlans.map(plan => plan.slug);
  } else return [];
};

export const isFreeUser = currentUser => {
  const plans = userPlans(currentUser);
  return plans.includes(PLANS.FREE);
};

export const isStackFileManagementUser = currentUser => {
  const plans = userPlans(currentUser);
  return plans.some(plan => STACK_FILE_PLANS.includes(plan));
};

export const isEnterpriseUser = currentUser => {
  const plans = userPlans(currentUser);
  return plans.some(plan => ENTERPRISE_PLANS.includes(plan));
};

export const isStackFileManagementFreeUser = currentUser => {
  const plans = userPlans(currentUser);
  return plans.some(plan => STACK_FILE_FREE_PLANS.includes(plan));
};

export const isStackFileManagementPaidUser = currentUser => {
  const plans = userPlans(currentUser);
  return plans.some(
    plan => STACK_FILE_PLANS.includes(plan) && !STACK_FILE_FREE_PLANS.includes(plan)
  );
};
