export const CONTENT_WIDTH = 692;
export const SIDEBAR_WIDTH = 1140 - CONTENT_WIDTH;

export const STACK_TYPE_COMPANY = 'Company';
export const STACK_TYPE_USER = 'User';

export const SP_DECISION_SOURCE = 'StackProfile';

export const SP_CLICK_SHARE_TECH_STACK = 'stackProfile_click_shareTechStack';
export const SP_CLICKED_STACK_OWNER = 'stack.clicked_stack_owner';
export const SP_CLICKED_SETTINGS = 'stack.clicked_settings_dropdown';
export const SP_CLICKED_SETTINGS_ITEM = 'stack.clicked_settings_dropdown_item';
export const SP_CLICKED_NAV_ITEM = 'stack.clicked_header';
export const SP_CLICKED_DETAILS_PANEL_HEADER = 'stack.clicked_details_panel_header';
export const SP_CLICKED_DETAILS_PANEL_TOOL = 'stack.clicked_details_panel_tool';
export const SP_CLICKED_VIEW_MORE_DECISIONS = 'stack.clicked_view_more_decisions';
export const SP_CLICKED_TOOL_ICON = 'stack.clicked_tool_icon';
export const SP_CLICKED_LAYER = 'stack.clicked_layer';
export const SP_CLICKED_JOBS_CARD = 'stack.clicked_jobs_card';
export const SP_JOBS_CARD_CLICK_POPOVER_SERVICE = 'stack.job_card.click_popover_service';
export const SP_JOBS_CARD_HOVER_POPOVER_SERVICE = 'stack.job_card.hover_popover_service';
export const SP_CLICKED_SEE_MORE_STACKS = 'stack.clicked_see_more_stacks';
export const SP_CLICKED_SEE_MORE_JOBS = 'stack.clicked_see_more_jobs';
export const SP_HOVER_USER_POPOVER = 'stack.hover_user_popover';
export const SP_CLICKED_USER_POPOVER = 'stack.clicked_user_popover';
export const SP_CLICKED_OTHER_STACK = 'stack.clicked_other_stack';
export const SP_HOVER_STRENGTH_METER_POPOVER = 'stack.hover_strength_meter_popover';

export const ACTION_OPEN = 'Open';
export const ACTION_CLOSE = 'Close';
export const ACTION_SELECT = 'Select';
export const ACTION_DESELECT = 'Deselect';

export const TAB_STACK = 'stack';
export const TAB_DECISIONS = 'decisions';
export const TAB_JOBS = 'jobs';
export const TAB_TEAM = 'members';
export const PRIVATE_TEAM = 'teams';
export const CONTRIBUTORS = 'contributors';
export const APPS = 'apps';

export const MAX_VISIBLE_JOBS = 4;
export const MAX_DECISIONS = 5;
export const MAX_CONNECTIONS = 100;
export const LIMIT_OTHER_STACKS = 3;
export const MAX_OTHER_STACKS = 100;
