import {STACK_APPLICATIONS, TOOL_ADOPTION_STAGE} from '../constants/features';
import {ORG_STRUCTURE_MODES} from '../enhancers/organization-enhancer';
import {featurePermissionCheck} from './feature-permission-check';

export const enterpriseOrg = (
  privateCompany,
  privateMode,
  orgStructureMode,
  isEditStagesAllowed
) => [
  {
    feature: 'Dashboard',
    link: `/companies/${privateCompany?.slug}/dashboard`,
    permission: true
  },
  {
    feature: 'Private Feed',
    link: `/feed`,
    permission: true
  },
  {
    feature: 'Tools Report',
    link: `/companies/${privateCompany?.slug}/dashboard/tools`,
    permission: true
  },
  {
    feature: 'Packages Report',
    link: `/companies/${privateCompany?.slug}/dashboard/packages`,
    permission: true
  },
  {
    feature: 'Vulnerabilities Report',
    link: `/companies/${privateCompany?.slug}/dashboard/vulnerabilities`,
    permission: true
  },
  {
    feature: 'Stacks Report',
    link: `/companies/${privateCompany?.slug}/dashboard/stacks`,
    permission: true
  },
  {
    feature: 'Members Report',
    link: `/companies/${privateCompany?.slug}/dashboard/members`,
    permission: true
  },
  {
    feature: 'Teams Report',
    link: `/companies/${privateCompany?.slug}/dashboard/teams`,
    permission: orgStructureMode !== ORG_STRUCTURE_MODES.flexible
  },
  {
    feature: 'Applications Report',
    link: `/companies/${privateCompany?.slug}/applications`,
    permission: featurePermissionCheck(privateMode, STACK_APPLICATIONS)
  },
  {
    feature: 'Company Profile',
    link: `/companies/${privateCompany?.slug}`,
    permission: true
  },
  {
    feature: 'Adoption Stage Management',
    link: `/companies/${privateCompany?.slug}/manage-stages`,
    permission: featurePermissionCheck(privateMode, TOOL_ADOPTION_STAGE) && isEditStagesAllowed
  },
  {
    feature: 'Tech Radar',
    link: `/companies/${privateCompany?.slug}/technology-radar`,
    permission: featurePermissionCheck(privateMode, TOOL_ADOPTION_STAGE) //Tech radar and stages are tightly coupled, if one is allowed, the other should be allowed too
  },
  {
    feature: 'Enterprise API Docs',
    link: `https://graphql.stackshare.io/stackshare_enterprise_api_docs`,
    permission: true,
    targetLink: true
  }
];

export const community = privateCompany => [
  {
    feature: privateCompany ? 'Public Feed' : 'Feed',
    link: privateCompany ? '/feed/public' : '/feed'
  },
  {
    feature: 'Tech Stacks',
    link: '/stacks'
  },
  {
    feature: 'Tools',
    link: '/tools/trending'
  },
  {
    feature: 'Blog',
    link: '/featured-posts'
  },
  {
    feature: 'Tool Comparisons',
    link: '/stackups/trending'
  }
];

export const personalInstall = username => [
  {
    feature: 'Dashboard',
    link: `/${username}/dashboard`,
    permission: true
  },
  {
    feature: 'Tools Report',
    link: `/${username}/dashboard/tools`,
    permission: true
  },
  {
    feature: 'Packages Report',
    link: `/${username}/dashboard/packages`,
    permission: true
  },
  {
    feature: 'Vulnerabilities Report',
    link: `/${username}/dashboard/vulnerabilities`,
    permission: true
  },
  {
    feature: 'Stacks Report',
    link: `/${username}/dashboard/stacks`,
    permission: true
  },
  {
    feature: 'User Profile',
    link: `/${username}`,
    permission: true
  }
];

export const stackFilePersonal = stackFileOwnerName => [
  {
    feature: 'Dashboard',
    link: `/${stackFileOwnerName}/dashboard`,
    permission: true
  },
  {
    feature: 'Tools Report',
    link: `/${stackFileOwnerName}/dashboard/tools`,
    permission: true
  },
  {
    feature: 'Packages Report',
    link: `/${stackFileOwnerName}/dashboard/packages`,
    permission: true
  },
  {
    feature: 'Vulnerabilities Report',
    link: `/${stackFileOwnerName}/dashboard/vulnerabilities`,
    permission: true
  },
  {
    feature: 'Stacks Report',
    link: `/${stackFileOwnerName}/dashboard/stacks`,
    permission: true
  },
  {
    feature: 'User Profile',
    link: `/${stackFileOwnerName}`,
    permission: true
  },
  {
    feature: 'Stack File Management',
    link: `/stack-file-management`,
    permission: true
  }
];

export const stackFileOrg = stackFileOwnerName => [
  {
    feature: 'Dashboard',
    link: `/companies/${stackFileOwnerName}/dashboard`,
    permission: true
  },
  {
    feature: 'Tools Report',
    link: `/companies/${stackFileOwnerName}/dashboard/tools`,
    permission: true
  },
  {
    feature: 'Packages Report',
    link: `/companies/${stackFileOwnerName}/dashboard/packages`,
    permission: true
  },
  {
    feature: 'Vulnerabilities Report',
    link: `/companies/${stackFileOwnerName}/dashboard/vulnerabilities`,
    permission: true
  },
  {
    feature: 'Stacks Report',
    link: `/companies/${stackFileOwnerName}/dashboard/stacks`,
    permission: true
  },
  {
    feature: 'Company Profile',
    link: `/companies/${stackFileOwnerName}`,
    permission: true
  },
  {
    feature: 'Stack File Management',
    link: `/stack-file-management`,
    permission: true
  }
];
