import React from 'react';
import PropTypes from 'prop-types';
import {TITLE_TEXT, BASE_TEXT, WEIGHT} from '../../../style/typography';
import {TARMAC, CHARCOAL, FOCUS_BLUE} from '../../../style/colors';
import glamorous from 'glamorous';
import sanitizeData from '../../../utils/sanitize-data';

const Container = glamorous.div();

const Title = glamorous.h2(
  {
    ...TITLE_TEXT,
    fontSize: 18,
    color: CHARCOAL,
    margin: 0
  },
  ({headingPadding}) => ({
    padding: headingPadding
  }),
  ({customStyle}) => ({
    ...customStyle
  })
);

const Description = glamorous.div({
  ...BASE_TEXT,
  marginTop: 8,
  color: TARMAC,
  lineHeight: 1.69
});

const Content = glamorous.div({
  ...BASE_TEXT,
  paddingRight: 10,
  ' > p, ol li, ol li > p': {
    fontSize: 13,
    lineHeight: '18px',
    color: TARMAC,
    marginBottom: 10
  },

  ' > ol li > strong, > ol li > p strong': {
    color: CHARCOAL,
    fontWeight: WEIGHT.BOLD,
    '> a': {
      color: FOCUS_BLUE,
      '&:hover': {
        cursor: 'pointer'
      }
    }
  },
  '> a': {
    color: FOCUS_BLUE,
    '&:hover': {
      cursor: 'pointer'
    }
  }
});

const DescriptionCard = ({
  heading,
  description,
  children,
  headingPadding,
  alternativesContent,
  customStyle = {}
}) => {
  const childrenDescription =
    children &&
    children.map((d, index) => {
      return <Description key={index}>{d}</Description>;
    });

  return (
    <Container>
      <Title headingPadding={headingPadding} customStyle={customStyle}>
        {heading}
      </Title>
      {alternativesContent ? (
        <Content
          dangerouslySetInnerHTML={{
            __html: sanitizeData(alternativesContent)
          }}
        />
      ) : (
        <>
          <Description>{description}</Description>
          {children && childrenDescription}
        </>
      )}
    </Container>
  );
};

DescriptionCard.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.array,
  headingPadding: PropTypes.string,
  alternativesContent: PropTypes.string,
  customStyle: PropTypes.object
};

export default DescriptionCard;
