import React, {useState, useEffect, useContext, useRef} from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import TextAreaInput from '../../inputs/text-area';
import SimpleButton from '../../buttons/base/simple';
import {WHITE, LIGHT_GREY, ASH, CHARCOAL, ERROR_RED, GREY} from '../../../style/colors';
import {truncateText} from '../../../../shared/utils/truncate-text';
import * as glamor from 'glamor';
import {linkStageMutation} from '../../../../data/shared/mutations';
import {ApolloContext} from '../../../../shared/enhancers/graphql-enhancer';
import {toolBySlug} from '../../../../data/tech-radar/queries';
import {BASE_TEXT} from '../../../style/typography';
import Circular, {BUTTON} from '../../../../shared/library/indicators/indeterminate/circular';
import WarningIcon from '../../../../shared/library/icons/warning-icon.svg';
import CheckedBlueIcon from '../../../../shared/library/icons/checked-blue-icon.svg';

const UNASSIGNED = 'unassigned';

const animaOpacity = glamor.css.keyframes({
  '0%, 100%': {opacity: 0},
  '50%': {opacity: 1}
});

const Container = glamorous.div(
  {
    display: 'flex',
    flexDirection: 'column',
    background: '#FBFBFB',
    borderRadius: 3,
    minHeight: 189,
    width: '100%',
    padding: '14px 23px 14px',
    position: 'relative'
  },
  ({animateSaveText}) => ({
    ' span': {
      animation: animateSaveText ? `${animaOpacity} 2s ease-in-out` : 'none'
    }
  })
);

const Button = glamorous(SimpleButton)({
  fontSize: 13,
  height: 32,
  width: 64,
  padding: '6px 18px',
  color: WHITE,
  marginRight: 0
});

const Menu = glamorous.ul({
  position: 'relative',
  margin: 0,
  listStyle: 'none',
  width: 120,
  color: CHARCOAL,
  padding: '9px 12px 11px 13px',
  fontSize: 12,
  fontWeight: 'bold',
  lineHeight: '15px',
  backgroundColor: WHITE,
  border: `1px solid ${ASH}`,
  borderRadius: 20,
  boxShadow: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  transition: 'border-color ease-in-out',
  ':hover': {
    cursor: 'pointer'
  }
});

const ArrowDownIcon = glamorous.img({
  width: '11px !important',
  height: '11px !important'
});

const ListContainer = glamorous.div({
  position: 'absolute',
  backgroundColor: WHITE,
  width: 206,
  borderRadius: 4,
  display: 'flex',
  flexDirection: 'column',
  zIndex: 999
});

const ListItem = glamorous.li(
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 40,
    padding: 13,
    fontSize: 13,
    fontWeight: 'bold',
    lineHeight: '14px',
    color: CHARCOAL,
    transition: `transform .3s cubic-bezier(0, .52, 0, 1)`,
    ':hover': {
      cursor: 'pointer',
      backgroundColor: '#f8f8f8'
    }
  },
  ({selected}) => ({
    backgroundColor: selected ? '#f8f8f8' : WHITE
  })
);

const CtaContainer = glamorous.div({
  display: 'flex',
  alignItems: 'center'
});

const UnAssignStageText = glamorous.p({
  display: 'flex',
  alignItems: 'center',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '14px',
  fontStyle: 'bold',
  marginBottom: 18,
  color: ERROR_RED,
  ' svg': {
    margin: '0 5px'
  }
});

const CancelText = glamorous.span({
  ...BASE_TEXT,
  fontSize: 12,
  lineHeight: '14px',
  color: LIGHT_GREY,
  marginLeft: 'auto',
  marginRight: 18,
  '&:hover': {
    textDecoration: 'underline',
    cursor: 'pointer'
  }
});

const Loading = glamorous.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 30
});

const Context = ({toolStage, stages, toolSlug, onCloseHandler}) => {
  const client = useContext(ApolloContext);
  const [selectedStage, setSelectedStage] = useState(
    toolStage ? toolStage : {id: UNASSIGNED, name: UNASSIGNED}
  );
  const [contextValue, setContextValue] = useState(
    toolStage && toolStage.context ? toolStage.context.rawContent : ''
  );
  const [animateStageContextSave, setAnimateStageContextSave] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [savingData, setSavingData] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const [showList, setShowList] = useState(false);
  const isUnassignedStageSelected =
    selectedStage.id === UNASSIGNED && toolStage && toolStage.name !== UNASSIGNED;

  const selectRef = useRef(null);

  const outSideClickHandler = e => {
    if (selectRef.current && !selectRef.current.contains(e.target)) {
      setShowList(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', outSideClickHandler);
    return () => {
      document.removeEventListener('click', outSideClickHandler);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => setAnimateStageContextSave(false), 300);
    return () => clearTimeout(timer);
  }, [animateStageContextSave]);

  const handleSelectChange = value => {
    if (value === UNASSIGNED) setSelectedStage({id: UNASSIGNED, name: UNASSIGNED});
    else setSelectedStage(value);
    setDisabledButton(false);
    setShowList(false);
  };

  const handleInputChange = e => {
    const {value} = e.target;
    setContextValue(value);
    setDisabledButton(false);
  };

  const saveDataHandler = async () => {
    //call API

    try {
      setSavingData(true);
      await client.mutate({
        mutation: linkStageMutation,
        variables: {
          companyAdoptionStages: stages.map(stage => ({id: stage.id, name: stage.name})),
          toolAdoptionStageLink: {
            toolSlug,
            adoptionStageId: selectedStage.name === UNASSIGNED ? null : selectedStage.id,
            adoptionStageContext: contextValue
          }
        },
        refetchQueries: [{query: toolBySlug, variables: {id: toolSlug}}],
        awaitRefetchQueries: true
      });

      setSavingData(false);
      setAnimateStageContextSave(true);
      onCloseHandler();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error in saving the data', error);
      setErrorMessage(true);
      setDisabledButton(true);
    }
  };

  return (
    <Container animateSaveText={animateStageContextSave}>
      <Menu onClick={() => setShowList(prev => !prev)} name="adoptionStage" innerRef={selectRef}>
        {selectedStage.name === UNASSIGNED ? 'Unassigned' : selectedStage.name}{' '}
        <ArrowDownIcon src="//img.stackshare.io/fe/down-arrow-grey.svg" alt="Arrow Icon" />
      </Menu>
      {showList && (
        <ListContainer>
          <ListItem
            key={UNASSIGNED}
            name={UNASSIGNED}
            onClick={() => handleSelectChange(UNASSIGNED)}
            selected={selectedStage.id === UNASSIGNED}
          >
            {'Unassigned'} {selectedStage.name === UNASSIGNED && <CheckedBlueIcon />}
          </ListItem>
          {stages.map(stage => (
            <ListItem
              key={stage.id}
              title={stage.name}
              onClick={() => handleSelectChange(stage)}
              selected={selectedStage.id === stage.id}
            >
              {truncateText(stage.name, 10, '...')}
              {selectedStage.id === stage.id && <CheckedBlueIcon />}
            </ListItem>
          ))}
        </ListContainer>
      )}
      <TextAreaInput
        placeholder="Assign a stage and add context..."
        id="context"
        name="context"
        value={contextValue}
        readOnly={selectedStage.id === UNASSIGNED}
        onChange={e => handleInputChange(e)}
        style={{
          fontSize: 13,
          border: selectedStage.id === UNASSIGNED ? `1px solid ${GREY}` : `1px solid ${LIGHT_GREY}`,
          borderRadius: 3,
          margin: '11px 0',
          minHeight: 150
        }}
        inputBoxStyle={{
          resize: 'none',
          minHeight: 150,
          backgroundColor: selectedStage.id === UNASSIGNED && GREY,
          opacity: selectedStage.id === UNASSIGNED && 0.2
        }}
      />
      {isUnassignedStageSelected && (
        <UnAssignStageText>
          <WarningIcon />
          Unassigning a stage will remove its context
        </UnAssignStageText>
      )}
      <CtaContainer>
        <CancelText
          onClick={() => {
            onCloseHandler();
          }}
        >
          Cancel
        </CancelText>
        <Button
          onClick={() => saveDataHandler()}
          disabled={disabledButton || errorMessage || savingData}
        >
          {savingData ? (
            <Loading>
              <Circular size={BUTTON} />
            </Loading>
          ) : (
            'Save'
          )}
        </Button>
        {animateStageContextSave && <span>{'Saved!'}</span>}
      </CtaContainer>
      {errorMessage && (
        <UnAssignStageText>{'Changes not saved!, Please try after some time.'}</UnAssignStageText>
      )}
    </Container>
  );
};

Context.propTypes = {
  toolStage: PropTypes.object,
  stages: PropTypes.array,
  toolSlug: PropTypes.string,
  onCloseHandler: PropTypes.func
};

export default Context;
